import theme from "../../../../theme";
import {Box, TextField} from "@mui/material";
import React from "react";

export interface FilterTextFieldProps {
    label: string;
    value: string;
    onChange: (value: string) => void;
}

export const FilterTextField = ({ label, value, onChange }: FilterTextFieldProps) => {
    return <Box sx={{width: {xs: '50%', md: 150}}}>
        <TextField
            value={value }
            label={label}
            onChange={(event) => onChange(event.target.value )}
            InputLabelProps={{shrink: true}}
            InputProps={{sx: { backgroundColor: theme.palette.background.paper, height: '100%' }}}
            sx={{ width: '100%', height: '100%' }}
        />
    </Box>
}