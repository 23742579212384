import React from 'react';
import {Button as MuiButton, ButtonProps as MuiButtonProps, SxProps, useTheme} from '@mui/material';
import {ReactJSXElement} from '@emotion/react/types/jsx-namespace';
import {Theme} from "@mui/material/styles";


export interface ButtonProps extends MuiButtonProps {
    text: string | ReactJSXElement;
    size?: 'small' | 'medium' | 'large',
    variant?: 'contained' | 'outlined' | 'text';
    color?: 'primary' | 'secondary';
    disabled?: boolean;
    onClick?: () => void;
    startIcon?: ReactJSXElement;
    styles?:  SxProps<Theme>;
    showLabel?: boolean;
}

export default function Button({text, size, variant, color, disabled, onClick, startIcon, showLabel = true, styles, ...props}: ButtonProps) {
    const theme = useTheme();
    const defaultStyles = {
        width: {xs: '100%', sm: 'fit-content', minWidth: 'fit-content', whiteSpace: 'noWrap'},
        boxShadow: variant === 'text' ? '' : '0px 0px 3px rgba(0, 0, 0, 0.2)',
    };
    const secondaryStyles = {
        backgroundColor: 'common.white',
        color: theme.palette.primary.main,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        height: '36.5px',
        ':hover': {
            backgroundColor: theme.palette.action.hover
        },
        ':disabled': {
            borderColor: theme.palette.action.disabled
        },
    };

    return (
        <MuiButton
            variant={variant ? variant : 'contained'}
            size={size ? size : 'medium'}
            disabled={disabled}
            onClick={onClick}
            disableElevation
            startIcon={showLabel ? startIcon : undefined}
            sx={color === 'secondary' ? {...defaultStyles, ...secondaryStyles, ...styles} : {...defaultStyles, ...styles}}
            {...props}
        >
            {showLabel ? text : startIcon}
        </MuiButton>
    );
}
