import React, {ReactElement, ReactNode} from "react";
import {Box, Tooltip} from "@mui/material";

export const CustomCellTooltip = ({tooltip, children}: {
    tooltip?: ReactNode;
    children: ReactElement;
}) => {
    if (tooltip) {
        return (
            <Tooltip arrow placement="top" title={
                <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 0.5}}>
                    {tooltip}
                </Box>
            }>
                {children}
            </Tooltip>
        );
    }

    return children;
};