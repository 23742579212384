import {Dayjs} from "dayjs";
import axios from "axios";
import {handleError} from "../utils";
import {
    DriverPosition,
    GetWorkScheduleResponse,
    SaveWorkScheduleItem,
    WorkScheduleItem
} from "./types";

export const createWorkScheduleItem = (saveWorkScheduleItem: SaveWorkScheduleItem) =>
    axios.post<WorkScheduleItem>('/api/work-schedule-items', saveWorkScheduleItem)
        .then(result => {
            return result.data;
        }).catch(handleError);

export const updateWorkScheduleItem = (id: number, saveWorkScheduleItem: SaveWorkScheduleItem) =>
    axios.put<WorkScheduleItem>(`/api/work-schedule-items/${id}`, saveWorkScheduleItem)
        .then(result => {
            return result.data;
        }).catch(handleError);

export const deleteWorkScheduleItem = (id: number) =>
    axios.delete(`/api/work-schedule-items/${id}`)
        .catch(handleError);

export const changeDriversOrder = (regionId: number, drivers: DriverPosition[]) =>
    axios.put(`/api/regions/${regionId}/drivers/order`, drivers);

export const confirmDriversWorkSchedule = (regionId: number, month: Dayjs) =>
    axios.post<GetWorkScheduleResponse>(`/api/regions/${regionId}/work-schedule/drivers/${month.format('YYYY-MM')}/confirmation`)
        .then(result => result.data)
        .catch(handleError);

export const confirmBusesWorkSchedule = (regionId: number, month: Dayjs) =>
    axios.post<GetWorkScheduleResponse>(`/api/regions/${regionId}/work-schedule/buses/${month.format('YYYY-MM')}/confirmation`)
        .then(result => result.data)
        .catch(handleError);


export const loadMonthWorkScheduleForBuses = async (regionId: number, month: Dayjs) => {
    const confirmed = axios.get<GetWorkScheduleResponse>(`/api/regions/${regionId}/work-schedule/buses/${month.format('YYYY-MM')}/confirmation`)
        .then(result => result.data);
    const unconfirmed = axios.get<GetWorkScheduleResponse>(`/api/regions/${regionId}/work-schedule/buses/${month.format('YYYY-MM')}`)
        .then(result => result.data);

    return Promise.all([confirmed, unconfirmed, []]);
};

export const loadMonthWorkScheduleForDrivers = async (regionId: number, month: Dayjs) => {
    const confirmed = axios.get<GetWorkScheduleResponse>(`/api/regions/${regionId}/work-schedule/drivers/${month.format('YYYY-MM')}/confirmation`)
        .then(result => result.data);
    const unconfirmed = axios.get<GetWorkScheduleResponse>(`/api/regions/${regionId}/work-schedule/drivers/${month.format('YYYY-MM')}`)
        .then(result => result.data);
    const sortingOrder = axios.get<DriverPosition[]>(`/api/regions/${regionId}/drivers/order`)
        .then(result => result.data);

    return Promise.all([confirmed, unconfirmed, sortingOrder]);
};
