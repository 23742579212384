import {ChangedWorkGroupVersion, RepetitionRules, RoutePoint} from "../types";

export interface WorkGroup {
    id: number;
    code: string;
    regionId: number | null;
    type: ResourceType;
    startDate: string | null;
    endDate: string | null;
    validFrom: string | null;
    validTo: string | null;
    repetition: RepetitionRules;
    comment: string | null;
    tripDefinitions: WorkGroupTripDefinition[];
    activities: WorkGroupActivity[];
    linkedWorkGroup?: LinkedWorkGroupDTO;
    versions?: WorkGroupVersion[];
    hasWorkSheet?: boolean;
}

export interface WorkGroupVersion {
    id: number;
    validFrom: string;
    validTo: string | null;
    tripDefinitions: WorkGroupTripDefinition[];
    activities: WorkGroupActivity[];
}

export interface LinkedWorkGroupDTO {
    id: number;
    code: string;
}

export interface DisplayWorkGroup extends WorkGroup {
    firstItemStartHh: number | null;
    lastItemEndHh: number | null;
}

export enum ResourceType {
    VEHICLE = 'VEHICLE',
    DRIVER = 'DRIVER',
}

export interface WorkGroupTripDefinition {
    id: number;
    tripId: number;
    tripSegmentId: number;
    code: string;
    type: WorkGroupItemType;
    startTime: string;
    startTimeIsOnNextDay: boolean;
    endTime: string;
    endTimeIsOnNextDay: boolean;
    seasonalStartDate: string | null;
    seasonalEndDate: string | null;
    validFrom: string | null;
    validTo: string | null;
    route: RoutePoint[] | null;
    distance: number | null;
    lineNumber: string | null;
    repetition?: RepetitionRules | null;
    regionId: number;
    workGroupsOfOppositeType?: WorkGroup[];
    workGroups?: WorkGroup[];
}

export interface WorkGroupActivity {
    id?: number;
    type: WorkGroupItemType;
    startTime: string;
    startTimeIsOnNextDay: boolean;
    endTime: string;
    endTimeIsOnNextDay: boolean;
    distance: number | null;
    workGroupIds?: number[];
    workGroupsOfOppositeType?: WorkGroup[];
}

export enum WorkGroupItemType {
    TRIP_DEFINITION = 'TRIP_DEFINITION',
    EXTRA_TIME = 'EXTRA_TIME',
    PREPARATION_TIME = 'PREPARATION_TIME',
    FINISHING_TIME = 'FINISHING_TIME',
    DISRUPTION = 'DISRUPTION',
    LUNCH_BREAK = 'LUNCH_BREAK',
    DEADHEADING = 'DEADHEADING',
    RESERVE = 'RESERVE',
    TIME_BETWEEN_SHIFTS = 'TIME_BETWEEN_SHIFTS',
    MAINTENANCE = 'MAINTENANCE',
}

export const WORK_GROUP_ITEM_TYPES = Object.values(WorkGroupItemType);

export const DRIVER_ONLY_ACTIVITY_TYPES = [
    WorkGroupItemType.LUNCH_BREAK,
    WorkGroupItemType.DISRUPTION,
    WorkGroupItemType.TIME_BETWEEN_SHIFTS,
    WorkGroupItemType.EXTRA_TIME
]

export interface MoveWorkGroupItem {
    date: string;
    from: number | null;
    to: number | null;
}

export interface MoveWorkGroupItemChanges {
    addedToWorkGroupVersions: ChangedWorkGroupVersion[];
    removedFromWorkGroupVersions: ChangedWorkGroupVersion[];
}

export interface CopyOppositeWorkGroupRequest {
    code: string;
    comment: string | null;
}

export interface SplitWorkGroupRequest {
    newWorkGroup1: NewWorkGroup;
    newWorkGroup2: NewWorkGroup;
}

export interface NewWorkGroup {
    code: string;
    linkedCode: string | null;
    repetitionRules: RepetitionRules;
    startDate: string | null;
    endDate: string | null;
}
