import React from 'react';
import {TextField} from "@mui/material";
import theme from "../../../theme";
import Autocomplete from "@mui/material/Autocomplete";
import {useAppSelector, useSearchRegions} from "../../../hooks";
import {selectAllRegions} from "../../../store/regionSlice";
import {Region} from "../../../API/region/types";
import {useAuth} from "../../../contexts/AuthContext";
import {AuthenticatedUser} from "../../../types";
import {isEqual} from "lodash";


const getPermittedRegions = (authenticatedUser: AuthenticatedUser, regions: Region[]) =>
    authenticatedUser.regionalFilteringApplies ? regions.filter(region => authenticatedUser.regions.find(userRegion => isEqual(userRegion, region))) : regions;

const RegionsSearch = () => {
    const { authenticatedUser } = useAuth();
    const regions = useAppSelector(selectAllRegions);
    const [searchRegions, setSearchRegions] = useSearchRegions();

    if (!authenticatedUser) return <></>;

    return (
        <Autocomplete
            multiple
            options={getPermittedRegions(authenticatedUser, regions)}
            size="small"
            getOptionLabel={(option) => option.name}
            value={searchRegions}
            onChange={(_, newValue) => {
                setSearchRegions([...newValue]);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Piirkond"
                    InputLabelProps={{shrink: true}}
                />
            )}
            sx={{
                minWidth: {xs: '100%', sm: 300},
                maxWidth: '100%',
                backgroundColor: theme.palette.background.paper,
                '.MuiAutocomplete-input': { minWidth: '30px !important' },
                '.MuiInputBase-root': {
                    minHeight: '30px',
                    padding: '6px 60px 1px 8px !important',
                },
                '.MuiInputBase-input': {
                    fontSize: '12px',
                    position: 'relative',
                    top: '-2px',
                },
                '.MuiChip-root': {
                    height: '19px',
                    mt: 0,
                    mb: '4px',
                    padding: 0,
                    fontSize: '14px',
                    lineHeight: '14px',
                },
            }}
        />
    );
};

export default RegionsSearch;
