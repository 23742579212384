import React, {useEffect, useMemo, useState} from "react";
import {Box, Typography} from "@mui/material";
import NumberTextField from "../../../../../components/Form/NumberTextField/NumberTextField";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import DateTimePicker from "../../../../../components/Form/DateTimePicker/DateTimePicker";
import Select from "../../../../../components/Form/Select/Select";
import {Fuel, OdometerReadingType} from "../../../../../API/bus/types";
import Button from "../../../../../components/Button/Button";
import {getFuelTranslationFromStr} from "../../../../../utils/enumTranslations";
import {isEqual} from "lodash";
import {Clear} from "@mui/icons-material";
import {getDate, getDisplayTimeRangeFromDateTimeStr} from "../../../../../utils/dateUtils";
import {
    OdometerAndRefuellingData,
    odometerReadingValidationSchema,
    refuellingValidationSchema
} from "../../../../../features/Form/ReadingForm/ReadingForm";
import Switch from "../../../../../components/Form/Switch/ControlledSwitch";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {selectAllBuses} from "../../../../../store/busSlice";
import SelectWithId from "../../../../../components/Form/Select/SelectWithId";
import {SelectOptionWithId} from "../../../../../types";
import dayjs from "dayjs";
import {getWorkSheetsConnectedToBus} from "../../../../../API";
import {mapErrors} from "../../../../../utils/errorMapping";
import {setToast} from "../../../../../store/toastSlice";
import * as Yup from 'yup';


const driverIdValidation = Yup.object({
    driverId: Yup.number()
        .test('is-not-zero', 'Juhi valimine on kohustuslik', (value) => value !== 0),
});

export const readingValidation = odometerReadingValidationSchema.concat(driverIdValidation);
export const readingWithFuelValidation = refuellingValidationSchema.concat(driverIdValidation);

export interface AddReadingFormData {
    busId: number;
    driverId: number;
    type: OdometerReadingType | null;
    dateTime: Date;
    reading: string;
    fuelType: string | null;
    fuelAmount: string;
}

const getInitialValues = (
    bus?: {id: number, fuelTypes: Fuel[]},
    driverId?: number,
    suggestedDateTime?: string,
): AddReadingFormData => ({
    busId: bus?.id ?? 0,
    driverId: driverId ?? 0,
    type: null,
    dateTime: suggestedDateTime ? getDate(suggestedDateTime) : new Date(),
    reading: '',
    fuelType: null,
    fuelAmount: '',
});

export interface AddReadingFormProps extends Omit<OdometerAndRefuellingData, 'includeRefuelling'> {
    bus?: {id: number, licencePlateNumber: string, fuelTypes: Fuel[]};
    driverId?: number,
    handleSubmit: (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>) => void;
    handleCloseDialog?: () => void;
}

const AddReadingForm = ({
    bus,
    driverId,
    handleSubmit,
    suggestedDateTime,
    handleCloseDialog,
}: AddReadingFormProps) => {
    const dispatch = useAppDispatch();
    const buses = useAppSelector(selectAllBuses);
    const [includeRefuelling, setIncludeRefuelling] = React.useState(false);
    const [driverOptions, setDriverOptions] = React.useState<SelectOptionWithId[]>([]);
    const [initialValues, setInitialValues] = useState<AddReadingFormData>(
        getInitialValues(bus, driverId, suggestedDateTime)
    );
    const shouldIncludeDriverSelect: boolean = !driverId;

    useEffect(() => {
        setInitialValues(getInitialValues(bus, driverId, suggestedDateTime));
    }, [includeRefuelling]);

    const areInitialValuesEqualToValues = (formikProps: FormikProps<AddReadingFormData>): boolean => {
        return isEqual(formikProps.initialValues, formikProps.values);
    };

    const updateDriverOptions = (busId: number, dateTime: Date) => {
        const dateTimeDayjs = dayjs(dateTime);
        if (dateTimeDayjs.isValid()) {
            getWorkSheetsConnectedToBus(busId, dateTime)
                .then((result) => {
                    setDriverOptions(result.map(option => ({
                        id: option.driverId,
                        name: `${option.driverFirstName} ${option.driverLastName} [`
                            + `${getDisplayTimeRangeFromDateTimeStr(option.startDateTime, option.endDateTime, dateTimeDayjs)}]`,
                        description: (option.workGroupCode ? `Töögrupp: ${option.workGroupCode}` : '')
                            + (option.region ? ` (${option.region.name})` : '')
                    })));
                })
                .catch(apiError => {
                    dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Juhtide pärimisel ilmnes viga'}));
                });
        }
    };

    return (
        <Box sx={{my: 1}}>
            <Typography variant="h5" pb={1}>{includeRefuelling ? 'Tankimise' : 'Odomeetri näidu'} lisamine</Typography>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={includeRefuelling ? readingWithFuelValidation : readingValidation}
            >
                {(formikProps: FormikProps<AddReadingFormData>) => {
                    useEffect(() => {
                        if (shouldIncludeDriverSelect) updateDriverOptions(formikProps.values.busId, formikProps.values.dateTime);
                    }, []);

                    useEffect(() => {
                        void formikProps.setFieldValue('fuelType', null);
                    }, [formikProps.values.busId]);

                    useEffect(() => {
                        if (shouldIncludeDriverSelect && formikProps.values.driverId !== 0 && !driverOptions.find(driverOption => driverOption.id === formikProps.values.driverId)) {
                            void formikProps.setFieldValue('driverId', 0);
                        }
                    }, [driverOptions]);

                    const selectedBus = useMemo(() =>
                        buses.find(bus => bus.id === formikProps.values.busId), [formikProps.values.busId]
                    );

                    const handleDateTimeFieldBlur = () => {
                        if (shouldIncludeDriverSelect) updateDriverOptions(formikProps.values.busId, formikProps.values.dateTime);
                    };

                    return (
                        <Form>
                            <Box sx={{display: 'flex'}}>
                                <Box sx={{mr: 0.5, width: '60%'}}>
                                    <DateTimePicker name="dateTime" label="Aeg*" fullWidth handleBlur={handleDateTimeFieldBlur} />
                                </Box>
                                <Box sx={{width: '40%'}}>
                                    <NumberTextField name="reading" label="Odomeetri näit*" />
                                </Box>
                            </Box>
                            <Box>
                                <Switch
                                    checked={includeRefuelling}
                                    onChange={() => setIncludeRefuelling(prevState => !prevState)}
                                    label="Lisa tankimine"
                                />
                            </Box>
                            {includeRefuelling &&
                                <Box sx={{display: 'flex'}}>
                                    <Box sx={{mr: 0.5, width: '60%'}}>
                                        <Select name="fuelType"
                                                label="Kütuseliik*"
                                                options={selectedBus?.fuelTypes ?? bus?.fuelTypes ?? []}
                                                sx={{width: '100%'}}
                                                translationFunction={getFuelTranslationFromStr}
                                                translationEnumType={Fuel}
                                        />
                                    </Box>
                                    <Box sx={{width: '40%'}}>
                                        <NumberTextField name="fuelAmount" label="Kütuse kogus*" decimals={2} />
                                    </Box>
                                </Box>
                            }
                            {shouldIncludeDriverSelect &&
                                <SelectWithId
                                    name="driverId"
                                    label="Juht*"
                                    options={driverOptions}
                                    noOptionsText="Sellel päeval bussiga sõitnud juhte ei leitud"
                                />
                            }
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                pt: 2
                            }}>
                                {handleCloseDialog &&
                                    <Box sx={{width: {xs: '50%', sm: 'fit-content'}}} pr={1}>
                                        <Button
                                            disabled={formikProps.isSubmitting}
                                            variant="outlined"
                                            text="Loobu"
                                            onClick={handleCloseDialog}
                                            startIcon={<Clear/>}
                                        />
                                    </Box>
                                }
                                <Box sx={{width: {xs: '50%', sm: 'fit-content'}}}>
                                    <Button
                                        text="Salvesta"
                                        type="submit"
                                        disabled={(areInitialValuesEqualToValues(formikProps)) || formikProps.isSubmitting}
                                    />
                                </Box>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default AddReadingForm;
