import React, {useContext, useEffect, useState} from "react";
import {WorkGroup} from "../../../../API/types";
import {ToastContext} from "../../../../contexts/ToastContext";
import {Box, useMediaQuery} from "@mui/material";
import {
    DateLimitFilterChipType,
    dateLimitFilterChipValues,
    Permission,
    resourceGroupToggleButtonTypeValuesWithAll,
    ResourceGroupToggleButtonTypeWithAll
} from "../../../../types";
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../../components/DataGrid/DataGrid";
import {filterByEndDate, filterByValue} from "../../../../utils/utils";
import {GridColDef} from "@mui/x-data-grid";
import theme from "../../../../theme";
import Visible from "../../../../components/Visible/Visible";
import {getResourceTypeTranslation} from "../../../../utils/enumTranslations";
import routes from "../../../../routes";
import {useNavigate, useOutletContext} from "react-router-dom";
import Repetition from "../../../../features/RepetitionRules/RepetitionRules";
import NavigationButton from "../../../../components/Button/NavigationButton";
import {loadWorkGroups} from "../../../../API";
import {useAppSelector, useWorkGroupsPlanningView} from "../../../../hooks";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {useResourceTypeToggle} from "../../../../store/viewSlice";
import {getDisplayNullableDate, getDisplayNullableDateWithoutYear} from "../../../../utils/dateUtils";
import {filterWorkGroupsByResourceType} from "../utils";
import NavLink from "../../../../components/NavLink/NavLink";
import NavIcon from "../../../../components/Icon/NavIcon";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import ListView from "../../../../layouts/ListViewWrapper";
import ActionIcon from "../../../../components/Icon/ActionIcon";
import NewWorkGroupVersionDialog, {NewWorkGroupVersionDialogData} from "./components/NewVersionDialog";
import {useLocalStorage} from "usehooks-ts";
import FilterToolbar from "../../../../components/FilterToolbar/FilterToolbar";
import {FilterChipProps} from "../../../../components/FilterToolbar/components/FilterChip";


export default function WorkGroupsListView() {
    const { addToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [rows, setRows] = useState<WorkGroup[] | undefined>(undefined);
    const [resourceTypeToggleButtonValue, handleResourceTypeChange] = useResourceTypeToggle<ResourceGroupToggleButtonTypeWithAll>(true);
    const [selectedFilterChips, setSelectedFilterChips] = useLocalStorage<DateLimitFilterChipType[]>('workGroupFilterChips', ['Käigus']);
    const [newWorkGroupVersionDialogData, setNewWorkGroupVersionDialogData] = useState<NewWorkGroupVersionDialogData | undefined>(undefined);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'));

    useWorkGroupsPlanningView(false);

    useEffect(() => {
        loadRows();
    }, [selectedRegion]);

    const loadRows = () => {
        if (selectedRegion) {
            loadWorkGroups(selectedRegion.id)
                .then(result => {
                    setRows(result);
                })
                .catch(() => {
                    setRows([]);
                    addToast({type: 'error', text: 'Töögruppide pärimisel ilmnes viga'});
                });
        }
    };

    const handleSelectedFilterChipsChange = (value: DateLimitFilterChipType) => {
        if (selectedFilterChips.includes(value)) {
            setSelectedFilterChips(prevState => prevState.filter(prevValue => prevValue !== value));
        } else {
            setSelectedFilterChips(prevState => [...prevState, value]);
        }
    };

    const getFilteredRows = (rows: WorkGroup[]) => filterByEndDate(filterWorkGroupsByResourceType(filterByValue(rows, searchInput), resourceTypeToggleButtonValue), selectedFilterChips);

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'code',
            headerName: 'Kood',
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) =>
                <NavLink id={params.row.id} value={params.row.code} route={routes.AUTHENTICATED.WORK_GROUPS.EDIT} navPermission={Permission.UpdateWorkGroups} />
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'type',
            headerName: 'Tüüp',
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) => params.value ?
                <Box>{getResourceTypeTranslation(params.value)}</Box> : <></>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'validFrom',
            headerName: 'Algus',
            width: isScreenSmall ? 120 : 140,
            valueFormatter: params => getDisplayNullableDate(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'validTo',
            headerName: 'Lõpp',
            width: isScreenSmall ? 120 : 140,
            valueFormatter: params => getDisplayNullableDate(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'startDate',
            headerName: 'Perioodi algus',
            width: isScreenSmall ? 120 : 140,
            valueFormatter: params => getDisplayNullableDateWithoutYear(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'endDate',
            headerName: 'Perioodi lõpp',
            width: isScreenSmall ? 120 : 140,
            valueFormatter: params => getDisplayNullableDateWithoutYear(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'repetition',
            headerName: 'Korduvusreeglid',
            sortable: false,
            minWidth: 150,
            renderCell: params => params.value ?
                <Box style={{minWidth: 'fit-content'}}><Repetition repetition={params.value} /></Box> : <></>
        },
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            minWidth: 70,
            renderCell: params => (
                <Visible permission={Permission.UpdateWorkGroups}>
                    <Box>
                        <ActionIcon type="START_NEW" id={params.row.id} handleClick={() => setNewWorkGroupVersionDialogData({originalWorkGroup: params.row})} />
                        <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.WORK_GROUPS.EDIT} />
                    </Box>
                </Visible>
            )
        }
    ];

    const handleCloseNewWorkGroupVersionDialog = () => setNewWorkGroupVersionDialogData(undefined);

    return (
        <ListView
            headerProps={{
                title: 'Töögrupid',
                buttonProps: {
                    title: 'Lisa töögrupp',
                    onClick: () => navigate(routes.AUTHENTICATED.WORK_GROUPS.ADD),
                    permission: Permission.UpdateWorkGroups
                },
                secondaryButton: <NavigationButton text="Planeerija" route={routes.AUTHENTICATED.WORK_GROUPS.PLANNER} />,
                toggleProps: {
                    options: [...resourceGroupToggleButtonTypeValuesWithAll],
                        value: resourceTypeToggleButtonValue,
                        handleChange: handleResourceTypeChange,
                },
                showRegionSelect: true,
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                filterToolbar={
                    <FilterToolbar
                        inputSearch={{value: searchInput, updateValue: setSearchInput}}
                        filterChips={
                            dateLimitFilterChipValues.map(value => ({
                                selected: selectedFilterChips.includes(value),
                                handleSelectClick: handleSelectedFilterChipsChange,
                                value: value
                            })) as FilterChipProps<DateLimitFilterChipType>[]
                        }
                    />
                }
            />
            {newWorkGroupVersionDialogData &&
                <NewWorkGroupVersionDialog
                    dialogData={newWorkGroupVersionDialogData}
                    handleSuccess={handleCloseNewWorkGroupVersionDialog}
                    handleCloseDialog={handleCloseNewWorkGroupVersionDialog}
                />
            }
        </ListView>
    );
}
