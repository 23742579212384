import React from 'react';
import {Box, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {getDisplayDateTime, getDisplayTime, isSameDate} from "../../../../../../utils/dateUtils";
import {OdometerReadingRow} from "./OdometerReadings";
import OdometerChangeNeededWarning from "../alerts/OdometerChangeNeededWarning";
import {OdometerReadingWithBusId} from "../../../../../../API/bus/types";


interface OdometerReadingChangeFormProps {
    selectedReadingIdsForUpdate: number[];
    setSelectedReadingIdsForUpdate:  React.Dispatch<React.SetStateAction<number[]>>;
    odometerReadingRows: OdometerReadingRow[];
    updatedOdometerReadingRows: OdometerReadingWithBusId[];
}

const OdometerReadingChangeForm = ({selectedReadingIdsForUpdate, setSelectedReadingIdsForUpdate, odometerReadingRows, updatedOdometerReadingRows}: OdometerReadingChangeFormProps) => {
    const handleSelectedReadingsChange = (id: number) => {
        if (selectedReadingIdsForUpdate.includes(id)) {
            setSelectedReadingIdsForUpdate(prevState => prevState.filter(readingId => readingId !== id));
        } else {
            setSelectedReadingIdsForUpdate(prevState => [...prevState, id]);
        }
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', pt: 1, gap: 1.5}}>
            <OdometerChangeNeededWarning />
            {updatedOdometerReadingRows.map(updatedReading => {
                const originalReading = odometerReadingRows.find(originalReading => originalReading.id === updatedReading.id);
                if (!originalReading) return null;

                const isOnSameDay = isSameDate(originalReading?.dateTime, updatedReading.dateTime);
                const displayOriginalReadingTime = isOnSameDay ? getDisplayTime(originalReading.dateTime) : getDisplayDateTime(originalReading.dateTime);
                const displayUpdatedReadingTime = isOnSameDay ? getDisplayTime(updatedReading.dateTime) : getDisplayDateTime(updatedReading.dateTime);

                return (
                    <Box key={originalReading.id} sx={{display: 'flex', flexDirection: 'column', color: 'inherit'}}>
                        <Typography>
                            Bussi {originalReading.licencePlateNumber} {originalReading.displayType === 'Algnäit' ? 'algnäidu' : 'lõppnäidu'} ({updatedReading.reading}km) aeg:
                        </Typography>
                        <RadioGroup
                            value={selectedReadingIdsForUpdate.some(readingId => readingId === originalReading.id)}
                            onChange={() => handleSelectedReadingsChange(originalReading.id)}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio sx={{py: 0.5}}  />}
                                label={`Määra näidu ajaks ${displayUpdatedReadingTime} ja säilita seos`}
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio sx={{py: 0.5}} />}
                                label={`Jäta näidu ajaks ${displayOriginalReadingTime} ja kaota seos`}
                            />
                        </RadioGroup>
                    </Box>
                );
            })}
        </Box>
    );
};

export default OdometerReadingChangeForm;
