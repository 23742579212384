export interface AvlAssignment {
    tripId: number;
    tripCode: string;
    tripLineNumber: string | null;
    tripStartTime: string;
    tripDepartureStopName: string | null;
    tripSegments: AvlAssigmentTripSegment[];
    date: string;
    status: AvlSyncStatus;
    lastSyncTime: string | null;
    lastMessage: string | null;
    retryCount: number | null;
}

export interface AvlAssigmentTripSegment {
    startDateTime: string;
    endDateTime: string;
    driverWorkSheet?: DriverWorkSheet;
    busWorkSheet?: BusWorkSheet;
}

interface DriverWorkSheet {
    id: number;
    firstName?: string;
    lastName?: string;
}

interface BusWorkSheet {
    id: number;
    licensePlateNumber?: string;
}

export enum AvlSyncStatus {
    PENDING = 'PENDING',
    OK = 'OK',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR'
}