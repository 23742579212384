import React from "react";
import DataGrid from "../../../../../components/DataGrid/DataGrid";
import {GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams} from "@mui/x-data-grid";
import {formatTimeAtDate} from "../../../../../utils/dateUtils";
import {Box, IconButton, Tooltip} from "@mui/material";
import {CallSplit, Delete, Edit, WarningAmberRounded} from "@mui/icons-material";
import {decimalToFixedDisplayStr, getOppositeResourceType} from "../../../../../utils/utils";
import {ResourceType, WorkGroupItemType} from "../../../../../API/workGroup/types";
import {WorkScheduleItemStatus} from "../../../../../API/workSchedule/types";
import {WorkSheetDetails, WorkSheetWorkItem} from "../../../../../API/workSheets/types";
import SecondaryResourceName from "./SecondaryResourceName";
import {getWorkItemLabel} from "../utils";
import {useAuth} from "../../../../../contexts/AuthContext";
import {Permission} from "../../../../../types";


interface WorkItemsProps {
    workSheet: WorkSheetDetails;
    secondaryWorkSheets: WorkSheetDetails[];
    workItems: WorkSheetWorkItem[];
    handleDelete: (workItem: WorkSheetWorkItem) => void;
    handleEdit: (workItem: WorkSheetWorkItem) => void;
    handleSplit: (workItem: WorkSheetWorkItem) => void;
}

const WorkItems = ({workSheet, secondaryWorkSheets, workItems, handleDelete, handleEdit, handleSplit}: WorkItemsProps) => {
    const {hasPermission} = useAuth();

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Tegevus',
            sortable: false,
            disableColumnMenu: true,
            width: 200,
            valueGetter: (params: GridValueGetterParams) => getWorkItemLabel(params.row),
        },
        {
            field: 'code',
            headerName: 'Reisi kood',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
            valueGetter: (params: GridValueGetterParams) => params.row.trip?.code ?? params.row.charterTrip?.tripNumber,
        },
        {
            field: 'lineNumber',
            headerName: 'Liini nr',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
            valueGetter: (params: GridValueGetterParams) => params.row.trip?.lineNumber,
        },
        {
            field: 'startDateTime',
            headerName: 'Algus',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params: GridValueFormatterParams) => formatTimeAtDate(params.value, workSheet.startDate),
            renderCell: (params: GridRenderCellParams) => <>
                {params.formattedValue}
                {params.row.warning &&
                    <Tooltip title="Tegevus algab enne eelmise tegevuse lõppu" arrow placement="top">
                        <WarningAmberRounded sx={{px: 0.2, fontSize: '18px'}} color="warning" />
                    </Tooltip>
                }
            </>,
        },
        {
            field: 'endDateTime',
            headerName: 'Lõpp',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params: GridValueFormatterParams) => formatTimeAtDate(params.value, workSheet.startDate),
        },
        {
            field: 'distance',
            headerName: 'Läbisõit',
            sortable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.value ? decimalToFixedDisplayStr(params.value) + ' km' : '',
        },
        {
            field: 'otherResource',
            headerName: workSheet.resourceType === ResourceType.DRIVER ? 'Buss' : 'Juht',
            sortable: false,
            disableColumnMenu: true,
            width: workSheet.resourceType === ResourceType.DRIVER ? 80 : 200,
            renderCell: (params: GridRenderCellParams) => <SecondaryResourceName
                workItem={params.row}
                secondaryWorkSheets={secondaryWorkSheets}
                secondaryResourceType={getOppositeResourceType(workSheet.resourceType)}
            />,
        },
        {
            field: 'comment',
            headerName: 'Kommentaar',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 120,
            valueFormatter: (params: GridValueFormatterParams) => params.value,
        },
        {
            field: 'menu',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            align: 'right',
            minWidth: 100,
            renderCell: (params: GridRenderCellParams) => (!hasPermission(Permission.UpdateWorkSchedules) || workSheet.status === WorkScheduleItemStatus.CONFIRMED) ? <></> :
                <Box>
                    {params.row.type === WorkGroupItemType.TRIP_DEFINITION && params.row.trip &&
                        <Tooltip title="Halda reisi osadena" arrow placement="top">
                            <IconButton size="small" onClick={() => handleSplit(params.row)}>
                                <CallSplit sx={{height: '20px', width: '20px', rotate: '180deg'}}/>
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title="Muuda" arrow placement="top">
                        <IconButton size="small" onClick={() => handleEdit(params.row)}>
                            <Edit sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Kustuta" arrow placement="top">
                        <IconButton size="small" onClick={() => handleDelete(params.row)}>
                            <Delete sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>
                </Box>
        },
    ];

    return (
        <DataGrid rows={workItems} columns={columns} />
    );
};

export default WorkItems;