import React from "react";
import InputSearch, {InputSearchProps} from "../../../components/FilterToolbar/components/InputSearch";
import FilterChip, {FilterChipProps} from "../../../components/FilterToolbar/components/FilterChip";
import DataRefresh from "../../../components/FilterToolbar/components/DataRefresh";
import {Box} from "@mui/material";
import {useDayNavigation} from "../../../store/viewSlice";
import {Dayjs} from "dayjs";
import ToolbarContainer from "../../../components/FilterToolbar/components/ToolbarContainer";
import DateNavigator from "../../../components/DateNavigator/DateNavigator";


interface FilterToolbarProps {
    inputSearch: InputSearchProps,
    refreshTime: Dayjs | null,
    handleRefreshed: () => void,
    filterChips: FilterChipProps[][],
}

const FilterToolbar = ({inputSearch, refreshTime, handleRefreshed, filterChips}: FilterToolbarProps) => {
    const [date, handleChangeDate] = useDayNavigation();

    return (
        <ToolbarContainer sx={{flexDirection: 'row', gap: 1, flexWrap: {xs: 'wrap-reverse', lg: 'nowrap'}}}>
            <Box sx={{order: 1}}>
                <DateNavigator date={date} handleSelectedDateChange={handleChangeDate} unit="day" />
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
                rowGap: 1.5,
                flexWrap: {xs: 'wrap-reverse', md: 'nowrap'},
                width: '100%',
                order: {xs: 3, lg: 2},
            }}>
                <Box sx={{width: {xs: '100%', md: 'fit-content'}}}>
                    <InputSearch {...inputSearch} />
                </Box>
                <Box sx={{display: 'flex', gap: 0.5, flexWrap: {xs: 'wrap', sm: 'nowrap'},}}>
                    {filterChips.flatMap((filterChipGroup, groupIndex) =>
                        filterChipGroup.map((filterChip, itemIndex) =>
                            <Box key={filterChip.value}
                                 sx={(groupIndex < (filterChips.length - 1) && itemIndex === filterChipGroup.length - 1)
                                     ? {pr: 1, mr: 0.5, borderRight: '2px solid', borderColor: 'divider'}
                                     : {}
                                 }
                            >
                                <FilterChip {...filterChip} />
                            </Box>
                        )
                    )}
                </Box>
            </Box>
            <Box sx={{order: {xs: 2, lg: 3}}}>
                <DataRefresh refreshTime={refreshTime} handleRefreshed={handleRefreshed} />
            </Box>
        </ToolbarContainer>
    );
};

export default FilterToolbar;
