import {
    AnyAction,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction,
    ThunkDispatch
} from "@reduxjs/toolkit";
import {
    ApiError,
    ChangedWorkGroupVersion,
    CopyOppositeWorkGroupRequest,
    ResourceType,
    SaveWorkItem,
    SaveWorkScheduleItem,
    TripDefinition,
    WorkGroup,
    WorkGroupActivity,
    WorkGroupItemType,
    WorkGroupTripDefinition,
    WorkScheduleItemStatus,
    WorkScheduleItemType,
    WorkSheetWorkItem,
    TripSegmentResources,
    LinkedWorkGroupDTO,
} from "../API/types";
import {
    addActivityToWorkGroup as apiAddActivityToWorkGroup,
    copyOppositeWorkGroup as apiCopyOppositeWorkGroup,
    createWorkGroupActivity as apiCreateWorkGroupActivity,
    createWorkItem as apiCreateWorkItem,
    createWorkItem,
    createWorkScheduleItem,
    removeWorkItemResource,
    getUnplannedWorkItems as apiGetUnplannedWorkItems,
    getWorkSheetsByRegionAndDate as apiGetWorkSheetsByRegionAndDate,
    loadTripSegmentsByDate as apiLoadTripDefinitions,
    loadWorkGroupDetails as apiLoadWorkGroup,
    loadWorkGroupsOnDate as apiLoadWorkGroupsOnDate,
    mergeTrip as apiMergeTrip,
    moveTripDefinition as apiMoveTripDefinition,
    removeActivityFromWorkGroup as apiRemoveActivityFromWorkGroup,
    setTripSegmentResources,
    splitTrip as apiSplitTrip,
    updateWorkGroupActivity as apiUpdateWorkGroupActivity,
    updateWorkItem as apiUpdateWorkItem,
    deleteWorkItem,
    linkWorkGroup as apiLinkWorkGroup,
    unlinkWorkGroup as apiUnlinkWorkGroup,
} from "../API";
import {AppDispatch, RootState} from "../store";
import {
    CharterTripWorkItem,
    PlannerDialogData,
    PlannerItemGroup,
    PlannerType,
    StartAndEndTimeWithModifier,
    TripDefinitionWorkItem,
    UnassignedTripsSectionHeight,
    WorkItem
} from "../features/Planner/types";
import {getPlannerTimeline} from "../features/Planner/utils/timelineUtils";
import dayjs, {Dayjs} from "dayjs";
import {setToast} from "./toastSlice";
import {
    getHhFromMin,
    getTimeInHours,
    getUniqueOtherWorkGroupIds,
    isDriverWorkDurationOverLimit,
    isDurationOverDriverWorkDayLimitWithNewItem
} from "../features/Planner/utils/utils";
import {createIdToColorIndexMap, getRandomColorIndex} from "../features/Planner/utils/colorUtils";
import {
    getResetUnassignedTripsState,
    getUnassignedTripRows,
    getUpdatedUnassignedCharterTripsState,
    getUpdatedUnassignedTripRows,
    getUpdatedUnassignedTripsState,
    groupUnassignedTrips
} from "../features/Planner/utils/unassignedRowsUtils";
import {ActivityForm} from "../features/Planner/components/dialogs/AddOrEditActivity/types";
import {doesItemCodeOrRouteMatchFilterInput, getOtherRegionId, strToDecimal} from "../utils/utils";
import {mapErrors, mapErrorToDisplayMessage} from "../utils/errorMapping";
import {
    getFirstItemStartAndLastItemEndHh,
    selectAllItemTimesInPlannerItemGroupMemoized,
    selectDisplayWorkGroupById,
    selectWorkScheduleItemByEntityId,
} from "../features/Planner/store/selectors";
import {DEFAULT_PREPARATION_AND_FINISHING_TIME_MINUTES} from "../features/Planner/constants";
import {MAX_DRIVER_WORK_GROUP_DURATION_HOURS} from "../constants";
import {selectToggledResourceType} from "./viewSlice";
import {WorkGroup as WorkScheduleWorkGroup, WorkScheduleItem} from "../API/workSchedule/types";
import {
    addCharterTripWorkItem,
    addTripDefinitionWorkItem,
    addWorkItem,
    CharterTripWorkItem as WorkScheduleCharterTripWorkItem,
    getWorkScheduleItemIdAsNr,
    removeCharterTripWorkItem,
    removeTripDefinitionWorkItem,
    removeWorkItem,
    selectAllPlannerCharterTripWorkItems,
    selectAllPlannerTripDefinitionWorkItems,
    selectAllPlannerWorkItems,
    selectCharterTripWorkItemById,
    selectPlannerTripWorkItemById,
    selectPlannerWorkItemById,
    selectWorkItemById,
    selectWorkScheduleItemById,
    selectWorkScheduleItemEntityId,
    TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem,
    updateWorkItemResources,
    WorkItem as WorkScheduleWorkItem,
} from "./workScheduleItemSlice";
import {WorkGroup as WorkGroupSliceWorkGroup} from "./workGroupSlice";
import {selectSelectedRegion} from "./regionSlice";
import {selectSelectedDay} from "../scenes/authenticated/workSheets/store/selectors";
import {
    getDateWithModifierFromHhNr,
    getHhMmFromDate,
    getStartAndEndTimeWithModifierFromDateTimeStr,
    getStrDateTimeFromDateWithModifier
} from "../utils/dateUtils";
import {compareRegionalItemsByCode} from "../scenes/authenticated/workSheets/utils";
import {SplitTripForm} from "../features/Planner/components/dialogs/SplitTrip/types";
import {FormikHelpers} from "formik";
import {MoveWorkGroupItemChanges} from "../API/workGroup/types";


interface PlannerState {
    plannerType: PlannerType;
    isLoading: boolean;
    error: string | undefined;
    contentWidth: number;
    plannerItemGroups: EntityState<PlannerItemGroup>;

    workGroups: EntityState<WorkGroup>;
    oppositeGroupMarkerColorMap: Record<number, number>;
    selectedOppositeGroupId?: number;
    tripDefinitions: EntityState<WorkGroupTripDefinition>;
    activities: EntityState<WorkGroupActivity>;

    unassignedTripsState: UnassignedTripsState;
    unassignedTripsSectionHeight: UnassignedTripsSectionHeight;
    dialogData: PlannerDialogData;
}

export interface UnassignedTripsState {
    filterInput: string;
    charterTripRows: number[][];
    rows: number[][];
    otherRegionRows: number[][];
}

const getWorkGroups = async (regionId: number, type: ResourceType, date: Dayjs, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<WorkGroup[]> => {
    try {
        return await apiLoadWorkGroupsOnDate(regionId, type, date);
    } catch {
        dispatch(setToast({type: 'error', text: 'Töögruppide pärimisel ilmnes viga'}));
        return [];
    }
};

const getTrips = async (regionId: number, date: Dayjs, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<TripDefinition[]> => {
    try {
        return await apiLoadTripDefinitions(regionId, date);
    } catch {
        dispatch(setToast({type: 'error', text: 'Reiside pärimisel ilmnes viga'}));
        return [];
    }
};

const getLinkedWorkGroup = async (id: number, dispatch: ThunkDispatch<unknown, unknown, AnyAction>): Promise<WorkGroup | undefined> => {
    try {
        return await apiLoadWorkGroup(id);
    } catch {
        dispatch(setToast({type: 'error', text: 'Seotud töögrupi pärimisel ilmnes viga'}));
        return undefined;
    }
};

export const fetchWorkGroupPlannerData = createAsyncThunk<
    {
        plannerType: PlannerType,
        plannerItemGroups: PlannerItemGroup[],
        oppositeGroupMarkerColorMap: Record<number, number>,
        workGroups: WorkGroup[],
        tripDefinitions: WorkGroupTripDefinition[],
        activities: WorkGroupActivity[],
        unassignedTripsState: UnassignedTripsState,
    },
    void,
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/fetchWorkGroupPlannerData',
    async (_, { getState, dispatch }) => {
        const state = getState();
        const plannerType = PlannerType.WORK_GROUP;
        const day = selectSelectedDay(state);
        const selectedRegion = selectSelectedRegion(state);

        if (!day || !selectedRegion) throw Error;

        const date = dayjs(day);

        const resourceType = selectToggledResourceType(state);

        const workGroups = (await getWorkGroups(selectedRegion.id, resourceType, date, dispatch))
            .sort((a, b) => a.code > b.code ? 1 : -1);

        const tripDefinitions = await getTrips(selectedRegion.id, date, dispatch);
        const workGroupTripDefinitions = tripDefinitions.map(tripDefinition => {
            const workGroupsOfOppositeType = resourceType
                ? tripDefinition.workGroups.filter(workGroup => workGroup.type !== resourceType)
                : [];
            return {
                ...tripDefinition,
                seasonalStartDate: tripDefinition.startDate,
                seasonalEndDate: tripDefinition.endDate,
                type: WorkGroupItemType.TRIP_DEFINITION,
                workGroupsOfOppositeType: workGroupsOfOppositeType
            } as WorkGroupTripDefinition;
        });
        workGroups.forEach(workGroup =>
            workGroup.tripDefinitions.forEach(trip => {
                if (trip.regionId !== selectedRegion.id) {
                    const plannedWorkGroup = {...workGroup, tripDefinitions: [], activities: []};
                    const tripWorkGroups: WorkGroup[] = [];
                    if (trip.workGroupsOfOppositeType) {
                        tripWorkGroups.push(...trip.workGroupsOfOppositeType);
                    }
                    tripWorkGroups.push(plannedWorkGroup);
                    const otherRegionTrip = {...trip, workGroups: tripWorkGroups};
                    workGroupTripDefinitions.push(otherRegionTrip);
                }
            })
        );

        const workGroupActivities = workGroups.flatMap(workGroup =>
            workGroup.activities.map(activity => ({
                ...activity,
                workGroupIds: activity.workGroupIds ?? (
                    activity.workGroupsOfOppositeType?.length
                        ? [workGroup.id, ...activity.workGroupsOfOppositeType.map(wg => wg.id)]
                        : [workGroup.id]
                )
            }))
        );

        const uniqueOppositeWorkGroupIds = getUniqueOtherWorkGroupIds([...workGroupTripDefinitions, ...workGroupActivities]);
        const oppositeWorkGroupMarkerColorMap: Record<number, number> = createIdToColorIndexMap(uniqueOppositeWorkGroupIds);

        const plannerItemGroups: PlannerItemGroup[] = workGroups.map(workGroup => {
            return {
                ...workGroup,
                workGroupCode: workGroup.code,
                tripDefinitionIds: workGroup.tripDefinitions.map(tripDefinition => tripDefinition.id),
                activityIds: workGroup.activities.filter(activity => activity.id !== undefined).map(activity => activity.id as number),
                workItemIds: [],
                otherRegionId: getOtherRegionId(workGroup.regionId, selectedRegion),
            }
        });

        const unassignedTripsState: UnassignedTripsState = {
            filterInput: '',
            charterTripRows: [],
            rows: getUnassignedTripRows(workGroupTripDefinitions, resourceType),
            otherRegionRows: []
        };

        return {
            plannerType: plannerType,
            plannerItemGroups: plannerItemGroups,
            oppositeGroupMarkerColorMap: oppositeWorkGroupMarkerColorMap,
            workGroups: workGroups,
            tripDefinitions: workGroupTripDefinitions,
            activities: workGroupActivities,
            unassignedTripsState: unassignedTripsState,
        };
    }
);

export const fetchWorkSheetPlannerData = createAsyncThunk<
    {
        plannerType: PlannerType,
        plannerItemGroups: PlannerItemGroup[],
        workSheets: WorkScheduleItem[],
        workItems: WorkItem[],
        tripDefinitionWorkItems: WorkScheduleTripDefinitionWorkItem[],
        charterTripWorkItems: WorkScheduleCharterTripWorkItem[],
        workGroups: WorkGroupSliceWorkGroup[],
        tripDefinitions: WorkGroupTripDefinition[],
        activities: WorkGroupActivity[],
        oppositeGroupMarkerColorMap: Record<number, number>,
        unassignedTripsState: UnassignedTripsState,
    },
    void,
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/fetchWorkSheetPlannerData',
    async (_, { getState }) => {
        const state = getState();
        const plannerType = PlannerType.WORK_SHEET;
        const day = selectSelectedDay(state);
        const selectedRegion = selectSelectedRegion(state);

        if (!day || !selectedRegion) throw Error;

        const date = dayjs(day);

        const resourceType = selectToggledResourceType(state);
        const getWorkSheetsResponse = await apiGetWorkSheetsByRegionAndDate(selectedRegion.id, date);
        const getUnplannedWorkItemsResponse = await apiGetUnplannedWorkItems(selectedRegion.id, date, resourceType);

        const plannerItemGroups: PlannerItemGroup[] = getWorkSheetsResponse.workScheduleItems
            .filter(workScheduleItem => workScheduleItem.resourceType === resourceType)
            .map(workScheduleItem => {
                const workGroup: WorkScheduleWorkGroup | undefined = getWorkSheetsResponse.workGroups
                    .find((workGroup: WorkScheduleWorkGroup) => workGroup.id === workScheduleItem.workGroupId);
                const shouldIncludeWorkGroupItems = workScheduleItem.status !== WorkScheduleItemStatus.GENERATED
                    && workScheduleItem.status !== WorkScheduleItemStatus.CONFIRMED;

                return {
                    id: selectWorkScheduleItemEntityId(workScheduleItem),
                    type: workScheduleItem.resourceType,
                    workGroupCode: workGroup?.code ?? '',
                    tripDefinitionIds: shouldIncludeWorkGroupItems && workGroup ? workGroup.tripDefinitionIds : [],
                    activityIds: shouldIncludeWorkGroupItems && workGroup ? workGroup.activityIds : [],
                    workItemIds: workScheduleItem.workItemIds,
                    otherRegionId: getOtherRegionId(workScheduleItem.regionId, selectedRegion),
                }
            });

        const workSheetsOfOppositeType = getWorkSheetsResponse.workScheduleItems
            .filter(workScheduleItem => workScheduleItem.id !== undefined && workScheduleItem.resourceType !== resourceType);
        const workItemIdToWorkSheetIdsMap: Record<number, number[]> = {};
        const uniqueOppositeWorkSheetIds: number[] = [];
        workSheetsOfOppositeType.forEach((workSheet) => {
            const { id: scheduleItemId, workItemIds } = workSheet;

            if (scheduleItemId) {
                workItemIds.forEach((workItemId) => {
                    if (workItemId in workItemIdToWorkSheetIdsMap) {
                        workItemIdToWorkSheetIdsMap[workItemId].push(scheduleItemId);
                    } else {
                        workItemIdToWorkSheetIdsMap[workItemId] = [scheduleItemId];
                    }
                });
                uniqueOppositeWorkSheetIds.push(scheduleItemId);
            }
        });

        const workItems: WorkItem[] = [
            ...getWorkSheetsResponse.workItems,
            ...getUnplannedWorkItemsResponse.filter(wi => wi.id !== 0)
        ].map((apiWorkItem): WorkItem => ({
            id: apiWorkItem.id,
            type: apiWorkItem.type,
            startDateTime: apiWorkItem.startDateTime,
            endDateTime: apiWorkItem.endDateTime,
            distance: apiWorkItem.distance ?? 0,
            startTime: getHhMmFromDate(dayjs(apiWorkItem.startDateTime).toDate()),
            startTimeIsOnNextDay: dayjs(apiWorkItem.startDateTime).isAfter(date, 'day'),
            endTime: getHhMmFromDate(dayjs(apiWorkItem.endDateTime).toDate()),
            endTimeIsOnNextDay: dayjs(apiWorkItem.endDateTime).isAfter(date, 'day'),
            code: apiWorkItem.trip?.code ?? apiWorkItem.charterTrip?.tripNumber,
            regionId: apiWorkItem.trip?.regionId ?? apiWorkItem.charterTrip?.regionId,
            workSheetsOfOppositeType: workItemIdToWorkSheetIdsMap[apiWorkItem.id] ?? [],
            tripId: apiWorkItem.trip?.id,
            tripSegmentId: apiWorkItem.tripSegmentId,
            charterTripId: apiWorkItem.charterTrip?.id,
            departurePoint: apiWorkItem.charterTrip?.departurePoint,
            destination: apiWorkItem.charterTrip?.destination,
            comment: apiWorkItem.comment,
            tripDefinitionRepetition: apiWorkItem.trip?.repetition,
            route: apiWorkItem.route ?? apiWorkItem.charterTrip?.route,
            driverWorkSheetId: apiWorkItem.driverWorkSheetId,
            busWorkSheetId: apiWorkItem.busWorkSheetId,
            defectId: apiWorkItem.defectId ?? null,
        }));

        const unplannedTripDefinitionWorkItems: TripDefinitionWorkItem[] = getUnplannedWorkItemsResponse
            .filter(item => item.type === WorkGroupItemType.TRIP_DEFINITION && item.tripSegmentId)
            .map(apiWorkItem => {
                return {
                    id: apiWorkItem.tripSegmentId as number,
                    tripId: apiWorkItem.trip?.id as number,
                    workItemId: apiWorkItem.id,
                    startDateTime: apiWorkItem.startDateTime,
                    startTime: getHhMmFromDate(dayjs(apiWorkItem.startDateTime).toDate()),
                    startTimeIsOnNextDay: dayjs(apiWorkItem.startDateTime).isAfter(date, 'day'),
                    endDateTime: apiWorkItem.endDateTime,
                    endTime: getHhMmFromDate(dayjs(apiWorkItem.endDateTime).toDate()),
                    endTimeIsOnNextDay: dayjs(apiWorkItem.endDateTime).isAfter(date, 'day'),
                    distance: apiWorkItem.distance ?? 0,
                    code: apiWorkItem.trip?.code,
                    regionId: apiWorkItem.trip?.regionId ?? selectedRegion.id,
                    route: apiWorkItem.route,
                    driverWorkSheetId: apiWorkItem.driverWorkSheetId,
                    busWorkSheetId: apiWorkItem.busWorkSheetId,
                    workSheetsOfOppositeType: workItemIdToWorkSheetIdsMap[apiWorkItem.id] ?? [],
                    repetition: apiWorkItem.trip?.repetition,
                    comment: null,
                }
            });

        const apiWorkItemToCharterTrip = (apiWorkItem: WorkSheetWorkItem): CharterTripWorkItem => ({
            id: apiWorkItem.charterTrip?.id as number,
            workItemId: apiWorkItem.id,
            startDateTime: apiWorkItem.startDateTime,
            startTime: getHhMmFromDate(dayjs(apiWorkItem.startDateTime).toDate()),
            startTimeIsOnNextDay: dayjs(apiWorkItem.startDateTime).isAfter(date, 'day'),
            endDateTime: apiWorkItem.endDateTime,
            endTime: getHhMmFromDate(dayjs(apiWorkItem.endDateTime).toDate()),
            endTimeIsOnNextDay: dayjs(apiWorkItem.endDateTime).isAfter(date, 'day'),
            distance: apiWorkItem.distance ?? 0,
            regionId: apiWorkItem.charterTrip?.regionId ?? selectedRegion.id,
            workSheetsOfOppositeType: workItemIdToWorkSheetIdsMap[apiWorkItem.id] ?? [],
            comment: null,
            departurePoint: apiWorkItem.charterTrip?.departurePoint ?? '',
            destination: apiWorkItem.charterTrip?.destination ?? '',
            charterTripId: apiWorkItem.charterTrip?.id as number,
            route: apiWorkItem.charterTrip?.route ?? null,
            code: apiWorkItem.charterTrip?.tripNumber ?? '',
        });

        const unplannedCharterTripWorkItems: CharterTripWorkItem[] = getUnplannedWorkItemsResponse
            .filter(item => item.type === WorkGroupItemType.TRIP_DEFINITION && item.charterTrip)
            .map(apiWorkItemToCharterTrip);
        const plannedCharterTripWorkItems: CharterTripWorkItem[] = getWorkSheetsResponse.workItems
            .filter(item => item.type === WorkGroupItemType.TRIP_DEFINITION && item.charterTrip)
            .map(apiWorkItemToCharterTrip);

        const oppositeWorkSheetMarkerColorMap: Record<number, number> = createIdToColorIndexMap(uniqueOppositeWorkSheetIds);

        const tripDefinitions: WorkGroupTripDefinition[] = getWorkSheetsResponse.tripDefinitions.map(workGroupItem => ({
            ...workGroupItem,
            tripId: workGroupItem.tripId as number,
            tripSegmentId: workGroupItem.id,
            seasonalStartDate: null,
            seasonalEndDate: null,
            validFrom: null,
            validTo: null,
            repetition: workGroupItem.tripDefinitionRepetition,
            regionId: selectedRegion.id,
            code: workGroupItem.tripDefinitionCode ?? '',
            route: workGroupItem.tripDefinitionRoute ?? null,
            lineNumber: ''
        }));

        const workGroups: WorkGroupSliceWorkGroup[] = getWorkSheetsResponse.workGroups.map(workGroup => ({
            id: workGroup.id,
            versionId: workGroup.versionId,
            validFrom: workGroup.validFrom,
            validTo: workGroup.validTo,
            code: workGroup.code,
            workGroupItemIds: [...workGroup.activityIds, ...workGroup.tripDefinitionIds]
        }));

        const unassignedTripsState = {
            filterInput: '',
            charterTripRows: groupUnassignedTrips(unplannedCharterTripWorkItems),
            rows: groupUnassignedTrips(unplannedTripDefinitionWorkItems),
            otherRegionRows: []
        };

        return {
            plannerType: plannerType,
            plannerItemGroups: plannerItemGroups,
            workSheets: getWorkSheetsResponse.workScheduleItems,
            workItems: workItems,
            tripDefinitionWorkItems: unplannedTripDefinitionWorkItems,
            charterTripWorkItems: [...plannedCharterTripWorkItems, ...unplannedCharterTripWorkItems],
            workGroups: workGroups,
            tripDefinitions: tripDefinitions,
            activities: getWorkSheetsResponse.activities,
            oppositeGroupMarkerColorMap: oppositeWorkSheetMarkerColorMap,
            unassignedTripsState: unassignedTripsState
        };
    }
);

const doesItemPassMaxDriverWorkDayCheck = (
    resourceType: ResourceType,
    groupStartHh: number | null,
    groupEndHh: number | null,
    newItem: StartAndEndTimeWithModifier
) => {
    if (resourceType === ResourceType.DRIVER && isDurationOverDriverWorkDayLimitWithNewItem(
        groupStartHh, groupEndHh,
        getTimeInHours(newItem.startTime, newItem.startTimeIsOnNextDay),
        getTimeInHours(newItem.endTime, newItem.endTimeIsOnNextDay)
    )) {
        if (!window.confirm(`Tööpäev ületab lubatud ${MAX_DRIVER_WORK_GROUP_DURATION_HOURS} tunni piiri. Kas soovid reisi siiski lisada?`)) {
            return false;
        }
    }
    return true;
};

export const updateTripSegmentWorkGroup = createAsyncThunk<
    void,
    {
        movedTripSegmentId: EntityId,
        originWorkGroupId: EntityId,
        targetWorkGroupId: EntityId
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/updateTripSegmentWorkGroup',
    async ({movedTripSegmentId, originWorkGroupId, targetWorkGroupId}, { getState, dispatch }) => {
        if ((originWorkGroupId === 0 && targetWorkGroupId === 0) || (originWorkGroupId === targetWorkGroupId)) return;

        const state = getState();
        const movedTrip = selectTripDefinitionById(state, movedTripSegmentId);
        if (!movedTrip) return;
        const targetDisplayWorkGroup = targetWorkGroupId !== 0 ? selectDisplayWorkGroupById(state, targetWorkGroupId) : undefined;

        if (targetDisplayWorkGroup) {
            if (!doesItemPassMaxDriverWorkDayCheck(
                targetDisplayWorkGroup.type,
                targetDisplayWorkGroup.firstItemStartHh,
                targetDisplayWorkGroup.lastItemEndHh,
                movedTrip
            )) return;
        }
        const originGroup = originWorkGroupId !== 0 ? selectPlannerItemGroupById(state, originWorkGroupId) : undefined;
        const targetGroup = targetWorkGroupId !== 0 ? selectPlannerItemGroupById(state, targetWorkGroupId) : undefined;

        if (originGroup || targetGroup) {
            const tripDefinition = selectTripDefinitionById(state, movedTripSegmentId);
            if (tripDefinition) {
                moveTrip(state, dispatch, tripDefinition, originGroup, targetGroup);
            }
        }
    }
);

const moveTrip = (
    state: RootState,
    dispatch: AppDispatch,
    workGroupTripDefinition: WorkGroupTripDefinition,
    originGroup?: PlannerItemGroup,
    targetGroup?: PlannerItemGroup,
) => {
    const moveRequest = {
        date: state.view.date,
        from: originGroup ? Number(originGroup.id) : null,
        to: targetGroup ? Number(targetGroup.id) : null,
    };
    if (!originGroup && !targetGroup) {
        return;
    }
    apiMoveTripDefinition(workGroupTripDefinition.id, moveRequest)
        .then(async (response: MoveWorkGroupItemChanges) => {
            const plannerItemGroups: PlannerItemGroup[] = [];
            const connectedWorkGroups = workGroupTripDefinition.workGroups ? [...workGroupTripDefinition.workGroups] : [];
            const connectedOppositeWorkGroups = workGroupTripDefinition.workGroupsOfOppositeType ? [...workGroupTripDefinition.workGroupsOfOppositeType] : [];

            if (originGroup) {
                const updatedOriginGroup: PlannerItemGroup = {
                    ...originGroup,
                    tripDefinitionIds: originGroup.tripDefinitionIds.filter(id => id !== workGroupTripDefinition.id)
                };
                plannerItemGroups.push(updatedOriginGroup);

                response.removedFromWorkGroupVersions.forEach((removedFromGroup: ChangedWorkGroupVersion) => {
                    removeWorkGroupById(connectedWorkGroups, removedFromGroup.workGroupId);
                    removeWorkGroupById(connectedOppositeWorkGroups, removedFromGroup.workGroupId);
                });
            }

            if (targetGroup) {
                const updatedTargetGroup: PlannerItemGroup = {
                    ...targetGroup,
                    tripDefinitionIds: [...targetGroup.tripDefinitionIds, workGroupTripDefinition.id],
                };
                plannerItemGroups.push(updatedTargetGroup);

                const targetWorkGroup = selectWorkGroupById(state, targetGroup.id);
                if (targetWorkGroup) {
                    connectedWorkGroups.push(targetWorkGroup);
                }

                if (targetWorkGroup?.linkedWorkGroup) {
                    const linkedWorkGroup = await getLinkedWorkGroup(targetWorkGroup?.linkedWorkGroup?.id, dispatch);
                    if (linkedWorkGroup) connectedOppositeWorkGroups.push(linkedWorkGroup);
                }
            }
            if (plannerItemGroups.length > 0) {
                dispatch(updatePlannerItemGroup(plannerItemGroups));
                dispatch(updateWorkGroupTripDefinition({
                    workGroupTripDefinition: {...workGroupTripDefinition, workGroups: connectedWorkGroups, workGroupsOfOppositeType: connectedOppositeWorkGroups},
                    selectedRegionId: state.region.selected,
                    isFromOrToUnassigned: moveRequest.to === null || moveRequest.from === null
                }));
            }
            if (moveRequest.from || moveRequest.to) {
                dispatch(setToast({type: 'success', text: composeMoveResultMessage(response)}));
            }
        })
        .catch(apiError => {
            dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Reisi liigutamisel ühest grupist teise tekkis viga'}));
        });
};

const removeWorkGroupById = (workGroups: WorkGroup[], workGroupIdToRemove: number) => {
    const idx = workGroups.findIndex(workGroup => workGroup.id === workGroupIdToRemove);
    if (idx >= 0) {
        workGroups.splice(idx, 1);
    }
};

const composeMoveResultMessage = (changes: MoveWorkGroupItemChanges) => {
    const parts: string[] = ['Reis'];

    const nGroupsAddedTo = changes.addedToWorkGroupVersions.length;
    const nGroupsRemovedFrom = changes.removedFromWorkGroupVersions.length;

    if (nGroupsAddedTo > 0) {
        parts.push('lisati');
        parts.push(nGroupsAddedTo > 1 ? 'gruppidesse' : 'gruppi');
        parts.push(concatenateCodes(changes.addedToWorkGroupVersions));
    }

    if (nGroupsAddedTo > 0 && nGroupsRemovedFrom > 0) {
        parts.push('ning');
    }

    if (nGroupsRemovedFrom > 0) {
        parts.push('eemaldati');
        parts.push(nGroupsRemovedFrom > 1 ? 'gruppidest' : 'grupist');
        parts.push(concatenateCodes(changes.removedFromWorkGroupVersions));
    }

    return parts.join(' ');
};

const concatenateCodes = (versions: ChangedWorkGroupVersion[]) => {
    const codes = versions.map(item => item.code);
    if (codes.length > 1) {
        return `${(codes.slice(0, -1).join(", "))} ja ${codes[codes.length - 1]}`;
    } else {
        return codes[0];
    }
};

export const updateTripItemWorkSheet = createAsyncThunk<
    void,
    {
        movedTripSegmentId: EntityId,
        originWorkSheetId: EntityId,
        targetWorkSheetId: EntityId
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/updateTripDefinitionWorkSheet',
    async ({movedTripSegmentId, originWorkSheetId, targetWorkSheetId}, { getState, dispatch }) => {
        if ((originWorkSheetId === 0 && targetWorkSheetId === 0) || (originWorkSheetId === targetWorkSheetId)) return;

        const state = getState();
        const resourceType = selectToggledResourceType(state);
        const selectedRegion = selectSelectedRegion(state);
        const date = state.view.date;
        const allItems = selectAllItemTimesInPlannerItemGroupMemoized(state, targetWorkSheetId) ?? [];
        const {firstItemStartHh, lastItemEndHh} = getFirstItemStartAndLastItemEndHh(allItems);
        const targetWorkSheetIdAsNr = getWorkScheduleItemIdAsNr(targetWorkSheetId) ?? null;

        const originPlannerItemGroup = selectPlannerItemGroupById(state, originWorkSheetId);
        const targetPlannerItemGroup = selectPlannerItemGroupById(state, targetWorkSheetId);

        if (originWorkSheetId === 0) {
            const movedTripItem = selectPlannerTripWorkItemById(state, movedTripSegmentId);
            if (!movedTripItem || !doesItemPassMaxDriverWorkDayCheck(resourceType, firstItemStartHh, lastItemEndHh, movedTripItem)) return;
            const workSheetOfOppositeTypeId = movedTripItem.workSheetsOfOppositeType?.length
                ? movedTripItem.workSheetsOfOppositeType[0]
                : null;

            if (movedTripItem.workItemId) {
                const saveWorkItem: SaveWorkItem = {
                    ...movedTripItem,
                    tripDefinitionId: Number(movedTripSegmentId),
                    type: WorkGroupItemType.TRIP_DEFINITION,
                    date: date,
                    route: typeof movedTripItem.route !== 'string' ? movedTripItem.route ?? null : null,
                    driverWorkSheetId: resourceType === ResourceType.DRIVER ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId,
                    busWorkSheetId: resourceType === ResourceType.VEHICLE ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId
                };

                const targetGroupUpdates = targetPlannerItemGroup
                    ? {targetWorkSheetId: targetWorkSheetId, targetPlannerItemGroup: targetPlannerItemGroup}
                    : undefined;

                await updateWorkItem(movedTripItem.workItemId, dispatch, saveWorkItem, undefined, targetGroupUpdates).then(() => {
                    if (selectedRegion) {
                        dispatch(updateUnassignedTripsStateWithMovedTrip({
                            movedTrip: {id: movedTripItem.id, regionId: movedTripItem.regionId},
                            selectedRegionId: selectedRegion.id
                        }));
                    }
                });
            } else {
                const tripSegmentResources: TripSegmentResources = {
                    driverWorkSheetId: resourceType === ResourceType.DRIVER ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId,
                    busWorkSheetId: resourceType === ResourceType.VEHICLE ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId
                };
                setTripSegmentResources(Number(movedTripSegmentId), dayjs(date), tripSegmentResources)
                    .then(result => {
                        const newWorkItem: WorkItem = {
                            ...result,
                            tripId: result.trip?.id,
                            tripSegmentId: result.tripSegmentId,
                            code: result.trip?.code,
                            regionId: movedTripItem.regionId,
                            ...getStartAndEndTimeWithModifierFromDateTimeStr(result.startDateTime, result.endDateTime, result.date),
                            distance: result.distance ?? 0,
                            defectId: result.defectId ?? null,
                        };
                        dispatch(addWorkItem(newWorkItem));
                        dispatch(removeTripDefinitionWorkItem(movedTripSegmentId));
                        if (targetPlannerItemGroup) {
                            dispatch(updatePlannerItemGroupWorkItemIds({
                                groupId: targetWorkSheetId,
                                workItemIds: [...targetPlannerItemGroup.workItemIds, result.id]
                            }));
                        }
                        if (selectedRegion) {
                            dispatch(updateUnassignedTripsStateWithMovedTrip({
                                movedTrip: {id: Number(movedTripSegmentId), regionId: movedTripItem.regionId},
                                selectedRegionId: selectedRegion.id
                            }));
                        }
                    })
                    .catch(apiError => {
                        dispatch(setToast({
                            type: 'error',
                            text: mapErrors(apiError) ?? 'Reisi liigutamisel tekkis viga'
                        }));
                        throw apiError;
                    });
            }
        } else {
            const movedWorkItemTripDefinition = selectPlannerWorkItemById(state, movedTripSegmentId);
            if (!movedWorkItemTripDefinition || (targetWorkSheetId !== 0 && !doesItemPassMaxDriverWorkDayCheck(resourceType, firstItemStartHh, lastItemEndHh, movedWorkItemTripDefinition))) return;
            const workSheetOfOppositeTypeId = movedWorkItemTripDefinition.workSheetsOfOppositeType?.length ? movedWorkItemTripDefinition.workSheetsOfOppositeType[0] : null;

            const saveWorkItem: SaveWorkItem = {
                ...movedWorkItemTripDefinition,
                date: date,
                route: typeof movedWorkItemTripDefinition.route !== 'string' ? movedWorkItemTripDefinition.route ?? null : null,
                driverWorkSheetId: resourceType === ResourceType.DRIVER ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId,
                busWorkSheetId: resourceType === ResourceType.VEHICLE ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId,
                defectId: movedWorkItemTripDefinition.defectId ?? undefined
            };

            const originGroupUpdates = originPlannerItemGroup
                ? {originWorkSheetId: originWorkSheetId, originPlannerItemGroup: originPlannerItemGroup}
                : undefined;
            const targetGroupUpdates = targetPlannerItemGroup
                ? {targetWorkSheetId: targetWorkSheetId, targetPlannerItemGroup: targetPlannerItemGroup}
                : undefined;

            await updateWorkItem(Number(movedTripSegmentId), dispatch, saveWorkItem, originGroupUpdates, targetGroupUpdates).then(() => {
                // if moved to unassigned then create a TripDefinitionWorkItem
                if (!targetWorkSheetIdAsNr) {
                    if (movedWorkItemTripDefinition.tripSegmentId && movedWorkItemTripDefinition.tripId && selectedRegion) {
                        const newTripDefinitionWorkItem: WorkScheduleTripDefinitionWorkItem = {
                            ...movedWorkItemTripDefinition,
                            id: movedWorkItemTripDefinition.tripSegmentId,
                            tripId: movedWorkItemTripDefinition.tripId,
                            regionId: movedWorkItemTripDefinition.regionId ?? selectedRegion.id,
                            workItemId: movedWorkItemTripDefinition.id,
                            repetition: movedWorkItemTripDefinition.tripDefinitionRepetition,
                            route: movedWorkItemTripDefinition.route,
                        };
                        dispatch(addTripDefinitionWorkItem(newTripDefinitionWorkItem));
                        dispatch(updateUnassignedTripsStateWithMovedTrip({
                            movedTrip: {id: movedWorkItemTripDefinition.tripSegmentId, regionId: movedWorkItemTripDefinition.regionId},
                            selectedRegionId: selectedRegion.id
                        }))
                    }
                }
            });
        }
    }
);

export const updateActivityWorkSheet = createAsyncThunk<
    void,
    {
        movedActivityId: EntityId,
        originWorkSheetId: EntityId,
        targetWorkSheetId: EntityId
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/updateActivityWorkSheet',
    async ({movedActivityId, originWorkSheetId, targetWorkSheetId}, { getState, dispatch }) => {
        const state = getState();
        const resourceType = selectToggledResourceType(state);
        const date = state.view.date;
        const allItems = selectAllItemTimesInPlannerItemGroupMemoized(state, targetWorkSheetId) ?? [];
        const {firstItemStartHh, lastItemEndHh} = getFirstItemStartAndLastItemEndHh(allItems);
        const targetWorkSheetIdAsNr = getWorkScheduleItemIdAsNr(targetWorkSheetId) ?? null;

        const originPlannerItemGroup = selectPlannerItemGroupById(state, originWorkSheetId);
        const targetPlannerItemGroup = selectPlannerItemGroupById(state, targetWorkSheetId);

        const movedWorkItemActivity = selectPlannerWorkItemById(state, movedActivityId);
        if (!movedWorkItemActivity || !doesItemPassMaxDriverWorkDayCheck(resourceType, firstItemStartHh, lastItemEndHh, movedWorkItemActivity)) return;
        const workSheetOfOppositeTypeId = movedWorkItemActivity.workSheetsOfOppositeType?.length ? movedWorkItemActivity.workSheetsOfOppositeType[0] : null;

        const saveWorkItem: SaveWorkItem = {
            ...movedWorkItemActivity,
            date: date,
            route: null,
            defectId: movedWorkItemActivity.defectId ?? undefined,
            driverWorkSheetId: resourceType === ResourceType.DRIVER ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId,
            busWorkSheetId: resourceType === ResourceType.VEHICLE ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId,
        };

        const originGroupUpdates = originPlannerItemGroup
            ? {originWorkSheetId: originWorkSheetId, originPlannerItemGroup: originPlannerItemGroup}
            : undefined;
        const targetGroupUpdates = targetPlannerItemGroup
            ? {targetWorkSheetId: targetWorkSheetId, targetPlannerItemGroup: targetPlannerItemGroup}
            : undefined;

        await updateWorkItem(Number(movedActivityId), dispatch, saveWorkItem, originGroupUpdates, targetGroupUpdates);
    }
);

export const updateCharterTripWorkItem =createAsyncThunk<
    void,
    {
        movedCharterTripId: EntityId,
        originWorkSheetId: EntityId,
        targetWorkSheetId: EntityId
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/updateCharterTripWorkItem',
    async ({movedCharterTripId, originWorkSheetId, targetWorkSheetId}, { getState, dispatch }) => {
        if ((originWorkSheetId === 0 && targetWorkSheetId === 0) || (originWorkSheetId === targetWorkSheetId)) return;
        const state = getState();
        const date = state.view.date;
        const resourceType = selectToggledResourceType(state);
        const targetWorkSheetIdAsNr = getWorkScheduleItemIdAsNr(targetWorkSheetId);
        const selectedRegion = selectSelectedRegion(state);

        const originPlannerItemGroup = selectPlannerItemGroupById(state, originWorkSheetId);
        const targetPlannerItemGroup = selectPlannerItemGroupById(state, targetWorkSheetId);

        let workItemId: number;
        let movedCharterTrip: WorkScheduleCharterTripWorkItem | WorkItem | undefined;

        if (originWorkSheetId === 0) {
            movedCharterTrip = selectCharterTripWorkItemById(state, movedCharterTripId);
            workItemId = Number(movedCharterTrip?.workItemId);
        } else {
            movedCharterTrip = selectPlannerWorkItemById(state, movedCharterTripId);
            workItemId = Number(movedCharterTripId);
        }
        if (!movedCharterTrip) {
            dispatch(setToast({
                type: 'error',
                text: 'Tellimusveo liigutamisel tekkis viga'
            }));
            throw new Error('Charter trip not found');
        }

        const charterTripId = Number(movedCharterTrip?.charterTripId);

        const workSheetOfOppositeTypeId = movedCharterTrip.workSheetsOfOppositeType?.length ? movedCharterTrip.workSheetsOfOppositeType[0] : null;

        const saveWorkItem: SaveWorkItem = {
            charterTripId: charterTripId,
            date: date,
            distance: movedCharterTrip.distance,
            startDateTime: movedCharterTrip.startDateTime,
            endDateTime: movedCharterTrip.endDateTime,
            type: WorkGroupItemType.TRIP_DEFINITION,
            driverWorkSheetId: resourceType === ResourceType.DRIVER ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId,
            busWorkSheetId: resourceType === ResourceType.VEHICLE ? targetWorkSheetIdAsNr : workSheetOfOppositeTypeId,
            comment: null,
            route: null,
        };
        if (workItemId === 0) {
            await createWorkItem(saveWorkItem).then((result) => {
                if (movedCharterTrip) {
                    const newWorkItem: WorkItem = {
                        ...result,
                        charterTripId: result.charterTrip?.id,
                        regionId: result.charterTrip?.regionId,
                        distance: result.distance ?? 0,
                        departurePoint: result.charterTrip?.departurePoint,
                        destination: result.charterTrip?.destination,
                        route: result.charterTrip?.route,
                        code: result.charterTrip?.tripNumber ?? '',
                        defectId: result.defectId ?? null,
                        ...getStartAndEndTimeWithModifierFromDateTimeStr(result.startDateTime, result.endDateTime, result.date),
                    };
                    dispatch(addWorkItem(newWorkItem));
                    dispatch(removeCharterTripWorkItem(charterTripId));
                    if (targetPlannerItemGroup) {
                        dispatch(updatePlannerItemGroupWorkItemIds({
                            groupId: targetWorkSheetId,
                            workItemIds: [...targetPlannerItemGroup.workItemIds, result.id]
                        }));
                    }
                    if (selectedRegion) {
                        dispatch(updateUnassignedCharterTripsStateWithMovedTrip({
                            movedCharterTrip: {id: charterTripId, regionId: movedCharterTrip?.regionId},
                            selectedRegionId: selectedRegion.id
                        }));
                    }
                }
            }).catch((apiError) => {
                dispatch(setToast({
                    type: 'error',
                    text: mapErrors(apiError) ?? 'Tellimusveo liigutamisel tekkis viga'
                }));
                throw apiError;
            });
        } else {
            const originGroupUpdates = originPlannerItemGroup
                ? {originWorkSheetId: originWorkSheetId, originPlannerItemGroup: originPlannerItemGroup}
                : undefined;
            const targetGroupUpdates = targetPlannerItemGroup
                ? {targetWorkSheetId: targetWorkSheetId, targetPlannerItemGroup: targetPlannerItemGroup}
                : undefined;
            if (saveWorkItem.driverWorkSheetId === null && saveWorkItem.busWorkSheetId === null) {
                await deleteWorkItem(workItemId).then(() => {
                    if (originGroupUpdates && selectedRegion && movedCharterTrip) {
                        dispatch(removeWorkItem(workItemId));
                        const newWorkItem: WorkScheduleCharterTripWorkItem = {
                            comment: movedCharterTrip.comment,
                            departurePoint: movedCharterTrip.departurePoint ?? '',
                            destination: movedCharterTrip.destination ?? '',
                            distance: movedCharterTrip.distance,
                            workSheetsOfOppositeType: movedCharterTrip.workSheetsOfOppositeType,
                            id: charterTripId,
                            charterTripId: charterTripId,
                            startDateTime: movedCharterTrip.startDateTime,
                            endDateTime: movedCharterTrip.endDateTime,
                            regionId: movedCharterTrip.regionId ?? selectedRegion.id,
                            workItemId: 0,
                            route: movedCharterTrip.route ?? null,
                            code: movedCharterTrip.code ?? '',
                        };
                        dispatch(addCharterTripWorkItem(newWorkItem));
                        dispatch(updatePlannerItemGroupWorkItemIds({
                            groupId: originGroupUpdates.originWorkSheetId,
                            workItemIds: [...originGroupUpdates.originPlannerItemGroup.workItemIds].filter(id => id !== workItemId)
                        }));
                        dispatch(updateUnassignedCharterTripsStateWithMovedTrip({
                            movedCharterTrip: {id: Number(charterTripId), regionId: movedCharterTrip?.regionId},
                            selectedRegionId: selectedRegion.id
                        }));
                    }
                });
            } else {
                await updateWorkItem(workItemId, dispatch, saveWorkItem, originGroupUpdates, targetGroupUpdates)
                    .then(() => {
                        if (!targetWorkSheetIdAsNr) {
                            if (selectedRegion && movedCharterTrip) {
                                const newWorkItem: WorkScheduleCharterTripWorkItem = {
                                    comment: movedCharterTrip.comment,
                                    departurePoint: movedCharterTrip.departurePoint ?? '',
                                    destination: movedCharterTrip.destination ?? '',
                                    distance: movedCharterTrip.distance,
                                    workSheetsOfOppositeType: movedCharterTrip.workSheetsOfOppositeType,
                                    id: workItemId,
                                    charterTripId: charterTripId,
                                    startDateTime: movedCharterTrip.startDateTime,
                                    endDateTime: movedCharterTrip.endDateTime,
                                    regionId: movedCharterTrip.regionId ?? selectedRegion.id,
                                    workItemId: workItemId,
                                    route: movedCharterTrip.route ?? null,
                                    code: movedCharterTrip.code ?? '',
                                };
                                dispatch(removeCharterTripWorkItem(charterTripId));
                                dispatch(addCharterTripWorkItem(newWorkItem));
                            }
                        }
                        if (selectedRegion) {
                            dispatch(updateUnassignedCharterTripsStateWithMovedTrip({
                                movedCharterTrip: {id: Number(movedCharterTripId), regionId: movedCharterTrip?.regionId},
                                selectedRegionId: selectedRegion.id
                            }));
                        }
                    }).catch((apiError) => {
                        dispatch(setToast({
                            type: 'error',
                            text: mapErrors(apiError) ?? 'Tellimusveo liigutamisel tekkis viga'
                        }));
                        throw apiError;
                    });
            }
        }
    }
);


const updateWorkItem = (
    workItemId: number,
    dispatch: AppDispatch,
    updatedWorkItem: SaveWorkItem,
    originGroupUpdates?: {
        originWorkSheetId: EntityId,
        originPlannerItemGroup: PlannerItemGroup
    },
    targetGroupUpdates?: {
        targetWorkSheetId: EntityId,
        targetPlannerItemGroup: PlannerItemGroup,
    },
) => {
    return apiUpdateWorkItem(workItemId, updatedWorkItem)
        .then(() => {
            if (originGroupUpdates) {
                dispatch(updatePlannerItemGroupWorkItemIds({
                    groupId: originGroupUpdates.originWorkSheetId,
                    workItemIds: [...originGroupUpdates.originPlannerItemGroup.workItemIds].filter(id => id !== workItemId)
                }));
            }
            if (targetGroupUpdates) {
                dispatch(updatePlannerItemGroupWorkItemIds({
                    groupId: targetGroupUpdates.targetWorkSheetId,
                    workItemIds: [...targetGroupUpdates.targetPlannerItemGroup.workItemIds, workItemId]
                }));
            }
            dispatch(updateWorkItemResources({
                id: workItemId,
                busWorkSheetId: updatedWorkItem.busWorkSheetId,
                driverWorkSheetId: updatedWorkItem.driverWorkSheetId,
            }));
        })
        .catch(apiError => {
            dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Liigutamisel tekkis viga'}));
            throw apiError;
        });
};

export const addActivity = createAsyncThunk<
    {
        groupId: EntityId,
        activities?: WorkGroupActivity[],
        workItems?: WorkScheduleWorkItem[]
    },
    {
        groupId: EntityId,
        form: ActivityForm,
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/addActivity',
    async ({groupId, form}, { getState, dispatch }) => {
        if (form.startTime && form.endTime) {
            const state = getState();
            const plannerType = selectPlannerType(state);

            const newActivity: WorkGroupActivity = {
                type: form.type,
                startTime: getHhMmFromDate(form.startTime),
                startTimeIsOnNextDay: form.startTimeIsOnNextDay,
                endTime: getHhMmFromDate(form.endTime),
                endTimeIsOnNextDay: form.endTimeIsOnNextDay,
                distance: form.distance?.length ? strToDecimal(form.distance) : null,
            };

            if (plannerType === PlannerType.WORK_GROUP) {
                return apiCreateWorkGroupActivity(newActivity, Number(groupId), state.view.date)
                    .then(async (result) => {
                        const updatedActivity: WorkGroupActivity = {...newActivity, id: result.id, workGroupIds: [Number(groupId)]};
                        const workGroup = selectWorkGroupById(state, plannerType === PlannerType.WORK_GROUP ? groupId : 0);
                        if (workGroup?.linkedWorkGroup) {
                            const linkedWorkGroup = await getLinkedWorkGroup(workGroup?.linkedWorkGroup?.id, dispatch);
                            if (linkedWorkGroup) updatedActivity.workGroupsOfOppositeType = [linkedWorkGroup];
                        }

                        dispatch(setToast({type: 'success', text: 'Tegevus edukalt lisatud'}));
                        dispatch(clearDialogData());
                        return {groupId: groupId, activities: [updatedActivity]};
                    })
                    .catch(apiError => {
                        dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Tegevuse lisamisel esines tõrge'}));
                        throw apiError;
                    });
            }
            if (plannerType === PlannerType.WORK_SHEET) {
                const workSheet = selectWorkScheduleItemById(state, groupId);
                if (workSheet) {
                    const newWorkItem: SaveWorkItem = {
                        ...newActivity,
                        startDateTime: getStrDateTimeFromDateWithModifier(form.startTime, form.startTimeIsOnNextDay, workSheet.startDate),
                        endDateTime: getStrDateTimeFromDateWithModifier(form.endTime, form.endTimeIsOnNextDay, workSheet.startDate),
                        busWorkSheetId: workSheet.resourceType === ResourceType.VEHICLE ? (workSheet.id ?? null) : null,
                        driverWorkSheetId: workSheet.resourceType === ResourceType.DRIVER ? (workSheet.id ?? null) : null,
                        date: workSheet.startDate,
                        route: null,
                        comment: form.comment.length > 0 ? form.comment : null,
                        defectId: form.defectId ?? undefined
                    };

                    return apiCreateWorkItem(newWorkItem)
                        .then(result => {
                            dispatch(setToast({type: 'success', text: 'Tegevus edukalt lisatud'}));
                            dispatch(clearDialogData());
                            return {
                                groupId: groupId,
                                workItems: [{
                                    ...newWorkItem,
                                    id: result.id,
                                    distance: newActivity.distance ?? 0,
                                    route: result.route,
                                    defectId: result.defectId ?? null
                                }],
                            };
                        })
                        .catch(apiError => {
                            dispatch(setToast({
                                type: 'error',
                                text: mapErrors(apiError) ?? 'Tegevuse lisamisel tekkis viga'
                            }));
                            throw apiError;
                        });
                }
            }
        }
        throw new Error('Tegevuse lisamisel esines tõrge');
    }
);

export const editActivity = createAsyncThunk<
    {
        groupId: EntityId,
        activities?: WorkGroupActivity[],
        workItems?: WorkScheduleWorkItem[]
    },
    {
        groupId: EntityId,
        activityId: EntityId,
        form: ActivityForm,
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/editActivity',
    async ({groupId, activityId, form}, { getState, dispatch }) => {
        if (form.startTime && form.endTime) {
            const state = getState();
            const plannerType = selectPlannerType(state);

            if (plannerType === PlannerType.WORK_GROUP) {
                const originalActivity = selectActivityById(state, activityId);

                if (originalActivity) {
                    const updatedActivity: WorkGroupActivity = {
                        ...originalActivity,
                        startTime: getHhMmFromDate(form.startTime),
                        startTimeIsOnNextDay: form.startTimeIsOnNextDay,
                        endTime: getHhMmFromDate(form.endTime),
                        endTimeIsOnNextDay: form.endTimeIsOnNextDay,
                        distance: form.distance?.length ? strToDecimal(form.distance) : null,
                    };

                    return apiUpdateWorkGroupActivity(activityId, updatedActivity)
                        .then(() => {
                            dispatch(setToast({type: 'success', text: 'Tegevus edukalt muudetud'}));
                            dispatch(clearDialogData());
                            return {groupId: groupId, activities: [{...updatedActivity, id: Number(activityId)}]};
                        })
                        .catch(apiError => {
                            dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Tegevuse muutmisel esines tõrge'}));
                            throw apiError;
                        });
                }
            }
            if (plannerType === PlannerType.WORK_SHEET) {
                const workSheet = selectWorkScheduleItemById(state, groupId);
                const workItem = selectWorkItemById(state, activityId);

                if (workSheet && workItem) {
                    const updatedWorkItem: SaveWorkItem = {
                        ...workItem,
                        startDateTime: getStrDateTimeFromDateWithModifier(form.startTime, form.startTimeIsOnNextDay, workSheet.startDate),
                        endDateTime: getStrDateTimeFromDateWithModifier(form.endTime, form.endTimeIsOnNextDay, workSheet.startDate),
                        date: workSheet.startDate,
                        distance: form.distance?.length ? strToDecimal(form.distance) : null,
                        route: typeof workItem.route !== 'string' ? workItem.route ?? null : null,
                        defectId: form.defectId ?? undefined,
                        comment: form.comment,
                    };

                    return apiUpdateWorkItem(activityId, updatedWorkItem)
                        .then(() => {
                            dispatch(setToast({type: 'success', text: 'Tegevus edukalt muudetud'}));
                            dispatch(clearDialogData());
                            return {
                                groupId: groupId,
                                workItems: [{
                                    ...workItem,
                                    ...updatedWorkItem,
                                    id: Number(activityId),
                                    route: workItem.route,
                                    distance: updatedWorkItem.distance ?? 0
                                }],
                            };
                        })
                        .catch(apiError => {
                            dispatch(setToast({
                                type: 'error',
                                text: mapErrors(apiError) ?? 'Tegevuse muutmisel tekkis viga'
                            }));
                            throw apiError;
                        });
                }
            }
        }
        throw new Error('Tegevuse muutmisel esines tõrge');
    }
);

export const addPreparationAndFinishingTime = createAsyncThunk<
    {
        groupId: EntityId,
        activities?: WorkGroupActivity[],
        workItems?: WorkScheduleWorkItem[]
    },
    EntityId,
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/addPreparationAndFinishingTime',
    async (groupId, { getState, dispatch }) => {
        const state = getState();
        const plannerType = selectPlannerType(state);
        const allItems = selectAllItemTimesInPlannerItemGroupMemoized(state, groupId);
        const group = selectPlannerItemGroupById(state, groupId);

        if (group && allItems) {
            if (allItems.length < 1) {
                dispatch(setToast({type: 'error', text: 'Töögrupis peab ettevalmistus- ja lõpetusaja lisamiseks olema vähemalt üks tegevus'}));
            } else {
                const firstItemStartHh = allItems[0].startHh;
                const lastItemEndHh = allItems[allItems.length - 1].endHh;
                const defaultActivityDurationHh = getHhFromMin(DEFAULT_PREPARATION_AND_FINISHING_TIME_MINUTES);

                if (isDriverWorkDurationOverLimit(
                    group.type,
                    firstItemStartHh - defaultActivityDurationHh,
                    lastItemEndHh + defaultActivityDurationHh,
                    allItems
                )) {
                    if (!window.confirm(
                        'Tegevuste lisamisel ületab töögrupp lubatud ' + MAX_DRIVER_WORK_GROUP_DURATION_HOURS
                        + ' tunni piiri, kas soovid tegevused siiski lisada?'
                    )) {
                        throw new Error('Alustus- ja lõpetusaja lisamine katkestatud');
                    }
                }

                const preparationStartHh = firstItemStartHh - defaultActivityDurationHh;
                const preparationStart = getDateWithModifierFromHhNr(preparationStartHh);
                const preparationEnd = getDateWithModifierFromHhNr(firstItemStartHh);

                const finishingEndHh = lastItemEndHh + defaultActivityDurationHh;
                const finishingStart = getDateWithModifierFromHhNr(lastItemEndHh);
                const finishingEnd = getDateWithModifierFromHhNr(finishingEndHh);

                if (plannerType === PlannerType.WORK_GROUP) {
                    const workGroup = selectWorkGroupById(state, groupId);

                    const preparationActivity = {
                        type: WorkGroupItemType.PREPARATION_TIME,
                        startTime: getHhMmFromDate(preparationStart.date),
                        startTimeIsOnNextDay: preparationStart.isOnNextDay,
                        endTime: getHhMmFromDate(preparationEnd.date),
                        endTimeIsOnNextDay: preparationEnd.isOnNextDay,
                        distance: null
                    };

                    const finishingActivity = {
                        type: WorkGroupItemType.FINISHING_TIME,
                        startTime: getHhMmFromDate(finishingStart.date),
                        startTimeIsOnNextDay: finishingStart.isOnNextDay,
                        endTime: getHhMmFromDate(finishingEnd.date),
                        endTimeIsOnNextDay: finishingEnd.isOnNextDay,
                        distance: null
                    };

                    const preparationActivityResult = await apiCreateWorkGroupActivity(preparationActivity, Number(groupId), state.view.date)
                        .catch(apiError => {
                            dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Alustusaja lisamisel esines tõrge'}));
                            throw apiError;
                        });
                    const finishingActivityResult = await apiCreateWorkGroupActivity(finishingActivity, Number(groupId), state.view.date)
                        .catch(apiError => {
                            dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Lõpetusaja lisamisel esines tõrge'}));
                            throw apiError;
                        });

                    dispatch(setToast({type: 'success', text: 'Tegevused edukalt lisatud'}));

                    if (workGroup?.linkedWorkGroup) {
                        const linkedWorkGroup = await getLinkedWorkGroup(workGroup?.linkedWorkGroup?.id, dispatch);
                        if (linkedWorkGroup) {
                            return {groupId: groupId, activities: [
                                {...preparationActivityResult, workGroupsOfOppositeType: [linkedWorkGroup]},
                                {...finishingActivityResult, workGroupsOfOppositeType: [linkedWorkGroup]},
                            ]};
                        }
                    }

                    return {groupId: groupId, activities: [preparationActivityResult, finishingActivityResult]};
                }
                if (plannerType === PlannerType.WORK_SHEET) {
                    const workSheet = selectWorkScheduleItemById(state, groupId);
                    if (workSheet) {

                        const defaultWorkItemProps = {
                            busWorkSheetId: workSheet.resourceType === ResourceType.VEHICLE ? (workSheet.id ?? null) : null,
                            driverWorkSheetId: workSheet.resourceType === ResourceType.DRIVER ? (workSheet.id ?? null) : null,
                            date: workSheet.startDate,
                            distance: null,
                            comment: null,
                            startingPoint: null,
                            endingPoint: null,
                            route: null
                        };

                        const preparationWorkItem: SaveWorkItem = {
                            type: WorkGroupItemType.PREPARATION_TIME,
                            startDateTime: getStrDateTimeFromDateWithModifier(preparationStart.date, preparationStart.isOnNextDay, workSheet.startDate),
                            endDateTime: getStrDateTimeFromDateWithModifier(preparationEnd.date, preparationEnd.isOnNextDay, workSheet.startDate),
                            ...defaultWorkItemProps
                        };

                        const finishingWorkItem: SaveWorkItem = {
                            type: WorkGroupItemType.FINISHING_TIME,
                            startDateTime: getStrDateTimeFromDateWithModifier(finishingStart.date, finishingStart.isOnNextDay, workSheet.startDate),
                            endDateTime: getStrDateTimeFromDateWithModifier(finishingEnd.date, finishingEnd.isOnNextDay, workSheet.startDate),
                            ...defaultWorkItemProps
                        };

                        const preparationWorkItemResult = await apiCreateWorkItem(preparationWorkItem)
                            .catch(apiError => {
                                dispatch(setToast({
                                    type: 'error',
                                    text: mapErrors(apiError) ?? 'Alustusaja lisamisel esines tõrge'
                                }));
                                throw apiError;
                            });

                        return apiCreateWorkItem(finishingWorkItem)
                            .then((finishingWorkItemResult) => {
                                dispatch(setToast({type: 'success', text: 'Tegevused edukalt lisatud'}));
                                return {
                                    groupId: groupId,
                                    workItems: [{
                                        id: preparationWorkItemResult.id,
                                        ...preparationWorkItem,
                                        route: preparationWorkItemResult.route,
                                        distance: preparationWorkItemResult.distance ?? 0,
                                        defectId: null
                                    }, {
                                        id: finishingWorkItemResult.id,
                                        ...finishingWorkItem,
                                        route: finishingWorkItemResult.route,
                                        distance: finishingWorkItemResult.distance ?? 0,
                                        defectId: null
                                    }]
                                };
                            })
                            .catch(apiError => {
                                dispatch(setToast({
                                    type: 'error',
                                    text: mapErrors(apiError) ?? 'Lõpetusaja lisamisel esines tõrge'
                                }));
                                throw apiError;
                            });

                    }
                }
            }
        }
        throw new Error('Alustus- ja lõpetusaja lisamisel esines tõrge');
    }
);

export const removeActivityFromWorkGroup = createAsyncThunk<
    {
        activityId: EntityId,
        groupId: EntityId
    },
    {
        activityId: EntityId,
        groupId: EntityId
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/removeActivityFromWorkGroup',
    async ({activityId, groupId}, { getState, dispatch }) => {
        const state = getState();
        const workGroup = selectDisplayWorkGroupById(state, groupId);

        if (workGroup) {
            return apiRemoveActivityFromWorkGroup(activityId, groupId, state.view.date)
                .then(() => {
                    dispatch(setToast({type: 'success', text: 'Tegevus edukalt kustutatud'}));
                    return {
                        groupId: groupId,
                        activityId: activityId
                    }
                })
                .catch(apiError => {
                    dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Tegevuse kustutamisel esines tõrge'}));
                    throw apiError;
                });
        }
        throw new Error('Tegevuse kustutamisel esines tõrge');
    }
);

export const deleteWorkSheetActivity = createAsyncThunk<
    {
        workItemId: EntityId,
        groupId: EntityId
    },
    {
        activityId: EntityId,
        groupId: EntityId
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/deleteWorkSheetActivity',
    async ({activityId, groupId}, { getState, dispatch }) => {
        const state = getState();
        const toggledResourceType = selectToggledResourceType(state);
        const workSheet = selectWorkScheduleItemById(state, groupId);
        const workItem = selectPlannerWorkItemById(state, activityId);

        if (workItem && workSheet) {
            const workSheetId = toggledResourceType === ResourceType.DRIVER
                ? workItem.driverWorkSheetId
                : workItem.busWorkSheetId;

            if (workSheetId) {
                return removeWorkItemResource(workItem.id, workSheetId)
                    .then(() => {
                        return {groupId: groupId, workItemId: activityId};
                    })
                    .catch(apiError => {
                        dispatch(setToast({
                            type: 'error',
                            text: mapErrors(apiError) ?? 'Tegevuse kustutamisel tekkis viga'
                        }));
                        throw apiError;
                    });
            }
        }
        throw new Error('Tegevuse kustutamisel tekkis viga');
    }
);

export const addOppositeWorkGroupToActivity = createAsyncThunk<
    {
        activityId: EntityId,
        workGroupsOfOppositeType: WorkGroup[],
        workGroupId: number
    },
    {
        activityId: EntityId,
        newWorkGroup: WorkGroup,
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/addOppositeWorkGroupToActivity',
    async ({activityId, newWorkGroup}, { getState, dispatch }) => {
        const state = getState();
        const activity = selectActivityById(state, activityId);

        if (activity) {
            return apiAddActivityToWorkGroup(Number(activityId), newWorkGroup.id, state.view.date)
                .then(() => {
                    dispatch(clearDialogData());
                    return {activityId: activityId, workGroupsOfOppositeType: [newWorkGroup], workGroupId: newWorkGroup.id};
                })
                .catch(apiError => {
                    dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Töögrupi lisamisel esines tõrge'}));
                    throw apiError;
                });
        }

        throw 'Töögrupi lisamisel esines tõrge';
    }
);

export const addOppositeWorkGroupToTrip = createAsyncThunk<
    {
        tripId: EntityId,
        workGroupsOfOppositeType: WorkGroup[],
        workGroupId: number,
    },
    {
        tripId: EntityId,
        newWorkGroup: WorkGroup,
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/addOppositeWorkGroupToTrip',
    async ({tripId, newWorkGroup}, { getState, dispatch }) => {
        const state = getState();
        return apiMoveTripDefinition(Number(tripId), {date: state.view.date, from: null, to: newWorkGroup.id})
            .then(() => {
                dispatch(clearDialogData());
                return {tripId: tripId, workGroupsOfOppositeType: [newWorkGroup], workGroupId: newWorkGroup.id};
            })
            .catch(apiError => {
                dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Töögrupi lisamisel esines tõrge'}));
                throw apiError;
            });
    }
);

export const addOppositeWorkSheetToActivity = createAsyncThunk<
    {
        workItemId: EntityId,
        workSheetsOfOppositeType: number[]
    },
    {
        workItemId: EntityId,
        busWorkSheetId: number,
        driverWorkSheetId: number,
    },
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/addOppositeWorkSheetToActivity',
    async ({workItemId, busWorkSheetId, driverWorkSheetId}, { getState, dispatch }) => {
        const state = getState();
        const workItem = selectWorkItemById(state, workItemId);
        const resourceType = selectToggledResourceType(state);
        const workSheet = selectWorkScheduleItemByEntityId(state, busWorkSheetId as number);
        if (workItem && workSheet) {
            const saveWorkItem: SaveWorkItem = {
                ...workItem,
                route: typeof workItem.route !== 'string' ? workItem.route ?? null : null,
                startDateTime: workItem.startDateTime,
                endDateTime: workItem.endDateTime,
                busWorkSheetId: Number(busWorkSheetId),
                driverWorkSheetId: Number(driverWorkSheetId),
                date: workSheet.startDate,
                defectId: workItem.defectId ?? undefined,
            };

            return apiUpdateWorkItem(workItem.id, saveWorkItem)
                .then(() => {
                    dispatch(clearDialogData());
                    return {
                        workItemId: workItem.id,
                        workSheetsOfOppositeType: [resourceType === ResourceType.DRIVER ? Number(busWorkSheetId) : Number(driverWorkSheetId)]
                    };
                })
                .catch(apiError => {
                    dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? `Tekkis viga`}));
                    throw apiError;
                });
        }
        throw new Error('Vastastöögrupi lisamisel esines tõrge');
    }
);

export const addWorkSheet = createAsyncThunk<
    WorkScheduleItem,
    {resourceId: number},
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'planner/addWorkSheet',
    async ({ resourceId }, { getState, dispatch }) => {
        const state = getState();
        const date = selectSelectedDay(state);
        const resourceType = selectToggledResourceType(state);
        const region = selectSelectedRegion(state);


        const data: SaveWorkScheduleItem = {
            workGroupId: null,
            type: WorkScheduleItemType.WORK_GROUP,
            startDate: date,
            endDate: date,
            status: WorkScheduleItemStatus.GENERATED,
            comment: '',
            driverContractId: resourceType === ResourceType.DRIVER ? resourceId : undefined,
            busId: resourceType === ResourceType.VEHICLE ? resourceId : undefined,
            regionId: region?.id,
            resourceType: resourceType,
        };

        return createWorkScheduleItem(data).then(workScheduleItem => {
            dispatch(setToast({type: 'success', text: 'Sõiduleht edukalt loodud'}));
            return workScheduleItem;
        }).catch((e) => {
            dispatch(setToast({type: 'error', text: 'Sõiduleht loomisel tekkis viga'}));
            throw e;
        })
    }
);

export const resetUnassignedTripsState = createAsyncThunk<
    UnassignedTripsState,
    void,
    { state: RootState }
>(
    'planner/resetUnassignedTripsState',
    async (_, { getState }) => {
        const state = getState();
        const plannerType = selectPlannerType(state);
        const tripItems: (TripDefinitionWorkItem | WorkGroupTripDefinition)[] = [];

        if (plannerType === PlannerType.WORK_SHEET) tripItems.push(...selectAllPlannerTripDefinitionWorkItems(state));
        if (plannerType === PlannerType.WORK_GROUP) tripItems.push(...selectAllTripDefinitions(state));

        const charterTripItems = selectAllPlannerCharterTripWorkItems(state);

        return getResetUnassignedTripsState(tripItems, charterTripItems, state.planner.unassignedTripsState);
    }
);

export const copyOppositeWorkGroup = createAsyncThunk<
    {
        originalWorkGroupId: number,
        newWorkGroup: WorkGroup,
        date: string,
    },
    {
        originalWorkGroupId: number,
        data: CopyOppositeWorkGroupRequest,
    },
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'planner/copyOppositeWorkGroup',
    async (
        {originalWorkGroupId, data},
        {dispatch, getState}
    ) => {
        const date = getState().view.date;
        const newWorkGroup = await apiCopyOppositeWorkGroup(originalWorkGroupId, data)
            .catch((error: ApiError) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Töögrupi lisamisel tekkis viga'}));
                throw error;
            });

        return {
            originalWorkGroupId: originalWorkGroupId,
            newWorkGroup: newWorkGroup,
            date: date,
        }
    },
);

export const splitTrip = createAsyncThunk<
    {
        selectedRegionId: number,
        toggledResourceType: ResourceType,
        updatedSegments: WorkGroupTripDefinition[],
        hasCreatedNewSegment: boolean,
    },
    {
        tripId: EntityId,
        originalSegments: WorkGroupTripDefinition[],
        form: SplitTripForm,
        formHelpers: FormikHelpers<SplitTripForm>,
    },
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'planner/splitTrip',
    async ({tripId, originalSegments, form, formHelpers}, {getState, dispatch}) => {
        const state = getState();
        const selectedRegion = selectSelectedRegion(state);
        const toggledResourceType = selectToggledResourceType(state);

        if (!form.splitRoutePoint) {
            dispatch(setToast({type: 'error', text: 'Reisi poolitamisel tekkis viga'}));
            throw Error()
        } else {
            const updatedSegments = await apiSplitTrip(tripId, {
                routePointId: form.splitRoutePoint.id,
                distance: form.distance2 ? strToDecimal(form.distance2) : 0.0
            })
                .catch((error: ApiError) => {
                    dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Reisi poolitamisel tekkis viga'}));
                    throw error;
                })
                .finally(() => formHelpers.setSubmitting(false));

            return {
                selectedRegionId: selectedRegion?.id ?? 0,
                toggledResourceType: toggledResourceType,
                updatedSegments: updatedSegments.map(updatedSegment => {
                    const originalSegment = originalSegments.find(original => original.id === updatedSegment.id) ?? originalSegments[0];

                    return {
                        ...originalSegment,
                        ...updatedSegment,
                        workGroups: originalSegment.workGroups
                    } as WorkGroupTripDefinition
                }),
                hasCreatedNewSegment: originalSegments.length === 1
            }
        }
    },
);

export const linkWorkGroup = createAsyncThunk<
    {
        workGroupId: EntityId,
        linkedWorkGroup: LinkedWorkGroupDTO,
    },
    {
        workGroupId: EntityId,
        linkedWorkGroup: LinkedWorkGroupDTO,
    },
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'planner/linkWorkGroup',
    async ({workGroupId, linkedWorkGroup}, {dispatch}) => {
        await apiLinkWorkGroup(workGroupId, linkedWorkGroup.id)
            .catch((error: ApiError) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Töögruppide sidumisel tekkis tõrge'}));
                throw error;
            });

        dispatch(setToast({
            type: 'success',
            text: 'Töögrupid edukalt seotud',
        }));
        dispatch(clearDialogData());

        return {
            workGroupId: workGroupId,
            linkedWorkGroup: linkedWorkGroup,
        }
    },
);

export const unlinkWorkGroup = createAsyncThunk<
    {
        workGroupId: EntityId,
    },
    {
        workGroupId: EntityId,
    },
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'planner/unlinkWorkGroup',
    async ({workGroupId}, {dispatch}) => {
        await apiUnlinkWorkGroup(workGroupId)
            .catch((error: ApiError) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Töögruppide lahti sidumisel tekkis tõrge'}));
                throw error;
            });

        dispatch(setToast({
            type: 'success',
            text: 'Töögrupid edukalt lahti seotud',
        }));

        return { workGroupId: workGroupId }
    },
);

export interface MergeTripResponse {
    selectedRegionId: number;
    toggledResourceType: ResourceType;
    originalSegment: WorkGroupTripDefinition;
    updatedSegment: WorkGroupTripDefinition;
    removedSegment: WorkGroupTripDefinition;
}

export const mergeTrip = createAsyncThunk<
    {
        mergeTripResponse?: MergeTripResponse,
    },
    {
        tripId: EntityId,
        originalSegments: WorkGroupTripDefinition[],
        confirmed: boolean,
    },
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'planner/mergeTrip',
    async ({tripId, originalSegments, confirmed}, {getState, dispatch}) => {
        const state = getState();
        const selectedRegion = selectSelectedRegion(state);
        const toggledResourceType = selectToggledResourceType(state);

        if (originalSegments.length < 2) {
            dispatch(setToast({type: 'error', text: 'Reisi liitmiseks peab olema kaks osa'}));
            throw Error;
        }

        const result = await apiMergeTrip(tripId, { confirmed: confirmed })
            .catch((error: ApiError) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Reisi liitmisel tekkis viga'}));
                throw error;
            });

        if (!confirmed && result.removedFromWorkGroupVersions.length > 0) {
            dispatch(setDialogData({ mergeTrip: {
                tripId: tripId,
                tripCode: result.trip.code,
                originalSegments: originalSegments,
                removedFromWorkGroupVersions: result.removedFromWorkGroupVersions
            } }));
            return {};
        }

        const joinedSegment = result.trip;
        const originalSegment = originalSegments.find(original => original.id === joinedSegment?.id);
        if (!originalSegment) {
            dispatch(setToast({type: 'error', text: 'Reisi liitmisel tekkis viga'}));
            throw Error;
        }
        const updatedWorkGroups = originalSegment.workGroups?.filter(wg =>
            !(result.removedFromWorkGroupVersions.find(wgv => wgv.workGroupId === wg.id))
        );
        const updatedWorkGroupsOfOppositeType = originalSegment.workGroupsOfOppositeType?.filter(wg =>
            !(result.removedFromWorkGroupVersions.find(wgv => wgv.workGroupId === wg.id))
        );

        return {
            mergeTripResponse: {
                selectedRegionId: selectedRegion?.id ?? 0,
                toggledResourceType: toggledResourceType,
                updatedSegment: {
                    ...originalSegment,
                    ...joinedSegment,
                    workGroups: updatedWorkGroups,
                    workGroupsOfOppositeType: updatedWorkGroupsOfOppositeType
                } as WorkGroupTripDefinition,
                removedSegment: originalSegments[1],
                originalSegment: originalSegment,
            }
        };
    },
);

export const plannerItemGroupAdapter = createEntityAdapter<PlannerItemGroup>({
    sortComparer: (a, b) => compareRegionalItemsByCode(a, b),
});
export const workGroupAdapter = createEntityAdapter<WorkGroup>();
export const tripDefinitionAdapter = createEntityAdapter<WorkGroupTripDefinition>();
export const activityAdapter = createEntityAdapter<WorkGroupActivity>();

const initialState: PlannerState = {
    plannerType: PlannerType.WORK_GROUP,
    isLoading: true,
    error: undefined,
    contentWidth: 0,
    plannerItemGroups: plannerItemGroupAdapter.getInitialState(),
    oppositeGroupMarkerColorMap: {},
    workGroups: workGroupAdapter.getInitialState(),
    tripDefinitions: tripDefinitionAdapter.getInitialState(),
    activities: activityAdapter.getInitialState(),

    unassignedTripsState: {
        filterInput: '',
        rows: [],
        charterTripRows: [],
        otherRegionRows: [],
    },
    unassignedTripsSectionHeight: '20%',
    dialogData: {
        addOtherRegionTrip: false
    },
};

export const plannerSlice = createSlice({
    name: 'planner',
    initialState: initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setContentWidth: (state, action: PayloadAction<number>) => {
            state.contentWidth = action.payload;
        },
        updatePlannerItemGroup: (state, action: PayloadAction<PlannerItemGroup[]>) => {
            plannerItemGroupAdapter.setMany(state.plannerItemGroups, action.payload);
        },
        updatePlannerItemGroupWorkItemIds: (state, action: PayloadAction<{groupId: EntityId, workItemIds: number[]}>) => {
            plannerItemGroupAdapter.updateOne(state.plannerItemGroups, {
                id: action.payload.groupId,
                changes: {
                    workItemIds: action.payload.workItemIds
                }
            });
        },
        addOrReplaceWorkGroup: (
            state,
            action: PayloadAction<{newWorkGroup: WorkGroup, oldVersionWorkGroupId?: number}>
        ) => {
            const newPlannerItemGroup: PlannerItemGroup = {
                ...action.payload.newWorkGroup,
                workGroupCode: action.payload.newWorkGroup.code,
                tripDefinitionIds: action.payload.newWorkGroup.tripDefinitions.map(tripDefinition => tripDefinition.id),
                activityIds: action.payload.newWorkGroup.activities.filter(activity => activity.id !== undefined).map(activity => activity.id as number),
                workItemIds: [],
                otherRegionId: null,
            };
            plannerItemGroupAdapter.upsertOne(state.plannerItemGroups, newPlannerItemGroup);
            workGroupAdapter.upsertOne(state.workGroups, action.payload.newWorkGroup);
            if (action.payload.oldVersionWorkGroupId) {
                plannerItemGroupAdapter.removeOne(state.plannerItemGroups, action.payload.newWorkGroup.id);
                workGroupAdapter.removeOne(state.workGroups, action.payload.newWorkGroup.id);
            }
            state.dialogData = {};
        },
        updateWorkGroup: (state, action: PayloadAction<WorkGroup[]>) => {
            workGroupAdapter.setMany(state.workGroups, action.payload);
        },
        updateWorkGroupValidDatesAndActivities: (state, action: PayloadAction<{workGroupId: EntityId, validFrom: string, validTo: string | null, activities: WorkGroupActivity[]}>) => {
            const selectPlannerGroupById = plannerItemGroupAdapter.getSelectors().selectById;
            const selectActivityById = activityAdapter.getSelectors().selectById;
            workGroupAdapter.updateOne(state.workGroups, {id: action.payload.workGroupId, changes: {
                validFrom: action.payload.validFrom,
                validTo: action.payload.validTo
            }});
            const existingActivityIds = selectPlannerGroupById(state.plannerItemGroups, action.payload.workGroupId)?.activityIds;
            if (existingActivityIds) {
                existingActivityIds.forEach((activityId) => {
                    const existingActivity = selectActivityById(state.activities, activityId);
                    const newActivity = action.payload.activities.find(activity =>
                        activity.startTime === existingActivity?.startTime && activity.startTimeIsOnNextDay === existingActivity.startTimeIsOnNextDay
                    );
                    if (newActivity) {
                        activityAdapter.updateOne(state.activities, {
                            id: activityId,
                            changes: {
                                id: newActivity.id,
                            }
                        });
                    }
                })
            }
            plannerItemGroupAdapter.updateOne(state.plannerItemGroups, {
                id: action.payload.workGroupId,
                changes: {
                    activityIds: action.payload.activities.map(it => it.id) as number[],
                }
            })
        },
        removeWorkGroup: (state, action: PayloadAction<EntityId>) => {
            plannerItemGroupAdapter.removeOne(state.plannerItemGroups, action.payload);
            workGroupAdapter.removeOne(state.workGroups, action.payload);
        },
        setSelectedOppositeGroupId: (state, action: PayloadAction<number | undefined>) => {
            if (state.selectedOppositeGroupId === action.payload) {
                if (state.selectedOppositeGroupId !== undefined) state.selectedOppositeGroupId = undefined;
            } else {
                state.selectedOppositeGroupId = action.payload;
            }
        },
        addUnassignedOtherRegionWorkGroupTripDefinition: (state, action: PayloadAction<WorkGroupTripDefinition>) => {
            state.unassignedTripsState.otherRegionRows = getUpdatedUnassignedTripRows(action.payload.id, state.unassignedTripsState.otherRegionRows);
            tripDefinitionAdapter.upsertOne(state.tripDefinitions, action.payload);
            action.payload.workGroupsOfOppositeType?.forEach(workGroup => {
                if (!state.oppositeGroupMarkerColorMap[workGroup.id]) {
                    state.oppositeGroupMarkerColorMap[workGroup.id] = getRandomColorIndex();
                }
            });
        },
        updateUnassignedTripsStateWithMovedTrip: (state, action: PayloadAction<{movedTrip: {id: number, regionId?: number}, selectedRegionId: number }>) => {
            state.unassignedTripsState = getUpdatedUnassignedTripsState(
                {id: action.payload.movedTrip.id, regionId: action.payload.movedTrip.regionId ?? action.payload.selectedRegionId},
                state.unassignedTripsState,
                action.payload.selectedRegionId,
            )
        },
        updateUnassignedCharterTripsStateWithMovedTrip: (state, action: PayloadAction<{movedCharterTrip: {id: number, regionId?: number}, selectedRegionId: number }>) => {
            state.unassignedTripsState = getUpdatedUnassignedCharterTripsState(
                {id: action.payload.movedCharterTrip.id, regionId: action.payload.movedCharterTrip.regionId ?? action.payload.selectedRegionId},
                state.unassignedTripsState,
            )
        },
        setUnassignedTripsFilterInput: (state, action: PayloadAction<string>) => {
            state.unassignedTripsState.filterInput = action.payload;
        },
        setUnassignedTripsSectionMaxHeight: (state, action: PayloadAction<UnassignedTripsSectionHeight>) => {
            state.unassignedTripsSectionHeight = action.payload;
        },
        setDialogData: (state, action: PayloadAction<PlannerDialogData>) => {
            state.dialogData = action.payload;
        },
        clearDialogData: (state) => {
            state.dialogData = {};
        },
        updateWorkGroupTripDefinition: (state, action: PayloadAction<{workGroupTripDefinition: WorkGroupTripDefinition, selectedRegionId: number, isFromOrToUnassigned: boolean}>) => {
            tripDefinitionAdapter.upsertOne(state.tripDefinitions, action.payload.workGroupTripDefinition);
            if (action.payload.isFromOrToUnassigned) {
                state.unassignedTripsState = getUpdatedUnassignedTripsState(
                    action.payload.workGroupTripDefinition,
                    state.unassignedTripsState,
                    action.payload.selectedRegionId,
                )
            }
            action.payload.workGroupTripDefinition.workGroupsOfOppositeType?.forEach(workGroup => {
                if (!state.oppositeGroupMarkerColorMap[workGroup.id]) {
                    state.oppositeGroupMarkerColorMap[workGroup.id] = getRandomColorIndex();
                }
            });
        },
    },
    extraReducers: (builder) => {
        const updateActivities = (state: PlannerState, action: PayloadAction<{groupId: EntityId, activities?: WorkGroupActivity[] | undefined, workItems?: WorkScheduleWorkItem[] | undefined}>) => {
            const selectGroupByIdFromPlanner = plannerItemGroupAdapter.getSelectors<PlannerState>(state => state.plannerItemGroups).selectById;
            const group = selectGroupByIdFromPlanner(state, action.payload.groupId);

            if (group) {
                let changes: { activityIds?: number[], workItemIds?: number[] } | undefined = undefined;
                if (action.payload.activities) {
                    changes = {
                        activityIds: [
                            ...group.activityIds,
                            ...action.payload.activities.map(activity => activity.id)
                                .filter(id => id !== undefined && !group.activityIds.includes(id)) as number[]]
                    };
                    activityAdapter.upsertMany(state.activities, action.payload.activities);
                }
                if (action.payload.workItems) {
                    changes = {
                        workItemIds: [
                            ...group.workItemIds,
                            ...action.payload.workItems.map(item => item.id)
                                .filter(id => !group.workItemIds.includes(id))
                        ]
                    }
                }
                if (changes) plannerItemGroupAdapter.updateOne(state.plannerItemGroups, {id: group.id, changes: changes});
            }
        };

        const addMissingOppositeWorkGroupMarkers = (state: PlannerState, oppositeWorkGroupIds: number[]) => {
            oppositeWorkGroupIds.forEach(workGroupId => {
                if (!state.oppositeGroupMarkerColorMap[workGroupId]) {
                    state.oppositeGroupMarkerColorMap[workGroupId] = getRandomColorIndex();
                }
            });
        };

        const resetOnPending = (state: PlannerState) => {
            state.isLoading = true;
            state.error = undefined;
            plannerItemGroupAdapter.removeAll(state.plannerItemGroups);
            workGroupAdapter.removeAll(state.workGroups);
            tripDefinitionAdapter.removeAll(state.tripDefinitions);
            activityAdapter.removeAll(state.activities);
            state.unassignedTripsState = {
                filterInput: '',
                charterTripRows: [],
                rows: [],
                otherRegionRows: []
            }
        };

        builder.addCase(fetchWorkGroupPlannerData.pending, resetOnPending);
        builder.addCase(fetchWorkGroupPlannerData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = mapErrorToDisplayMessage('töögruppide planeerija', action.error.message);
        });
        builder.addCase(fetchWorkGroupPlannerData.fulfilled, (state, action) => {
            state.plannerType = action.payload.plannerType;
            plannerItemGroupAdapter.setAll(state.plannerItemGroups, action.payload.plannerItemGroups);
            state.oppositeGroupMarkerColorMap = action.payload.oppositeGroupMarkerColorMap;
            workGroupAdapter.setAll(state.workGroups, action.payload.workGroups);
            tripDefinitionAdapter.setAll(state.tripDefinitions, action.payload.tripDefinitions);
            activityAdapter.setAll(state.activities, action.payload.activities);
            state.unassignedTripsState = action.payload.unassignedTripsState;
            state.isLoading = false;
            state.error = undefined;
        });
        builder.addCase(fetchWorkSheetPlannerData.pending, resetOnPending);
        builder.addCase(fetchWorkSheetPlannerData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = mapErrorToDisplayMessage('päeva planeerija', action.error.message);
        });
        builder.addCase(fetchWorkSheetPlannerData.fulfilled, (state, action) => {
            state.plannerType = action.payload.plannerType;
            plannerItemGroupAdapter.setAll(state.plannerItemGroups, action.payload.plannerItemGroups);
            state.oppositeGroupMarkerColorMap = action.payload.oppositeGroupMarkerColorMap;
            tripDefinitionAdapter.setAll(state.tripDefinitions, action.payload.tripDefinitions);
            activityAdapter.setAll(state.activities, action.payload.activities);
            state.unassignedTripsState = action.payload.unassignedTripsState;
            state.isLoading = false;
            state.error = undefined;
        });
        builder.addCase(addActivity.fulfilled, (state, action) => {
            updateActivities(state, action);
            const workGroupOfOppositeTypeIds: number[] = [];
            action.payload.activities?.forEach(activity => {
                activity.workGroupsOfOppositeType?.forEach(workGroupOfOppositeType => {
                    if (!workGroupOfOppositeTypeIds.find(id => id === workGroupOfOppositeType.id)) {
                        workGroupOfOppositeTypeIds.push(workGroupOfOppositeType.id);
                    }
                });
            });
            addMissingOppositeWorkGroupMarkers(state, workGroupOfOppositeTypeIds);
        });
        builder.addCase(editActivity.fulfilled, updateActivities);
        builder.addCase(addPreparationAndFinishingTime.fulfilled, updateActivities);
        builder.addCase(addOppositeWorkGroupToActivity.fulfilled, (state, action) => {
            activityAdapter.updateOne(state.activities, {
                id: action.payload.activityId,
                changes: {
                    workGroupsOfOppositeType: action.payload.workGroupsOfOppositeType
                }
            });
            if (!state.oppositeGroupMarkerColorMap[(action.payload.workGroupId)]) {
                state.oppositeGroupMarkerColorMap[action.payload.workGroupId] = getRandomColorIndex();
            }
        });
        builder.addCase(addOppositeWorkGroupToTrip.fulfilled, (state, action) => {
            tripDefinitionAdapter.updateOne(state.tripDefinitions, {
                id: action.payload.tripId,
                changes: {
                    workGroupsOfOppositeType: action.payload.workGroupsOfOppositeType
                }
            });
            if (!state.oppositeGroupMarkerColorMap[(action.payload.workGroupId)]) {
                state.oppositeGroupMarkerColorMap[action.payload.workGroupId] = getRandomColorIndex();
            }
        });
        builder.addCase(deleteWorkSheetActivity.fulfilled, (state, action) => {
            const selectGroupByIdFromPlanner = plannerItemGroupAdapter.getSelectors<PlannerState>(state => state.plannerItemGroups).selectById;
            const group = selectGroupByIdFromPlanner(state, action.payload.groupId);

            if (group) {
                plannerItemGroupAdapter.updateOne(state.plannerItemGroups, {
                    ...group,
                    changes: {
                        workItemIds: [...group.workItemIds].filter(id => id !== action.payload.workItemId)
                    }
                });
            }
        });
        builder.addCase(removeActivityFromWorkGroup.fulfilled, (state, action) => {
            const selectGroupByIdFromPlanner = plannerItemGroupAdapter.getSelectors<PlannerState>(state => state.plannerItemGroups).selectById;
            const group = selectGroupByIdFromPlanner(state, action.payload.groupId);

            if (group) {
                plannerItemGroupAdapter.updateOne(state.plannerItemGroups, {
                    ...group,
                    changes: {
                        activityIds: [...group.activityIds].filter(id => id !== action.payload.activityId)
                    }
                });
                activityAdapter.removeOne(state.activities, action.payload.activityId);
            }
        });
        builder.addCase(resetUnassignedTripsState.fulfilled, (state, action) => {
            state.unassignedTripsState = action.payload;
        });
        builder.addCase(addWorkSheet.fulfilled, (state, action) => {
            plannerItemGroupAdapter.addOne(state.plannerItemGroups, {
                id: `${action.payload.id}-${undefined}-${action.payload.startDate}`,
                workGroupCode: '',
                type: action.payload.resourceType,
                activityIds: [],
                tripDefinitionIds: [],
                workItemIds: [],
                otherRegionId: null, // assuming work sheet can only be added within selected region
            })
        });
        builder.addCase(copyOppositeWorkGroup.fulfilled, (state, action) => {
            workGroupAdapter.addOne(state.workGroups, action.payload.newWorkGroup);
            workGroupAdapter.updateOne(state.workGroups, {
                id: action.payload.originalWorkGroupId,
                changes: {
                    linkedWorkGroup: {
                        id: action.payload.newWorkGroup.id,
                        code: action.payload.newWorkGroup.code,
                    }
                }
            });
            tripDefinitionAdapter.updateMany(
                state.tripDefinitions,
                action.payload.newWorkGroup.tripDefinitions.map(tripDefinition => ({
                    id: tripDefinition.id,
                    changes: {
                        workGroupsOfOppositeType: [action.payload.newWorkGroup],
                    }
                }))
            );
            activityAdapter.updateMany(
                state.activities,
                action.payload.newWorkGroup.activities.map(activity => ({
                    id: activity.id ?? 0,
                    changes: {
                        workGroupsOfOppositeType: [action.payload.newWorkGroup],
                    }
                }))
            );
            addMissingOppositeWorkGroupMarkers(state, [action.payload.newWorkGroup.id]);
        });
        builder.addCase(splitTrip.fulfilled, (state, action) => {
            const selectGroupByIdFromPlanner = plannerItemGroupAdapter.getSelectors<PlannerState>(state => state.plannerItemGroups).selectById;
            const updatedSegment2 = action.payload.updatedSegments.length > 1 ? action.payload.updatedSegments[1] : undefined;
            const updatedSegment2GroupId = updatedSegment2?.workGroups?.find(wg => wg.type === action.payload.toggledResourceType)?.id ?? 0;
            const updatedSegment2Group = selectGroupByIdFromPlanner(state, updatedSegment2GroupId);

            if (updatedSegment2?.id && updatedSegment2Group && !updatedSegment2Group.tripDefinitionIds.includes(updatedSegment2.id)) {
                plannerItemGroupAdapter.updateOne(state.plannerItemGroups, {
                    ...updatedSegment2Group,
                    changes: {
                        tripDefinitionIds: [...updatedSegment2Group.tripDefinitionIds, updatedSegment2.id]
                    }
                });
            }
            tripDefinitionAdapter.upsertMany(state.tripDefinitions, action.payload.updatedSegments);

            if (action.payload.hasCreatedNewSegment && updatedSegment2?.id && updatedSegment2GroupId === 0) {
                state.unassignedTripsState = getUpdatedUnassignedTripsState(
                    {id: updatedSegment2.id, regionId: updatedSegment2.regionId},
                    state.unassignedTripsState,
                    action.payload.selectedRegionId,
                )
            }
            state.dialogData = {};
        });
    builder.addCase(mergeTrip.fulfilled, (state, action) => {
            const selectGroupByIdFromPlanner = plannerItemGroupAdapter.getSelectors<PlannerState>(state => state.plannerItemGroups).selectById;
            const mergeTripResponse = action.payload.mergeTripResponse;
            if (mergeTripResponse) {
                const removedSegment = mergeTripResponse.removedSegment;
                const removedSegmentGroupId = removedSegment.workGroups?.find(wg => wg.type === mergeTripResponse.toggledResourceType)?.id ?? 0;
                const removedSegmentGroup = selectGroupByIdFromPlanner(state, removedSegmentGroupId);

                if (removedSegment.id && removedSegmentGroup) {
                    plannerItemGroupAdapter.updateOne(state.plannerItemGroups, {
                        ...removedSegmentGroup,
                        changes: {
                            tripDefinitionIds: [...removedSegmentGroup.tripDefinitionIds].filter(segmentId => segmentId !== removedSegment.id)
                        }
                    });
                }

                const originalSegment = mergeTripResponse.originalSegment;
                const updatedSegment = mergeTripResponse.updatedSegment;

                let removedFromWorkGroup;
                if (originalSegment.workGroups !== undefined && updatedSegment.workGroups !== undefined) {
                    removedFromWorkGroup = originalSegment.workGroups
                        .filter(wg => !(updatedSegment.workGroups?.find(uwg => uwg.id === wg.id)))
                        .find(wg => wg.type === mergeTripResponse.toggledResourceType);
                    if (removedFromWorkGroup) {
                        const group = selectGroupByIdFromPlanner(state, removedFromWorkGroup.id);
                        if (group) {
                            plannerItemGroupAdapter.updateOne(state.plannerItemGroups, {
                                ...group,
                                changes: {
                                    tripDefinitionIds: [...group.tripDefinitionIds].filter(segmentId => segmentId !== updatedSegment.id)
                                }
                            });
                        }
                    }
                }

                tripDefinitionAdapter.upsertOne(state.tripDefinitions, updatedSegment);
                tripDefinitionAdapter.removeOne(state.tripDefinitions, removedSegment.id);

                if (removedSegmentGroupId === 0) {
                    state.unassignedTripsState = getUpdatedUnassignedTripsState(
                        {id: removedSegment.id, regionId: removedSegment.regionId},
                        state.unassignedTripsState,
                        mergeTripResponse.selectedRegionId,
                    )
                }
                if (removedFromWorkGroup) {
                    state.unassignedTripsState = getUpdatedUnassignedTripsState(
                        { id: originalSegment.id, regionId: originalSegment.regionId },
                        state.unassignedTripsState,
                        mergeTripResponse.selectedRegionId,
                    )
                }
                state.dialogData = {};
            }
        });
    builder.addCase(linkWorkGroup.fulfilled, (state, action) => {
            workGroupAdapter.updateOne(state.workGroups, {
                id: action.payload.workGroupId,
                changes: {
                    linkedWorkGroup: action.payload.linkedWorkGroup,
                }
            });
        });
    builder.addCase(unlinkWorkGroup.fulfilled, (state, action) => {
            workGroupAdapter.updateOne(state.workGroups, {
                id: action.payload.workGroupId,
                changes: {
                    linkedWorkGroup: undefined,
                }
            });
        });
    }
});

export const {
    setIsLoading,
    setContentWidth,
    addOrReplaceWorkGroup,
    updatePlannerItemGroup,
    updatePlannerItemGroupWorkItemIds,
    updateWorkGroup,
    updateWorkGroupValidDatesAndActivities,
    removeWorkGroup,
    setSelectedOppositeGroupId,
    addUnassignedOtherRegionWorkGroupTripDefinition,
    updateUnassignedTripsStateWithMovedTrip,
    updateUnassignedCharterTripsStateWithMovedTrip,
    setUnassignedTripsFilterInput,
    setUnassignedTripsSectionMaxHeight,
    setDialogData,
    clearDialogData,
    updateWorkGroupTripDefinition,
} = plannerSlice.actions;

export const {
    selectIds: selectPlannerItemGroupIds,
    selectById: selectPlannerItemGroupById,
} = plannerItemGroupAdapter.getSelectors<RootState>(state => state.planner.plannerItemGroups);

export const {
    selectById: selectWorkGroupById,
} = workGroupAdapter.getSelectors<RootState>(state => state.planner.workGroups);

export const {
    selectAll: selectAllTripDefinitions,
    selectById: selectTripDefinitionById,
} = tripDefinitionAdapter.getSelectors<RootState>(state => state.planner.tripDefinitions);

export const {
    selectAll: selectAllActivities,
    selectById: selectActivityById,
} = activityAdapter.getSelectors<RootState>(state => state.planner.activities);

export const selectPlannerTimeline = (state: RootState) => {
    const trips = selectAllTripDefinitions(state);
    const activities = selectAllActivities(state);
    const workItems = selectAllPlannerWorkItems(state);
    const tripDefinitionWorkItems = selectAllPlannerTripDefinitionWorkItems(state);
    const charterTripWorkItems = selectAllPlannerCharterTripWorkItems(state);

    return getPlannerTimeline([
        ...trips,
        ...activities,
        ...workItems,
        ...tripDefinitionWorkItems,
        ...charterTripWorkItems,
    ]);
};

export const selectPlannerTimelineMemoized = createSelector(
    selectPlannerTimeline,
    (result) => result
);

export const selectPlannerTimelineMinWidth = createSelector(
    selectPlannerTimelineMemoized,
    (timeline) => timeline.minWidth
);

export const selectUnassignedTripRows = (state: RootState) => {
    const unassignedTripsState = selectUnassignedTripsState(state);
    const unassignedTripRows = [...unassignedTripsState.otherRegionRows, ...unassignedTripsState.rows];
    if (unassignedTripsState.filterInput === '') return unassignedTripRows;

    const plannerType = selectPlannerType(state);
    const filteredRows: number[][] = [];

    if (plannerType === PlannerType.WORK_GROUP) {
        const trips = selectAllTripDefinitions(state);
        for (const unassignedTripRow of unassignedTripRows) {
            const filteredInnerArray = unassignedTripRow.filter(tripId => {
                const trip = trips?.find(trip => trip.id === tripId);
                return trip
                    ? doesItemCodeOrRouteMatchFilterInput(unassignedTripsState.filterInput, trip.code, trip.route)
                    : false;
            });

            if (filteredInnerArray.length > 0) filteredRows.push(filteredInnerArray);
        }
    }

    if (plannerType === PlannerType.WORK_SHEET) {
        const workItems = selectAllPlannerTripDefinitionWorkItems(state);

        for (const unassignedTripRow of unassignedTripRows) {
            const filteredInnerArray = unassignedTripRow.filter(workItemId => {
                const workItem = workItems?.find(item => item.id === workItemId);
                return workItem
                    ? doesItemCodeOrRouteMatchFilterInput(unassignedTripsState.filterInput, workItem.code, workItem.route)
                    : false;
            });

            if (filteredInnerArray.length > 0) filteredRows.push(filteredInnerArray);
        }
    }

    return filteredRows;
};

export const selectUnassignedTripRowsMemoized = createSelector(
    selectUnassignedTripRows,
    (result) => result
);

export const selectOrderedSegmentsByTripId = createSelector(
    (_: RootState, tripId: EntityId) => tripId,
    selectAllTripDefinitions,
    (tripId, trips): WorkGroupTripDefinition[] => trips
        .filter(trip => trip.tripId === tripId)
        .sort((a, b) =>
            a.route?.length && b.route?.length && a.route[0].order > b.route[0].order ? 1 : -1
        )
);

export const selectPlannerType = (state: RootState) => state.planner.plannerType;
export const selectIsPlannerLoading = (state: RootState) => state.planner.isLoading;
export const selectContentWidth = (state: RootState) => state.planner.contentWidth;
export const selectOppositeGroupMarkerColorMap = (state: RootState) => state.planner.oppositeGroupMarkerColorMap;
export const selectSelectedOppositeGroupId = (state: RootState) => state.planner.selectedOppositeGroupId;
export const selectUnassignedTripsState = (state: RootState) => state.planner.unassignedTripsState;
export const selectUnassignedTripsFilterInput = (state: RootState) => state.planner.unassignedTripsState.filterInput;
export const selectUnassignedTripsSectionHeight = (state: RootState) => state.planner.unassignedTripsSectionHeight;
export const selectDialogData = (state: RootState) => state.planner.dialogData;

export default plannerSlice.reducer;
