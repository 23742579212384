import React, {useEffect} from 'react';
import {useMediaQuery} from '@mui/material';
import {TransportContract} from "../../../../API/types";
import {GridColDef} from '@mui/x-data-grid';
import {areBothOrNeitherActiveChipsSelected, filterByActive, filterByValue} from "../../../../utils/utils";
import theme from "../../../../theme";
import {ActiveFilterChipType, activeFilterChipValues, Permission} from "../../../../types";
import {useNavigate, useOutletContext} from 'react-router-dom';
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../../components/DataGrid/DataGrid";
import routes from "../../../../routes";
import {getDisplayNullableDate} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink/NavLink";
import NavIcon from "../../../../components/Icon/NavIcon";
import StaticIcon from "../../../../components/Icon/StaticIcon";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import {useAuth} from "../../../../contexts/AuthContext";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import ListView from "../../../../layouts/ListViewWrapper";
import {fetchTransportContractsByUser, selectAllTransportContracts} from "../../../../store/transportContractsSlice";
import FilterToolbar from "../../../../components/FilterToolbar/FilterToolbar";
import {useLocalStorage} from "usehooks-ts";
import {FilterChipProps} from "../../../../components/FilterToolbar/components/FilterChip";


export default function TransportContracts() {
    const dispatch = useAppDispatch();
    const { authenticatedUser } = useAuth();
    const navigate = useNavigate();
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const rows = useAppSelector(selectAllTransportContracts);
    const [selectedFilterChips, setSelectedFilterChips] = useLocalStorage<ActiveFilterChipType[]>('transportContractFilterChips', ['Aktiivsed']);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        dispatch(fetchTransportContractsByUser(authenticatedUser))
    }, [authenticatedUser]);

    const handleAddTransportContract = () => navigate(routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ADD);

    const handleSelectedFilterChipsChange = (value: ActiveFilterChipType) => {
        if (selectedFilterChips.includes(value)) {
            setSelectedFilterChips(prevState => prevState.filter(prevValue => prevValue !== value));
        } else {
            setSelectedFilterChips(prevState => [...prevState, value]);
        }
    };

    const getFilteredRows = (rows: TransportContract[]) => filterByActive(filterByValue(rows, searchInput), selectedFilterChips);

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'contractId',
            headerName: 'Lepingu nr',
            width: isScreenSmall ? 120 : 140,
            renderCell: params =>
                <NavLink id={params.row.id} value={params.row.contractId} route={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.EDIT} navPermission={Permission.UpdateTransportContracts} />
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'profitCenter',
            headerName: 'Tulemusüksus',
            width: isScreenSmall ? 110 : 130,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'dateOfSigning',
            headerName: 'Sõlmimise kuupäev',
            width: isScreenSmall ? 120 : 160,
            valueFormatter: params => getDisplayNullableDate(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'partnerName',
            headerName: 'Partneri nimi',
            width: isScreenSmall ? 120 : 160,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'validFrom',
            headerName: 'Kehtivuse algus',
            width: isScreenSmall ? 120 : 140,
            valueFormatter: params => getDisplayNullableDate(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'validTo',
            headerName: 'Kehtivuse lõpp',
            width: isScreenSmall ? 120 : 140,
            valueFormatter: params => getDisplayNullableDate(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'regionName',
            headerName: 'Piirkond',
            width: isScreenSmall ? 90 : 120,
        },
        ...(areBothOrNeitherActiveChipsSelected(selectedFilterChips) ? [{
            ...DEFAULT_COL_DEF_PROPS,
            field: 'active',
            headerName: 'Aktiivne',
            width: isScreenSmall ? 80 : 100,
            renderCell: params => params.value ? <StaticIcon type="CHECK" /> : ''
        } as GridColDef] : []),
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'comment',
            headerName: 'Kommentaar',
            width: isScreenSmall ? 140 : 300,
        },
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            renderCell: params =>
                <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.EDIT} navPermission={Permission.UpdateTransportContracts} />
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Veolepingud',
                buttonProps: {
                    title: 'Lisa veoleping',
                    onClick: handleAddTransportContract,
                    permission: Permission.UpdateTransportContracts
                },
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                filterToolbar={
                    <FilterToolbar
                        inputSearch={{value: searchInput, updateValue: setSearchInput}}
                        filterChips={
                            activeFilterChipValues.map(value => ({
                                selected: selectedFilterChips.includes(value),
                                handleSelectClick: handleSelectedFilterChipsChange,
                                value: value
                            })) as FilterChipProps<ActiveFilterChipType>[]
                        }
                    />
                }
            />
        </ListView>
    );
}
