import React, {useEffect, useMemo, useState} from 'react';
import {useMediaQuery} from '@mui/material';
import {Bus} from "../../../../API/types";
import {GridColDef} from '@mui/x-data-grid';
import {
    areBothOrNeitherActiveChipsSelected,
    filterByActive,
    filterByRegionsForUser,
    filterByValue,
    getRegionsDisplayStr,
} from "../../../../utils/utils";
import theme from "../../../../theme";
import {getFuelTranslationsDisplayStr} from '../../../../utils/enumTranslations';
import {ActiveFilterChipType, activeFilterChipValues, Permission} from "../../../../types";
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../../components/DataGrid/DataGrid";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import routes from "../../../../routes";
import {useNavigate, useOutletContext} from "react-router-dom";
import {loadBuses} from "../../../../API";
import {setToast} from "../../../../store/toastSlice";
import {selectAllRegions} from "../../../../store/regionSlice";
import {getDisplayNullableDate} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink/NavLink";
import NavIcon from "../../../../components/Icon/NavIcon";
import StaticIcon from "../../../../components/Icon/StaticIcon";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import {useAuth} from "../../../../contexts/AuthContext";
import ListView from "../../../../layouts/ListViewWrapper";
import {useLocalStorage} from "usehooks-ts";
import FilterToolbar from "../../../../components/FilterToolbar/FilterToolbar";
import {FilterChipProps} from "../../../../components/FilterToolbar/components/FilterChip";


interface DisplayBus extends Bus {
    regions: string;
    fuels: string;
}

export default function Buses() {
    const navigate = useNavigate();
    const { authenticatedUser } = useAuth();
    const regions = useAppSelector(selectAllRegions);
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [buses, setBuses] = useState<Bus[] | undefined>(undefined);
    const [selectedFilterChips, setSelectedFilterChips] = useLocalStorage<ActiveFilterChipType[]>('busFilterChips', ['Aktiivsed']);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (authenticatedUser) {
            loadBuses()
                .then(result => {
                    setBuses(filterByRegionsForUser(result, authenticatedUser));
                })
                .catch(() => dispatch(setToast({type: 'error', text: 'Busside pärimisel tekkis viga'})));
        }
    }, [authenticatedUser]);

    const rows: DisplayBus[] | undefined = useMemo(() => buses?.map(bus => ({
        ...bus,
        fuels: getFuelTranslationsDisplayStr(bus.fuelTypes),
        regions: getRegionsDisplayStr(bus.regionIds, regions)
    })), [buses, regions]);

    const handleAddBus = () => navigate(routes.AUTHENTICATED.BUSES.ADD);

    const handleSelectedFilterChipsChange = (value: ActiveFilterChipType) => {
        if (selectedFilterChips.includes(value)) {
            setSelectedFilterChips(prevState => prevState.filter(prevValue => prevValue !== value));
        } else {
            setSelectedFilterChips(prevState => [...prevState, value]);
        }
    };

    const getFilteredRows = (rows: Bus[]) => filterByActive(filterByValue(rows, searchInput), selectedFilterChips);

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'licencePlateNumber',
            headerName: 'Reg-nr',
            width: isScreenSmall ? 80 : 100,
            renderCell: params =>
                <NavLink id={params.row.id} value={params.row.licencePlateNumber} route={routes.AUTHENTICATED.BUSES.EDIT} navPermission={Permission.UpdateBuses} />
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'make',
            headerName: 'Mark',
            width: isScreenSmall ? 80 : 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'model',
            headerName: 'Mudel',
            width: isScreenSmall ? 80 : 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'type',
            headerName: 'Masinmark',
            width: isScreenSmall ? 80 : 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'registrationDate',
            headerName: 'Esmaregistreerimine',
            width: isScreenSmall ? 120 : 180,
            minWidth: 100,
            valueFormatter: params => getDisplayNullableDate(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'seatingCapacity',
            headerName: 'Istekohad',
            width: isScreenSmall ? 60 : 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'standingCapacity',
            headerName: 'Seisukohad',
            width: isScreenSmall ? 70 : 120,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'fuels',
            headerName: 'Kütuseliik',
            width: isScreenSmall ? 120 : 200,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'regions',
            headerName: 'Piirkonnad',
            width: isScreenSmall ? 150 : 200,
            minWidth: 100
        },
        ...(areBothOrNeitherActiveChipsSelected(selectedFilterChips) ? [{
            ...DEFAULT_COL_DEF_PROPS,
            field: 'active',
            headerName: 'Aktiivne',
            width: isScreenSmall ? 80 : 100,
            renderCell: params => params.value ? <StaticIcon type="CHECK" /> : ''
        } as GridColDef] : []),
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            renderCell: params =>
                <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.BUSES.EDIT} navPermission={Permission.UpdateBuses} />
        }
    ];

    return (
        <ListView
            headerProps={{
                title: "Bussid",
                buttonProps: {
                    title: 'Lisa buss',
                    onClick: handleAddBus,
                    permission: Permission.UpdateBuses
                },
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                filterToolbar={
                    <FilterToolbar
                        inputSearch={{value: searchInput, updateValue: setSearchInput}}
                        filterChips={
                            activeFilterChipValues.map(value => ({
                                selected: selectedFilterChips.includes(value),
                                handleSelectClick: handleSelectedFilterChipsChange,
                                value: value
                            })) as FilterChipProps<ActiveFilterChipType>[]
                        }
                    />
                }
            />
        </ListView>
    );
}
