import React from 'react';
import {Box, Typography} from '@mui/material';
import theme from '../../theme';
import Button from "../Button/Button";
import {Add} from '@mui/icons-material';
import {Permission} from "../../types";
import Visible from "../Visible/Visible";
import ToggleButton from "../Button/ToggleButton";
import PageHeaderRegionSelect from "./components/RegionSelect";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";


export const PAGE_HEADER_ITEM_HEIGHT = '36.5px';

export interface PageHeaderButtonProps {
    title: string;
    onClick: () => void;
    permission: Permission;
    startIcon?: ReactJSXElement;
}

interface ToggleProps {
    options: string[];
    value: string;
    handleChange: (value: string) => void;
}

export interface PageHeaderProps {
    title: string;
    buttonProps?: PageHeaderButtonProps;
    secondaryButton?: JSX.Element;
    toggleProps?: ToggleProps;
    showRegionSelect?: boolean;
}

export default function PageHeader({title, buttonProps, secondaryButton, toggleProps, showRegionSelect}: PageHeaderProps) {
    const shouldWrapOnMd = [toggleProps, secondaryButton, buttonProps].filter(Boolean).length > 1;
    const hasManyToggleOptions = toggleProps?.options && toggleProps.options.length > 3;

    return (
        <Box sx={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 0.5,
            mb: 1.5,
            gap: 1,
            [theme.breakpoints.down('md')]:
                shouldWrapOnMd || hasManyToggleOptions ? {flexDirection: 'column', alignItems: 'flex-start'} : {},
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'flex-start'
            },
            displayPrint: 'none',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
                [theme.breakpoints.down('sm')]: {width: '100%', flexDirection: 'column'},
                [theme.breakpoints.down('md')]: hasManyToggleOptions ? {width: '100%', flexDirection: 'column'} : {}
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        justifyContent: toggleProps ? 'space-between' : 'flex-start'
                    },
                    [theme.breakpoints.down('md')]: hasManyToggleOptions ? {
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: toggleProps ? 'flex-start' : 'space-between'
                    } : {}
                }}>
                    <Typography component="h1" variant="h5">{title}</Typography>
                    {toggleProps &&
                        <ToggleButton options={toggleProps.options} value={toggleProps.value} handleChange={toggleProps.handleChange} />
                    }
                </Box>
                {secondaryButton}
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                gap: 1,
                [theme.breakpoints.down('md')]:
                    shouldWrapOnMd || hasManyToggleOptions ? {width: '100%'} : {},
                [theme.breakpoints.down('sm')]: {
                    width: '100%', flexDirection: 'column-reverse', justifyContent: 'space-between', mt: showRegionSelect ? 0.5 : 0
                },
            }}>
                {buttonProps &&
                    <Visible permission={buttonProps.permission}>
                        <Box sx={{width: {xs: '100%', sm: 'fit-content'}}}>
                            <Button
                                text={buttonProps.title}
                                onClick={buttonProps.onClick}
                                startIcon={buttonProps.startIcon ?? <Add />}
                            />
                        </Box>
                    </Visible>
                }
               {showRegionSelect &&
                    <PageHeaderRegionSelect styles={{
                        [theme.breakpoints.down('md')]: hasManyToggleOptions || shouldWrapOnMd ? {width: '100%'} : {}
                    }} />
                }
            </Box>
        </Box>
    );
}
