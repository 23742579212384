import React from 'react';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {Moving} from "@mui/icons-material";
import Button from "./Button";
import {useNavigate} from "react-router";


interface NavigationButtonProps {
    text: string | ReactJSXElement;
    route: string;
}

export default function NavigationButton({text, route}: NavigationButtonProps) {
    const navigate = useNavigate();

    return (
        <Button
            text={text}
            startIcon={<Moving />}
            color="secondary"
            onClick={() => navigate(route)}
            styles={{color: 'secondary.main', borderColor: 'secondary.main'}}
        />
    );
}
