import NavLink from "../../../../components/NavLink/NavLink";
import {Permission} from "../../../../types";
import React from "react";

export interface FormattedNavLinkProps {
    id: string | number;
    value?: string;
    route: string;
    permission: Permission;
}

export const FormattedNavLink = ({id, value, route, permission}: FormattedNavLinkProps) => {
    return <NavLink
        id={`${id}`}
        value={value ?? 'määramata'}
        route={route}
        navPermission={permission}
        openInNewWindow={true}
        sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontStyle: value ? undefined : 'italic',
            opacity: value ? undefined : 0.5
        }}
    />;
}