import React, {useEffect, useState} from 'react';
import {getAvlAssignments, reportAvlAssignments} from "../../../API";
import {GridColDef, GridRenderCellParams, GridValueFormatterParams} from "@mui/x-data-grid";
import DataGrid, {DEFAULT_COL_DEF_PROPS} from "../../../components/DataGrid/DataGrid";
import {AvlAssignmentRow} from "./types";
import {getAvlSyncStatusTranslation} from "../../../utils/enumTranslations";
import {
    getDisplayDate,
    getDisplayNullableDateTime,
    getDisplayTimeRangeFromDateTimeStr,
    getDisplayTimeWithModifier
} from "../../../utils/dateUtils";
import {useOutletContext} from "react-router-dom";
import {SearchContextType} from "../../../layouts/SearchLayoutWrapper";
import {filterByValue, isToday} from "../../../utils/utils";
import {useLocalStorage} from "usehooks-ts";
import {AvlAssigmentTripSegment, AvlSyncStatus} from "../../../API/avlAssignments/types";
import {FilterChipProps} from "../../../components/FilterToolbar/components/FilterChip";
import dayjs, {Dayjs} from "dayjs";
import FilterToolbar from "./FilterToolbar";
import {Box, Tooltip, Typography} from "@mui/material";
import Button from "../../../components/Button/Button";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectIsSelectedDayToday} from "../workSheets/store/selectors";
import {SendRounded} from "@mui/icons-material";
import {useDayNavigation} from "../../../store/viewSlice";
import {setToast} from "../../../store/toastSlice";
import {mapErrors} from "../../../utils/errorMapping";
import NavLink from "../../../components/NavLink/NavLink";
import routes from "../../../routes";
import {Permission} from "../../../types";


const REPORTING_WINDOW_BUFFER_HOURS = 2;

export default function AvlAssignments() {
    const dispatch = useAppDispatch();
    const [date] = useDayNavigation();
    const isSelectedDayToday = useAppSelector(selectIsSelectedDayToday);
    const [rows, setRows] = useState<AvlAssignmentRow[]>([]);
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [selectedStatuses, setSelectedStatuses] = useLocalStorage<AvlSyncStatus[]>('avlAssignments.selectedStatuses', [AvlSyncStatus.ERROR]);
    const [extraInfoEnabled, setExtraInfoEnabled] = useLocalStorage<boolean>('avlAssignments.extraInfoEnabled', false);
    const [refreshTime, setRefreshTime] = useState<Dayjs | null>(null);

    useEffect(() => {
        fetchAssignments();
    }, [date]);

    const handleRefreshed = () => fetchAssignments()

    const handleSelectedFilterChipsChange = (value: AvlSyncStatus) => {
        if (selectedStatuses.includes(value)) {
            setSelectedStatuses(prevState => prevState.filter(prevValue => prevValue !== value));
        } else {
            setSelectedStatuses(prevState => [...prevState, value]);
        }
    };

    const filterByStatus = (rows: AvlAssignmentRow[]): AvlAssignmentRow[] => {
        if (selectedStatuses.length === 0) return rows;

        return rows.filter(row => selectedStatuses.includes(row.status));
    };

    const fetchAssignments = () => {
        getAvlAssignments(date)
            .then((data) => {
                const rows: AvlAssignmentRow[] = data.map(assignment => {
                    return {
                        ...assignment,
                        id: `${assignment.tripId}-${assignment.date}`,
                        statusTranslation: getAvlSyncStatusTranslation(assignment.status),
                    };
                });
                setRows(rows);
                setRefreshTime(dayjs())
            })
            .catch(apiError => {
                dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Töökäskude pärimisel ilmnes viga'}));
            });
    };

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'tripCode',
            headerName: 'Reisi kood',
            width: 120,
            renderCell: (params) =>
                <NavLink id={params.row.tripId} value={params.value} route={routes.AUTHENTICATED.TRIPS.DETAILS} navPermission={Permission.UpdateTrips} openInNewWindow />
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'tripLineNumber',
            headerName: 'Liini nr',
            width: 120,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'tripStartTime',
            headerName: 'Väljumise aeg',
            width: 120,
            valueFormatter: (params: GridValueFormatterParams) => getDisplayTimeWithModifier(params.value, !dayjs(params.value).isSame(date, 'date')),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'tripDepartureStopName',
            headerName: 'Algpeatus',
            width: 120,
        },
        ...(extraInfoEnabled ? [
            {
                ...DEFAULT_COL_DEF_PROPS,
                field: 'tripSegments',
                headerName: 'Buss / Juht',
                width: 300,
                sortable: false,
                renderCell: (params: GridRenderCellParams) =>
                    <Box sx={{mb: 1, width: '100%', overflow: 'hidden'}}>
                        {(params.value ?? [])
                            .toSorted((a: AvlAssigmentTripSegment, b: AvlAssigmentTripSegment) =>
                                a.startDateTime.localeCompare(b.startDateTime))
                            .map((row: AvlAssigmentTripSegment) => {
                            const displayTime = getDisplayTimeRangeFromDateTimeStr(row.startDateTime, row.endDateTime, date);
                            return <Box key={displayTime} sx={{display: 'flex', width: '100%', overflow: 'hidden'}}>
                                <Box sx={{minWidth: 130, whiteSpace: 'nowrap', flexShrink: 0}}>{displayTime}</Box>
                                <Box sx={{display: 'flex', gap: 1, overflow: 'hidden'}}>
                                    <Box>{row.busWorkSheet
                                        ? <NavLink id={row.busWorkSheet.id.toString()}
                                                   value={row.busWorkSheet.licensePlateNumber ?? 'määramata'}
                                                   route={routes.AUTHENTICATED.WORK_SHEETS.DETAILS}
                                                   navPermission={Permission.ReadWorkSchedules}
                                                   openInNewWindow />
                                        : 'määramata'}</Box>
                                    <Box sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{row.driverWorkSheet
                                        ? <NavLink id={row.driverWorkSheet.id.toString()}
                                                   value={row.driverWorkSheet.firstName ? `${row.driverWorkSheet.firstName} ${row.driverWorkSheet.lastName}` : 'määramata'}
                                                   route={routes.AUTHENTICATED.WORK_SHEETS.DETAILS}
                                                   navPermission={Permission.ReadWorkSchedules}
                                                   openInNewWindow />
                                        : 'määramata'}</Box>
                                </Box>
                            </Box>
                        })}
                    </Box>
            },
        ] : []),
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'date',
            headerName: 'Kuupäev',
            width: 120,
            valueFormatter: (params: GridValueFormatterParams) => getDisplayDate(params.value),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'statusTranslation',
            headerName: 'Staatus',
            width: 120,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'lastSyncTime',
            headerName: 'Viimati saadetud',
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) => getDisplayNullableDateTime(params.value),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'lastMessage',
            headerName: 'Viimane tulemus',
            minWidth: 300,
            flex: 1,
        }
    ];

    const handleResend = () => {
        const dateTime = dayjs();
        const adjustedDateTimeStr = (dateTime.hour() < REPORTING_WINDOW_BUFFER_HOURS)
            ? '00:00' : dateTime.subtract(REPORTING_WINDOW_BUFFER_HOURS, 'h').format('HH:mm');

        if (!isToday(date)) {
            dispatch(setToast({
                type: 'error',
                text: 'Uuesti saab saata ainult tänase päeva töökäske',
            }));
        } else {
            if (confirm(`Uuesti saadetakse kõik ${getDisplayDate(dateTime)} sõidulehtede reisid, mis on käigus peale kella ${adjustedDateTimeStr}. Kas oled kindel, et soovid jätkata?`)) {
                reportAvlAssignments()
                    .then(() => {
                        dispatch(setToast({type: 'success', text: 'Töökäsud edukalt uuesti saadetud. Töökäskude töötlemine võtab veidi aega. Tulemuste nägemiseks tuleb vaadet värskendada'}));
                        fetchAssignments();
                    })
                    .catch(apiError => {
                        dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Uuesti saatmisel ilmnes viga'}));
                    });
            }
        }
    };

    return (
        <Box sx={{height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
            <Box sx={{
                display: 'flex',
                flexDirection: {xs: 'column', sm: 'row'},
                justifyContent: {xs: 'start', sm: 'space-between'},
                alignItems: {xs: 'start', sm: 'end'},
                pb: 1.5,
                gap: 0.5
            }}>
                <Typography component="h1" variant="h5" mr={{xs: 1, sm: 2}}>Töökäsud</Typography>
                <Tooltip title={isSelectedDayToday ? undefined : 'Uuesti saab saata ainult tänase päeva töökäske'} arrow placement="top">
                    <Box sx={{width: {xs: '100%', sm: 'fit-content'}}}>
                        <Button
                            text="Saada päev uuesti"
                            disabled={!isSelectedDayToday}
                            color="secondary"
                            startIcon={<SendRounded />}
                            onClick={handleResend}
                        />
                    </Box>
                </Tooltip>
            </Box>
            <DataGrid
                sorting={{
                    sortModel: [{ field: 'lastSyncTime', sort: 'desc' }],
                }}
                rows={filterByValue(filterByStatus(rows), searchInput)}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                enableMultiRowCells={extraInfoEnabled}
                filterToolbar={
                    <FilterToolbar
                        inputSearch={{value: searchInput, updateValue: setSearchInput}}
                        refreshTime={refreshTime}
                        handleRefreshed={handleRefreshed}
                        filterChips={[
                            Object.values(AvlSyncStatus).map((value): FilterChipProps<AvlSyncStatus> => ({
                                selected: selectedStatuses.includes(value),
                                handleSelectClick: handleSelectedFilterChipsChange,
                                text: getAvlSyncStatusTranslation(value),
                                value: value
                            })),
                            [{
                                selected: extraInfoEnabled,
                                handleSelectClick: () => setExtraInfoEnabled(prevState => !prevState),
                                text: 'Kuva bussid/juhid',
                                value: extraInfoEnabled,
                            }],
                        ]}
                    />
                }
            />
        </Box>
    );
}
