import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {
    CopyTripDefinitionRequest,
    CopyTripDefinitionResponse,
    SaveTripDefinitionRequest,
    ScheduleSetting,
    TransportContract,
    TripDefinition, TripRoutePointRequest, WorkGroup
} from '../../../../API/types';
import {TripDefinitionForm} from './types';
import {useParams} from 'react-router-dom';
import Loader from "../../../../components/Loader/Loader";
import {ToastContext} from "../../../../contexts/ToastContext";
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {useLocation, useNavigate} from "react-router";
import * as Yup from "yup";
import {dateValidTo, routePointNameRequired, validationSchema} from "../../../../utils/formValidation";
import routes from '../../../../routes';
import TripDefinitionEditForm from "./components/TripDefinitionEditForm";
import {Permission, SelectOptionWithId} from "../../../../types";
import {copyTripDefinition, createTripDefinition, loadTripDefinition, loadTransportContracts, updateTripDefinition} from "../../../../API";
import {correspondsWith, decimalToDisplayStr, strToDecimal} from "../../../../utils/utils";
import {mapErrors} from "../../../../utils/errorMapping";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {ensureGeoPoints, fetchGeoPoints, selectAllGeoPoints} from "../../../../store/geoPointSlice";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import TripDefinitionWorkGroups from "./components/TripDefinitionWorkGroups";
import dayjs, {Dayjs} from "dayjs";
import ConfirmDialog from "./components/ConfirmDialog";
import {getDateString, getDisplayNullableDate, getHhMmFromDate} from "../../../../utils/dateUtils";
import TextButton from "../../../../components/Button/TextButton";
import {Delete} from "@mui/icons-material";
import {deleteTrip} from "../../../../API/trips/api";
import {setToast} from "../../../../store/toastSlice";
import {useAuth} from "../../../../contexts/AuthContext";


interface StateData {
    copiedTripDefinition?: TripDefinition;
    previousTripDefinitionVersion?: TripDefinition;
}

const getTripDefinitionValidationSchema = (isValidFromRequired: boolean) => Yup.object().shape({
    code: validationSchema('Reisi kood').fields.shortTextFieldRequired,
    contractId: validationSchema('Lepingu number').fields.numberRequired,
    startTime: validationSchema('Algusaeg').fields.timeRequired,
    endTime: validationSchema('Lõpuaeg').fields.timeRequired,
    route: routePointNameRequired('Teekonna punkti nimi'),
    distance: validationSchema('Reisi pikkus').fields.numberTextField,
    validFrom: isValidFromRequired
        ? validationSchema('Kehtivuse alguskuupäev').fields.dateRequired
        : validationSchema('Kehtivuse alguskuupäev').fields.date,
    validTo: dateValidTo('Kehtivuse lõppkuupäev', 'validFrom'),
    startDate: validationSchema('Sesonaalse kehtivuse alguskuupäev').fields.date,
    endDate: validationSchema('Sesonaalse kehtivuse lõpukuupäev').fields.date,
    comment: validationSchema('Kommentaar').fields.longTextField,
});

const defaultFormValues: TripDefinitionForm = {
    code: '',
    contractId: null,
    lineNumber: '',
    startTime: null,
    startTimeIsOnNextDay: false,
    endTime: null,
    endTimeIsOnNextDay: false,
    route: [],
    distance: '',
    validFrom: null,
    validTo: null,
    startDate: null,
    endDate: null,
    repetition: {
        mon: false, tue: false, wed: false, thu: false, fri: false, sat: false, sun: false,
        scheduleSettings: {
            SCHOOL_HOLIDAY: ScheduleSetting.NoEffect,
            PUBLIC_HOLIDAY: ScheduleSetting.NoEffect,
            SUMMER_HOLIDAY: ScheduleSetting.NoEffect,
        },
    },
    comment: '',
    active: true
};

const getFormType = (id?: string, stateData?: StateData | null) => {
    if (stateData?.previousTripDefinitionVersion) {
        return 'newVersion';
    } else if (stateData?.copiedTripDefinition) {
        return 'copy';
    } else if (id) {
        return 'edit';
    } else {
        return 'add';
    }
};

export default function TripDetails() {
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const { addToast } = useContext(ToastContext);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state as StateData;
    const [tripDefinition, setTripDefinition] = useState<TripDefinition | undefined>(undefined);
    const [prepopulatedFormValues, setPrepopulatedFormValues] = useState<TripDefinitionForm | undefined>(undefined);
    const [tripDefinitionRegion, setTripDefinitionRegion] = useState<string | undefined>(undefined);
    const [initialValues, setInitialValues] = useState<TripDefinitionForm | undefined>(undefined);
    const [transportContracts, setTransportContracts] = useState<TransportContract[] | undefined>(undefined);
    const [filteredTransportContractOptions, setFilteredTransportContractOptions] = useState<SelectOptionWithId[] | undefined>(undefined);
    const [workGroups, setWorkGroups] = useState<WorkGroup[] | undefined>(undefined);
    const geoPoints = useAppSelector(selectAllGeoPoints);
    const formType: 'add' | 'edit' | 'copy' | 'newVersion' = getFormType(id, stateData);
    const [plannedChanges, setPlannedChanges] = useState<CopyTripDefinitionResponse | undefined>(undefined);
    const [copyTripDefinitionRequest, setCopyTripDefinitionRequest] = useState<CopyTripDefinitionRequest | undefined>(undefined);
    const dispatch = useAppDispatch();
    const { hasPermission } = useAuth();

    useEffect(() => {
        dispatch(ensureGeoPoints());
        loadTransportContracts()
            .then(result => setTransportContracts(result))
            .catch(error => {
                addToast({
                    type: 'error',
                    text: mapErrors(error) ?? 'Veolepingute pärimisel ilmnes viga',
                });
            });
    }, []);

    useEffect(() => {
        if (id === undefined) {
            if (!prepopulatedFormValues) {
                if (formType === 'copy' && stateData?.copiedTripDefinition) {
                    setPrepopulatedFormValues(getFormValuesFromTripDefinition(stateData.copiedTripDefinition));
                } else if (formType === 'newVersion' && stateData?.previousTripDefinitionVersion) {
                    setPrepopulatedFormValues(getFormValuesFromTripDefinition(stateData.previousTripDefinitionVersion));
                    loadTripDefinition(stateData.previousTripDefinitionVersion.id)
                        .then(result => setWorkGroups(result.workGroups))
                        .catch(() => {
                            addToast({type: 'error', text: 'Reisi pärimisel ilmnes viga'});
                            navigate(routes.AUTHENTICATED.TRIPS.ROOT);
                        });
                }
            }
            setInitialValues(defaultFormValues);
        } else {
            loadTripDefinition(id)
                .then(result => {
                    setTripDefinition(result);
                    setWorkGroups(result.workGroups);
                })
                .catch(() => {
                    addToast({type: 'error', text: 'Reisi pärimisel ilmnes viga'});
                    navigate(routes.AUTHENTICATED.TRIPS.ROOT);
                });
        }
    }, [id]);

    useEffect(() => {
        if (tripDefinition) {
            if (transportContracts) {
                setTripDefinitionRegion(transportContracts.find(contract => contract.id === tripDefinition.contractId)?.regionName ?? undefined);
            }
            setInitialValues(getFormValuesFromTripDefinition(tripDefinition));
        }
    }, [tripDefinition]);

    useEffect(() => {
        updateFilteredTransportContractOptions();
    }, [transportContracts]);

    const updateFilteredTransportContractOptions = () => {
        if (transportContracts){
            const tripTransportContract = transportContracts.find(contract => contract.id === tripDefinition?.contractId);

            const transportContractsWithinRegion = (tripTransportContract)
                ? transportContracts.filter((contract) => contract.regionName === tripTransportContract.regionName)
                : (!id && selectedRegion)
                    ? transportContracts.filter((contract) => contract.regionName === selectedRegion.name)
                    : transportContracts;
            setFilteredTransportContractOptions(
                transportContractsWithinRegion
                    .map(contract => {
                        const validTo = getDisplayNullableDate(contract.validTo);

                        return {
                            id: contract.id,
                            name: contract.contractId,
                            description: `Partner: ${contract.partnerName}` + (validTo !== '' ? `, kehtib kuni: ${validTo}` : '')
                        };
                    })
                    .sort((a, b) => a.name < b.name ? -1 : 1)
            );
        }
    };

    const getFormValuesFromTripDefinition = (tripDefinitionData: TripDefinition): TripDefinitionForm => {
        return {
            code: tripDefinitionData.code,
            contractId: tripDefinitionData.contractId,
            lineNumber: tripDefinitionData.lineNumber ?? '',
            startTime: tripDefinitionData.startTime ? getDayjsFromTime(tripDefinitionData.startTime) : null,
            startTimeIsOnNextDay: tripDefinitionData.startTimeIsOnNextDay,
            endTime: tripDefinitionData.endTime ? getDayjsFromTime(tripDefinitionData.endTime) : null,
            endTimeIsOnNextDay: tripDefinitionData.endTimeIsOnNextDay,
            route: tripDefinitionData.route?.map(point => ({
                geoPoint: point,
                stopName: point.stopName ?? '',
                requestStop: point.requestStop,
                time: point.time ? getDayjsFromTime(point.time) : null,
                timeIsOnNextDay: point.timeIsOnNextDay ?? false,
            })) ?? [],
            distance: decimalToDisplayStr(tripDefinitionData.distance) ?? '',
            validFrom: tripDefinitionData.validFrom ? new Date(tripDefinitionData.validFrom): null,
            validTo: tripDefinitionData.validTo ? new Date(tripDefinitionData.validTo) : null,
            startDate: tripDefinitionData.startDate ? new Date(tripDefinitionData.startDate) : null,
            endDate: tripDefinitionData.endDate ? new Date(tripDefinitionData.endDate) : null,
            repetition:  {
                ...tripDefinitionData.repetition,
                scheduleSettings: {
                    ...defaultFormValues.repetition.scheduleSettings,
                    ...tripDefinitionData.repetition.scheduleSettings,
                },
            },
            comment: tripDefinitionData.comment ?? '',
            active: tripDefinitionData.active,
        }
    };

    const getDayjsFromTime = (time: string): Dayjs => dayjs(time, 'HH:mm:ss');

    const confirmNewTripDefinitionVersion = useCallback(() => {
        if (formType === 'newVersion' && stateData?.previousTripDefinitionVersion && copyTripDefinitionRequest) {
            setPlannedChanges(undefined);
            copyTripDefinition(stateData.previousTripDefinitionVersion.id, {
                ...copyTripDefinitionRequest,
                confirmed: true,
            }).then(result => {
                addToast({type: 'success', text: 'Reisi uus versioon edukalt loodud'});
                navigate(routes.AUTHENTICATED.TRIPS.DETAILS.replace(':id', result.newTripDefinition.tripId.toString()));
            }).catch(error => {
                addToast({type: 'error', text: mapErrors(error) ?? 'Reisi uue versiooni loomisel esines tõrge'});
            })
        }
    }, [stateData, copyTripDefinitionRequest]);

    const onSubmit = async (form: TripDefinitionForm, formHelpers: FormikHelpers<TripDefinitionForm>) => {
        if (filteredTransportContractOptions && geoPoints && !!form.startTime && !!form.endTime && !!form.contractId) {
            const route: TripRoutePointRequest[] = form.route.length > 0
                ? form.route.map((point) => {
                    const existingPoint = geoPoints.find(existingPoint => correspondsWith(point, existingPoint));
                    if (existingPoint) {
                        return {...point, ...existingPoint, time: point.time ? getHhMmFromDate(point.time) : undefined};
                    } else {
                        return {...point, name: point.geoPoint?.name ?? '', time: point.time ? getHhMmFromDate(point.time) : undefined};
                    }
                })
                : [];

            if (formType === 'newVersion' && form.validFrom && stateData?.previousTripDefinitionVersion) {
                const request = {
                    lineNumber: form.lineNumber ?? null,
                    validFrom: dayjs(form.validFrom).format('YYYY-MM-DD'),
                    validTo: form.validTo ? dayjs(form.validTo).format('YYYY-MM-DD') : null,
                    startTime: getHhMmFromDate(form.startTime),
                    startTimeIsOnNextDay: form.startTimeIsOnNextDay,
                    endTime: getHhMmFromDate(form.endTime),
                    endTimeIsOnNextDay: form.endTimeIsOnNextDay,
                    distance: form.distance ? strToDecimal(form.distance) : null,
                    route: route,
                    confirmed: false,
                    comment: form.comment ?? null,
                };
                copyTripDefinition(stateData.previousTripDefinitionVersion.id, request).then(result => {
                    setPlannedChanges(result);
                    setCopyTripDefinitionRequest(request);
                })
                .catch(error => {
                    addToast({type: 'error', text: mapErrors(error) ?? 'Reisi uue versiooni loomisel esines tõrge'});
                })
            } else {
                const request: SaveTripDefinitionRequest = {
                    id: id ? parseInt(id) : 0,
                    code: form.code,
                    contractId: form.contractId,
                    lineNumber: form.lineNumber.length > 0 ? form.lineNumber : null,
                    startTime: getHhMmFromDate(form.startTime),
                    startTimeIsOnNextDay: form.startTimeIsOnNextDay,
                    endTime: getHhMmFromDate(form.endTime),
                    endTimeIsOnNextDay: form.endTimeIsOnNextDay,
                    route: route,
                    distance: form.distance ? strToDecimal(form.distance) : null,
                    validFrom: form.validFrom ? getDateString(form.validFrom) : null,
                    validTo: form.validTo ? getDateString(form.validTo) : null,
                    startDate: form.startDate ? getDateString(form.startDate) : null,
                    endDate: form.endDate ? getDateString(form.endDate) : null,
                    repetition: form.repetition,
                    comment: form.comment.length > 0 ? form.comment : null,
                    active: form.active,
                };
                if (id) {
                    updateTripDefinition(request)
                        .then(result => {
                            addToast({type: 'success', text: 'Reis edukalt uuendatud'});
                            setTripDefinition({
                                ...result,
                                workGroups: tripDefinition?.workGroups ? tripDefinition.workGroups : [],
                                hasWorkItem: tripDefinition?.hasWorkItem,
                            });
                        })
                        .catch(error => {
                            addToast({type: 'error', text: mapErrors(error) ?? 'Reisi uuendamisel esines tõrge'});
                        })
                        .finally(() => {
                            formHelpers.setSubmitting(false);
                            dispatch(fetchGeoPoints());
                        });
                } else {
                    createTripDefinition(request)
                        .then(() => {
                            addToast({type: 'success', text: 'Reis edukalt loodud'});
                            navigate(routes.AUTHENTICATED.TRIPS.ROOT);
                        })
                        .catch(error => {
                            addToast({type: 'error', text: mapErrors(error) ?? 'Reisi loomisel esines tõrge'});
                        })
                        .finally(() => {
                            formHelpers.setSubmitting(false);
                            dispatch(fetchGeoPoints());
                        });
                }
            }
        }
    };

    const handleDeleteClick = () => {
        if (id && confirm("Kas oled kindel, et soovid reisi kustutada?")) {
            deleteTrip(id)
                .then(() => {
                    dispatch(setToast({type: 'success', text: 'Reis edukalt kustutatud'}));
                    navigate(routes.AUTHENTICATED.TRIPS.ROOT);
                })
                .catch((apiError) => {
                    dispatch(setToast({type: 'error', text: apiError.message ?? 'Reisi kustutamisel ilmnes viga'}));
                });
        }
    };

    return (filteredTransportContractOptions && geoPoints && initialValues ?
        <Box p={{xs: 1, sm: 0}}>
            {plannedChanges &&
                <ConfirmDialog
                    plannedChanges={plannedChanges}
                    onCancel={() => {
                        setPlannedChanges(undefined);
                        setCopyTripDefinitionRequest(undefined);
                    }}
                    onConfirm={confirmNewTripDefinitionVersion}
                />
            }
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={getTripDefinitionValidationSchema(formType === 'newVersion')}
                    onSubmit={onSubmit}
                >
                    {(formikProps: FormikProps<TripDefinitionForm>) => {
                        useEffect(() => {
                            if (prepopulatedFormValues) {
                                formikProps.setValues(() => prepopulatedFormValues).then();
                            }
                        }, [prepopulatedFormValues]);

                        return (
                            <TripDefinitionEditForm
                                formikProps={formikProps}
                                tripDefinition={tripDefinition}
                                tripDefinitionRegion={tripDefinitionRegion}
                                id={id}
                                defaultFormValues={defaultFormValues}
                                transportContracts={filteredTransportContractOptions}
                                updateTransportContracts={updateFilteredTransportContractOptions}
                                isNewVersion={formType === 'newVersion'}
                            />
                        )
                    }}
                </Formik>
                { hasPermission(Permission.DeleteTrips)
                    && id
                    && tripDefinition
                    && !(tripDefinition.workGroups && tripDefinition.workGroups.length > 0)
                    && !tripDefinition?.hasWorkItem
                    &&
                    <TextButton text="Kustuta reis" startIcon={<Delete />} onClick={handleDeleteClick} styles={{mt: 1}} />
                }
            </Box>
            {workGroups && <TripDefinitionWorkGroups workGroups={workGroups} />}
        </Box>
        :
        <Loader />
    );
}
