import React from "react";
import {Box, Tooltip, useTheme} from "@mui/material";
import {EntityId} from "@reduxjs/toolkit";
import {Region, RepetitionRules} from "../../../../../API/types";
import {useAppSelector} from "../../../../../hooks";
import {selectDisplayWorkGroupByIdMemoized} from "../../../store/selectors";
import {shallowEqual} from "react-redux";
import {getDisplayDate, getDisplayDateWithoutYear} from "../../../../../utils/dateUtils";
import {LinkRounded} from "@mui/icons-material";


interface PlannerTooltipProps {
    groupId: EntityId;
    repetition?: RepetitionRules;
    otherRegion?: Region,
    children: React.ReactElement;
}

const GroupCardTooltip = ({groupId, otherRegion, children}: PlannerTooltipProps) => {
    const workGroup = useAppSelector(state => selectDisplayWorkGroupByIdMemoized(state, Number(groupId)), shallowEqual);
    const theme = useTheme();

    const Row = ({text, date}: {text: string, date: string}) =>
        <Box><span style={{textDecoration: 'underline'}}>{text}</span>: {date}</Box>;

    return (
        <Tooltip
            placement="top"
            arrow
            PopperProps={{
                modifiers: [
                    {name: 'offset', options: {offset: [0, -8]}}
                ],
            }}
            title={
                otherRegion || workGroup ? (
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5, fontSize: theme.typography.body2.fontSize}} px={0.5} py={1}>
                        <Box sx={{display: 'flex'}}>
                            {workGroup?.code}
                            {workGroup?.linkedWorkGroup &&
                                <><LinkRounded sx={{fontSize: '20px', mt: '-2px', mx: 0.5}} />{workGroup.linkedWorkGroup.code}</>
                            }
                        </Box>
                        {workGroup?.validFrom && <Row text="Kehtivuse algus" date={getDisplayDate(workGroup.validFrom)} />}
                        {workGroup?.validTo && <Row text="Kehtivuse lõpp" date={getDisplayDate(workGroup.validTo)} />}
                        {workGroup?.startDate && workGroup?.endDate &&
                            <Row
                                date={getDisplayDateWithoutYear(workGroup.startDate) + '-' + getDisplayDateWithoutYear(workGroup.endDate)}
                                text="Sesonaalsus"
                            />
                        }
                        {otherRegion && <Row text="Piirkond" date={otherRegion.name} />}
                    </Box>
                ) : undefined
            }
        >
            {children}
        </Tooltip>
    );
};

export default GroupCardTooltip;
