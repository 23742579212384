import {Dayjs} from "dayjs";
import axios from "axios";
import {
    DriverWorkSheetRecord,
    GenerateWorkSheetRequest, ResourceWorkSheet,
    SaveWorkItem,
    SaveWorkSheet,
    TripSegmentResources,
    WorkSheet,
    WorkSheetDetails,
    WorkSheetWorkItem
} from "./types";
import {mapErrors} from "../../utils/errorMapping";
import {GetWorkScheduleResponse} from "../workSchedule/types";
import {ResourceType} from "../types";
import {handleError} from "../utils";
import {getDateString} from "../../utils/dateUtils";
import {getDefectsByRegionAndDateRange} from "../defects/api";

export const getWorkSheetsByRegionAndDate = async (regionId: number, date: Dayjs) =>
    axios.get<GetWorkScheduleResponse>(
        `/api/regions/${regionId}/work-schedule/${date.format('YYYY-MM-DD')}/work-sheet`
    ).then(result => result.data)
    .catch(handleError);

export const getWorkSheetsByRegionAndDateWithPrevAndNextDay = async (regionId: number, date: Dayjs) => {
    const yesterdayPromise = getWorkSheetsByRegionAndDate(regionId, date.subtract(1, 'day'));
    const todayPromise = getWorkSheetsByRegionAndDate(regionId, date);
    const tomorrowPromise = getWorkSheetsByRegionAndDate(regionId, date.add(1, 'day'));
    const defectsPromise = getDefectsByRegionAndDateRange(regionId, date);

    const [yesterday, today, tomorrow, defects] = await Promise.all([
        yesterdayPromise,
        todayPromise,
        tomorrowPromise,
        defectsPromise
    ]);

    return {
        workScheduleItems: [...yesterday.workScheduleItems, ...today.workScheduleItems, ...tomorrow.workScheduleItems],
        workItems: [...yesterday.workItems, ...today.workItems, ...tomorrow.workItems],
        workGroups: [...yesterday.workGroups, ...today.workGroups, ...tomorrow.workGroups],
        activities: [...yesterday.activities, ...today.activities, ...tomorrow.activities],
        tripDefinitions: [...yesterday.tripDefinitions, ...today.tripDefinitions, ...tomorrow.tripDefinitions],
        defects: defects,
    };
};

export const createWorkSheet = async (workSheet: SaveWorkSheet) =>
    axios.post<WorkSheet>('/api/work-sheets', workSheet)
        .then(result => result.data)
        .catch(error => mapErrors(error));

export const updateWorkSheet = async (workSheet: SaveWorkSheet) =>
    axios.put<WorkSheet>(`/api/work-sheets/${workSheet.id}`, workSheet)
        .then(result => result.data)
        .catch(error => mapErrors(error));

export const getWorkSheet = async (id: number | string) => {
    const workSheet = await axios.get<WorkSheetDetails>(`/api/work-sheets/${id}`)
        .then(result => result.data);
    const secondaryWorkSheetIds: number[] = [];
    workSheet.workItems.forEach(item => {
        const secondaryWorkSheetId = workSheet.resourceType === ResourceType.DRIVER
            ? item.busWorkSheetId
            : item.driverWorkSheetId;

        if (secondaryWorkSheetId && !secondaryWorkSheetIds.includes(secondaryWorkSheetId)) {
            secondaryWorkSheetIds.push(secondaryWorkSheetId);
        }
    });
    const secondaryWorkSheets = await Promise.all(secondaryWorkSheetIds.map(secondaryWorkSheetId =>
        axios.get<WorkSheetDetails>(`/api/work-sheets/${secondaryWorkSheetId}`)
            .then(result => result.data)
    ));

    return {
        workSheet: workSheet,
        secondaryWorkSheets: secondaryWorkSheets,
    };
};

export const generateWorkSheet = async (generateWorkSheetRequest: GenerateWorkSheetRequest) =>
    axios.post<GetWorkScheduleResponse>('/api/work-sheets/generate', generateWorkSheetRequest)
        .then(result => result.data)
        .catch(handleError);


export const createWorkItem = async (workItem: SaveWorkItem) =>
    axios.post<WorkSheetWorkItem>('/api/work-items', workItem)
        .then(result => result.data)
        .catch(handleError);

export const setTripSegmentResources = async (tripSegmentId: number, date: Dayjs, tripSegmentResources: TripSegmentResources) =>
    axios.put<WorkSheetWorkItem>(`/api/work-items/trip-segments/${tripSegmentId}/${date.format('YYYY-MM-DD')}/resources`, tripSegmentResources)
        .then(result => result.data)
        .catch(handleError);

export const removeWorkItemResource = async (workItemId: number, workSheetId: number) =>
    axios.delete(`/api/work-items/${workItemId}/resources/${workSheetId}`)
        .catch(handleError);

export const updateWorkItem = async (id: number | string, workItem: SaveWorkItem) =>
    axios.put<WorkSheetWorkItem>(`/api/work-items/${id}`, workItem)
        .then(result => result.data)
        .catch(handleError);

export const deleteWorkItem = async (id: number | string) =>
    axios.delete(`/api/work-items/${id}`)
        .then(result => result.data)
        .catch(handleError);

export const getUnplannedWorkItems = async (regionId: number, date: Dayjs, resourceType: ResourceType)=>
    axios.get<WorkSheetWorkItem[]>(`/api/regions/${regionId}/work-items/unplanned?date=${date.format('YYYY-MM-DD')}&resource-type=${resourceType}`)
        .then(result => result.data)
        .catch(handleError);

export const updateWorkSheetComment = async (id: number, comment: string) =>
    axios.put(
        `/api/work-sheets/${id}/driver-comment`,
        comment ?? '',
        {
            headers: {
                'Content-Type': 'text/plain',
            },
        }
    )
        .then(result => result.data)
        .catch(handleError);

export const getWorkSheetsConnectedToBus = async (busId: number, date: Date)=>
    axios.get<DriverWorkSheetRecord[]>(`/api/work-sheets/connected-to-bus?busId=${busId}&date=${getDateString(date)}`)
        .then(result => result.data)
        .catch(handleError);

export const getWorkSheetsWithResourceOnDate = async (date: string) =>
    axios.get<ResourceWorkSheet[]>(`/api/work-sheets/with-resource?date=${date}`)
        .then(result => result.data)
        .catch(handleError);