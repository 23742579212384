import React, {useMemo} from 'react';
import DataGrid from "../../../../../components/DataGrid/DataGrid";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {Permission} from "../../../../../types";
import NavIcon from "../../../../../components/Icon/NavIcon";
import routes from "../../../../../routes";
import NavLink from "../../../../../components/NavLink/NavLink";
import {DefectWithSearchFields} from "../../../../../store/defectsSlice";
import {
    NumberOfCommentsIconText,
    PriorityIconText,
    RepairDateIconText,
    StatusIconText
} from "../../detailsView/components/DefectFields";
import {Box, useMediaQuery,} from "@mui/material";
import theme from "../../../../../theme";
import {filterByValue} from "../../../../../utils/utils";
import FilterToolbar from "../../../../../components/FilterToolbar/FilterToolbar";
import {useOutletContext} from "react-router-dom";
import {SearchContextType} from "../../../../../layouts/SearchLayoutWrapper";
import DefectComments from "../../detailsView/components/DefectComments";
import {useLocalStorage} from "usehooks-ts";
import {useSearchRegions} from "../../../../../hooks";
import {FilterChipProps} from "../../../../../components/FilterToolbar/components/FilterChip";


interface DefectsTableProps {
    defects: DefectWithSearchFields[];
}

export const DefectsTable = ({defects}: DefectsTableProps) => {
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        paginationModel,
        setPaginationModel,
    } = useOutletContext<SearchContextType>();
    const [searchInput, setSearchInput] = useLocalStorage('defectsSearchInput', '');
    const [showAllComments, setShowAllComments] = useLocalStorage('defectsShowAllComments', false);
    const [searchRegions] = useSearchRegions();

    const filteredRows = useMemo(() => {
        return filterByValue(defects, searchInput)
            .filter(defect =>
                searchRegions.length === 0
                || [...defect.bus.regionIds, defect.bus.accountingRegionId].some(
                    regionId => searchRegions.findIndex((region => region.id === regionId)) > -1
                )
            )
        }, [searchInput, searchRegions]
    );

    const handleShowCommentsChange = () => setShowAllComments(prevState => !prevState);

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: 'Esitatud',
            sortable: true,
            filterable: false,
            minWidth: 120,
            renderCell: (params) =>
                <NavLink
                    id={params.row.id}
                    value={params.row.createdAtDisplayStr}
                    route={routes.AUTHENTICATED.DEFECTS.EDIT}
                    navPermission={Permission.UpdateDefects}
                />
        },
        {
            field: 'lastEditedAt',
            headerName: 'Viimati muudetud',
            sortable: true,
            filterable: false,
            minWidth: 140,
            renderCell: (params: GridRenderCellParams) => params.row.lastEditedAtDisplayStr
        },
        {
            field: 'priorityDisplayStr',
            headerName: 'Olulisus',
            sortable: true,
            filterable: false,
            width: isScreenSmall || showAllComments ? 50 : 190,
            renderCell: (params) =>
                <PriorityIconText
                    priority={params.row.priority}
                    priorityDisplayStr={params.value}
                    hideTextInMobileView={true}
                    showCompactView={showAllComments}
                />
        },
        {
            field: 'busLicencePlateNr',
            headerName: 'Buss',
            sortable: true,
            filterable: false,
            width: 80,
            renderCell: (params) =>
                <NavLink
                    id={params.row.bus.id}
                    value={params.value}
                    route={routes.AUTHENTICATED.BUSES.EDIT}
                    openInNewWindow={true}
                    navPermission={Permission.UpdateBuses}
                />
        },
        {
            field: 'creatorUserName',
            headerName: 'Esitaja',
            sortable: true,
            filterable: false,
            minWidth: 130,
        },
        {
            field: 'statusDisplayStr',
            headerName: 'Staatus',
            sortable: true,
            filterable: false,
            minWidth: 100,
            renderCell: (params) => <StatusIconText status={params.row.status} statusDisplayStr={params.value} />
        },
        {
            field: 'repairDateDisplayStr',
            headerName: 'Parandusaeg',
            sortable: true,
            filterable: false,
            minWidth: 150,
            valueGetter: (params) => params.row.repairedAt ?? params.row.expectedRepairDateTime,
            renderCell: (params) => <RepairDateIconText repairDateDisplayStr={params.row.repairDateDisplayStr} repairedAt={params.row.repairedAt} expectedRepairDateTime={params.row.expectedRepairDateTime} />
        },
        {
            field: 'description',
            headerName: 'Kirjeldus',
            sortable: false,
            filterable: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'commentIds',
            headerName: 'Kommentaarid',
            sortable: true,
            filterable: false,
            minWidth: showAllComments ? 300 : 110,
            valueGetter: (params) => params.value?.length ?? 0,
            renderCell: ({value, row}) => {
                return (value > 0)
                    ? (
                        showAllComments ?
                            <DefectComments defectId={row.id} showTableView/>
                            :
                            <NumberOfCommentsIconText numberOfComments={value} />
                    ) : null
            }
        },
        {
            field: 'otherSearchFields',
            headerName: '',
            sortable: false,
            filterable: false,
            align: 'right',
            width: 30,
            renderCell: (params) =>
                <Box mt={showAllComments ? -0.5 : 0}>
                    <NavIcon type="VIEW" id={params.row.id} route={routes.AUTHENTICATED.DEFECTS.EDIT} navPermission={Permission.ReadDefects} />
                </Box>
        }
    ];

    return (
        <DataGrid
            rows={filteredRows}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            enableMultiRowCells={showAllComments}
            filterToolbar={
                <FilterToolbar
                    inputSearch={{value: searchInput, updateValue: setSearchInput}}
                    showRegionsSearch
                    filterChips={[{selected: showAllComments, handleSelectClick: handleShowCommentsChange, text: 'Kuva kommentaarid', value: showAllComments}] as FilterChipProps<boolean>[]}
                />
            }
        />
    );
};

export default DefectsTable;
