import dayjs, {Dayjs} from "dayjs";
import {OdometerReading, OdometerReadingWithBusId} from "../../../API/types";
import {BusUsage} from "../../../API/workSchedule/types";
import {getDateTimeString} from "../../../utils/dateUtils";
import {ReadingFormData} from "./ReadingForm";
import {OdometerReadingType} from "../../../API/bus/types";

const VALID_READING_BUFFER_MINUTES = 15;
const WARNING_DIFF_MARGIN_KM = 1000;

export const isReadingWithinBusUsage = (itemDateTime: string | Date | Dayjs, busUsage: BusUsage): boolean => {
    const dateTime = dayjs(itemDateTime);
    const minDateTime = dayjs(busUsage.startDateTime).subtract(VALID_READING_BUFFER_MINUTES, 'minutes');
    const maxDateTime = dayjs(busUsage.endDateTime).add(VALID_READING_BUFFER_MINUTES, 'minutes');

    return dateTime >= minDateTime && dateTime <= maxDateTime;
};

export const isReadingWithinAllowedTimeRange = (targetDateTime: Dayjs, itemDateTime: Dayjs) =>
    Math.abs(targetDateTime.diff(itemDateTime, 'minute')) < VALID_READING_BUFFER_MINUTES;

export const findNearestReading = <T extends OdometerReading | OdometerReadingWithBusId>(
    odometerReadings: T[],
    expectedReadingDateTime: string, driverId?: number | null,
    expectedType?: OdometerReadingType
): T | undefined => {
    const expectedReadingDateTimeDayjs = dayjs(expectedReadingDateTime);
    const validOdometerReadings = odometerReadings.filter(reading => {
        if (driverId !== undefined && reading.driverId !== driverId) return false;
        if (reading.type && expectedType && reading.type !== expectedType) return false;
        const readingDateTimeDayjs = dayjs(reading.dateTime);
        return isReadingWithinAllowedTimeRange(expectedReadingDateTimeDayjs, readingDateTimeDayjs);
    });

    validOdometerReadings.sort((a, b) => {
        const aDateTime = dayjs(a.dateTime);
        const bDateTime = dayjs(b.dateTime);
        const diffA = Math.abs(expectedReadingDateTimeDayjs.diff(aDateTime, 'minute'));
        const diffB = Math.abs(expectedReadingDateTimeDayjs.diff(bDateTime, 'minute'));
        return diffA - diffB;
    });

    if (!validOdometerReadings.length) {
        return undefined;
    } else {
        return validOdometerReadings.find(reading => reading.type && expectedType && reading.type === expectedType) // prefer reading that has a matching type
            ?? validOdometerReadings.find(reading => !reading.fuelType) // fallback to any closest reading that is not fuelling
            ?? validOdometerReadings[0]; // default to any closest reading
    }
};

export const findPreviousReading = (odometerReadings: OdometerReading[], expectedReadingDateTime: string) => {
    const expectedReadingDateTimeDayjs = dayjs(expectedReadingDateTime);

    let closestReading = undefined;
    for (const reading of odometerReadings) {
        const readingDateTimeDayjs = dayjs(reading.dateTime);
        if (readingDateTimeDayjs.isBefore(expectedReadingDateTimeDayjs)) {
            if (!closestReading || readingDateTimeDayjs.isAfter(dayjs(closestReading.dateTime))) {
                closestReading = reading;
            }
        }
    }

    return closestReading;
};

export const validateReadingWithPrevious = (existingReadings: OdometerReading[], readingForm: ReadingFormData) => {
    const prevReading = findPreviousReading(existingReadings, getDateTimeString(readingForm.dateTime));
    const newReading = Number(readingForm.reading);

    if (prevReading) {
        if (prevReading.reading > newReading) {
            return confirm('Odomeetri näit on väiksem kui eelmine sisestatud näit. Kas soovid siiski näidu salvestada?');
        } else if (prevReading.reading + WARNING_DIFF_MARGIN_KM < newReading) {
            return confirm(`Odomeetri näit on ${newReading - prevReading.reading}km suurem kui eelmine sisestatud näit. Kas soovid siiski näidu salvestada?`);
        }
    }

    return true;
}
