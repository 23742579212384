import React, {useEffect} from "react";
import {Box} from "@mui/material";
import PageHeader from "../../../components/PageHeader/PageHeader";
import dayjs, {Dayjs} from "dayjs";
import {
    BusReport,
    BusStopUsagesReport,
    OverUnderTimeReport,
    Region,
    TripReport,
    WorkGroup
} from "../../../API/types";
import {
    getBusReport,
    getLineReport,
    getDriverWorkGroupsReport,
    getOverUnderTimeReport,
    getBusStopUsagesReport,
    getTransportContractPayReport
} from "../../../API";
import {formatMonthlyLineReportData} from "./formatters/monthlyLineReportFormatter";
import {formatBusReportData} from "./formatters/busReportFormatter";
import {formatDailyLineReport} from "./formatters/dailyLineReportFormatter";
import {SelectOptionWithIdAndPermission} from "../../../types";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectSelectedRegion} from "../../../store/regionSlice";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {setToast} from "../../../store/toastSlice";
import writeXlsxFile, {Columns, SheetData} from "write-excel-file";
import DatePicker from "../../../components/Form/DateTimePicker/DatePicker";
import Button from "../../../components/Button/Button";
import {Download} from "@mui/icons-material";
import SelectWithId from "../../../components/Form/Select/SelectWithId";
import * as Yup from "yup";
import {dateValidToRequired, validationSchema} from "../../../utils/formValidation";
import {formatWorkGroupReportData} from "./formatters/workGroupReportFormatter";
import {formatWorkGroupSimplifiedReportData} from "./formatters/workGroupSimplifiedReportFormatter";
import {formatOverUnderTimeReportData} from "./formatters/overUnderPayReportFormatter";
import PageHeaderRegionSelect from "../../../components/PageHeader/components/RegionSelect";
import {formatBusStopUsagesReport} from "./formatters/busStopUsageReportFormatter";
import TransportContractsSelect from "./components/TransportContractsSelect";
import {TransportContractPayReport} from "../../../API/reports/types";
import {formatTransportContractPayReportData} from "./formatters/transportContractPayReportFormatter";
import {selectAllTransportContracts} from "../../../store/transportContractsSlice";


const reportValidationSchema = Yup.object().shape({
    reportType: validationSchema('Tüüp').fields.numberRequired,
    startDate: validationSchema('Alguskuupäev').fields.dateRequired,
    endDate: dateValidToRequired('Lõpukuupäev', 'startDate'),
    transportContractId: Yup.number()
        .when('reportType', (typeId, schema) => {
            const isTransportContractIncluded = reportTypes.find(reportType => typeId[0] === reportType.id)?.fieldConfig.transportContract ?? false;

            if (isTransportContractIncluded) {
                return schema.min(1, 'Veoleping on kohustuslik väli')
            } else {
                return schema.notRequired()
            }
        }),
});

export interface ReportDownloadFormData {
    reportType: number;
    transportContractId: number;
    startDate: Date | null;
    endDate: Date | null;
}

interface ReportSelectOption extends SelectOptionWithIdAndPermission {
    fieldConfig: FieldConfig;
}

interface FieldConfig {
    region: boolean;
    transportContract: boolean;
    startDate: boolean;
    endDate: boolean;
    yearMonth: boolean;
}

const defaultFieldConfig: FieldConfig = {
    region: true,
    transportContract: false,
    startDate: true,
    endDate: true,
    yearMonth: false,
}

const defaultYearMonthFieldConfig: FieldConfig = {
    region: false,
    transportContract: false,
    startDate: false,
    endDate: false,
    yearMonth: true,
}

const reportTypes: ReportSelectOption[] = [
    {id: 0, name: '-', fieldConfig: defaultFieldConfig},
    {id: 1, name: 'Reisiaruanne', fieldConfig: defaultFieldConfig},
    {id: 2, name: 'Liiniaruanne', fieldConfig: defaultFieldConfig},
    {id: 3, name: 'Tööaruanne', fieldConfig: defaultFieldConfig},
    {id: 4, name: 'Töögruppide aruanne', fieldConfig: defaultFieldConfig},
    {id: 5, name: 'Töögruppide aruanne (print)', fieldConfig: defaultFieldConfig},
    {id: 6, name: 'Ületundide aruanne', fieldConfig: defaultYearMonthFieldConfig, requireAllRegions: true},
    {id: 7, name: 'Veolepingu palgaaruanne', fieldConfig: {...defaultYearMonthFieldConfig, transportContract: true}, requireAllRegions: true},
    {id: 8, name: 'Peatuste kasutamise aruanne', fieldConfig: {...defaultFieldConfig, region: false}, requireAllRegions: true},
];

const FormContent = ({reportTypeId}: {reportTypeId: number}) => {
    const reportType = reportTypes.find(reportType => reportType.id === reportTypeId);

    return (
        <>
            {reportType?.fieldConfig.region && <PageHeaderRegionSelect styles={{width: '100%'}} />}
            {reportType?.fieldConfig.transportContract && <TransportContractsSelect />}
            {reportType?.fieldConfig.yearMonth && <DatePicker name="startDate" label="Kuu" hideDay />}
            {reportType?.fieldConfig.startDate && <DatePicker name="startDate" label="Alguskuupäev" disabled={reportTypeId === 0} />}
            {reportType?.fieldConfig.endDate && <DatePicker name="endDate" label="Lõpukuupäev" disabled={reportTypeId === 0} />}
        </>
    );
};

const Reports = () => {
    const dispatch = useAppDispatch();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const transportContracts = useAppSelector(selectAllTransportContracts);
    const today = dayjs();
    const startOfPrevMonth = today.subtract(1, 'month').startOf('month');
    const endOfPrevMonth = startOfPrevMonth.endOf('month');
    const startOfPrevWeek = today.subtract(1, 'week').startOf('week');
    const endOfPrevWeek = startOfPrevWeek.endOf('week');

    const initialValues: ReportDownloadFormData = {
        transportContractId: 0,
        reportType: 0,
        startDate: null,
        endDate: null,
    };

    const handleDownloadFile = (data: ReportDownloadFormData, helpers: FormikHelpers<ReportDownloadFormData>) => {
        switch (data.reportType) {
            case 1:
                composeAndDownloadFileWithDateRange<TripReport[]>(
                    data,
                    helpers,
                    reportTypes[1].name,
                    'reisiaruanne_%region%_%startDate%-%endDate%.xlsx',
                    getLineReport,
                    formatDailyLineReport
                );
                break;
            case 2:
                composeAndDownloadFileWithDateRange<TripReport[]>(
                    data,
                    helpers,
                    reportTypes[2].name,
                    'liiniaruanne_%region%_%startDate%-%endDate%.xlsx',
                    getLineReport,
                    formatMonthlyLineReportData
                );
                break;
            case 3:
                composeAndDownloadFileWithDateRange<BusReport[]>(
                    data,
                    helpers,
                    reportTypes[3].name,
                    'tooaruanne_%region%_%startDate%-%endDate%.xlsx',
                    getBusReport,
                    formatBusReportData
                );
                break;
            case 4:
                composeAndDownloadFileWithDateRange<WorkGroup[]>(
                    data,
                    helpers,
                    reportTypes[4].name,
                    'bussijuhi_toogruppide_aruanne_%region%_%startDate%-%endDate%.xlsx',
                    getDriverWorkGroupsReport,
                    formatWorkGroupReportData
                );
                break;
            case 5:
                composeAndDownloadFileWithDateRange<WorkGroup[]>(
                    data,
                    helpers,
                    reportTypes[5].name,
                    'bussijuhi_toogruppide_print_aruanne_%region%_%startDate%-%endDate%.xlsx',
                    getDriverWorkGroupsReport,
                    formatWorkGroupSimplifiedReportData
                );
                break;
            case 6:
                composeAndDownloadFileWithYearMonth<OverUnderTimeReport[]>(
                    data,
                    helpers,
                    reportTypes[6].name,
                    'yletundide_aruanne_%yearMonth%.xlsx',
                    getOverUnderTimeReport,
                    formatOverUnderTimeReportData
                );
                break;
            case 7:
                transportContractPayComposeAndDownloadWrapper(data, helpers);
                break;
            case 8:
                composeAndDownloadFileWithDateRange<BusStopUsagesReport>(
                    data,
                    helpers,
                    reportTypes[7].name,
                    'peatuste_kasutamise_aruanne_%startDate%-%endDate%.xlsx',
                    getBusStopUsagesReport,
                    formatBusStopUsagesReport
                );
        }
    };

    async function composeAndDownloadFileWithDateRange<T>(
        data: ReportDownloadFormData,
        helpers: FormikHelpers<ReportDownloadFormData>,
        name: string,
        fileNameFormat: string,
        fetchData: (regionId: number | string, startDate: Dayjs, endDate: Dayjs) => T | Promise<T>,
        composeReport: (data: T, startDate: Dayjs, endDate: Dayjs, region: Region) => [SheetData, Columns],
    ) {
        if (!selectedRegion) {
            dispatch(setToast({type: 'error', text: `${name} allalaadimisel tekkis viga`}));
            return;
        }
        const start = dayjs(data.startDate);
        const end = dayjs(data.endDate);

        try {
            const fileName = fileNameFormat
                .replace('%region%', selectedRegion.name)
                .replace('%startDate%', start.format('DD.MM.YYYY'))
                .replace('%endDate%', end.format('DD.MM.YYYY'));
            const data = await fetchData(selectedRegion.id, start, end);
            const [formattedData, columns] = composeReport(data, start, end, selectedRegion);

            await writeXlsxFile(
                formattedData,
                {
                    fileName: fileName,
                    columns: columns,
                    sheet: name,
                }
            );
        } catch (e) {
            console.error(e);
            dispatch(setToast({type: 'error', text: `${name}: allalaadimisel tekkis viga`}));
        }
        helpers.setSubmitting(false);
    }

    async function composeAndDownloadFileWithYearMonth<T>(
        data: ReportDownloadFormData,
        helpers: FormikHelpers<ReportDownloadFormData>,
        name: string,
        fileNameFormat: string,
        fetchData: (yearMonth: string) => T | Promise<T>,
        composeReport: (data: T, startDate: Dayjs, endDate: Dayjs) => [SheetData, Columns],
    ) {
        const start = dayjs(data.startDate).startOf('month');
        const end = dayjs(data.startDate).endOf('month');
        const yearMonth = start.format('YYYY-MM');

        try {
            const fileName = fileNameFormat.replace('%yearMonth%', yearMonth)
            const data = await fetchData(yearMonth);
            const [formattedData, columns] = composeReport(data, start, end);

            await writeXlsxFile(
                formattedData,
                {
                    fileName: fileName,
                    columns: columns,
                    sheet: name,
                }
            );
        } catch (e) {
            console.error(e);
            dispatch(setToast({type: 'error', text: `${name}: allalaadimisel tekkis viga`}));
        }
        helpers.setSubmitting(false);
    }

    async function transportContractPayComposeAndDownloadWrapper(data: ReportDownloadFormData, helpers: FormikHelpers<ReportDownloadFormData>) {
        const selectedTransportContract = transportContracts.find(transportContract => transportContract.id === data.transportContractId);

        return composeAndDownloadFileWithYearMonth<TransportContractPayReport[]>(
            data,
            helpers,
            reportTypes[7].name,
            `veolepingu_palgaaruanne_%yearMonth%${selectedTransportContract ? '_' + selectedTransportContract.contractId : ''}.xlsx`,
            (yearMonth: string) => getTransportContractPayReport(yearMonth, data.transportContractId),
            (data: TransportContractPayReport[], startDate: Dayjs, endDate: Dayjs) => formatTransportContractPayReportData(data, startDate, endDate, selectedTransportContract?.contractId)
        );
    }

    return (
        <Box>
            <PageHeader title="Aruanded" />
            <Formik initialValues={initialValues} onSubmit={handleDownloadFile} validationSchema={reportValidationSchema}>
                {(formikProps: FormikProps<ReportDownloadFormData>) => {
                    useEffect(() => {
                        if (formikProps.values.startDate === null && formikProps.values.endDate === null && formikProps.values.reportType !== 0) {
                            switch (formikProps.values.reportType) {
                                case 1:
                                    formikProps.setFieldValue('startDate', startOfPrevWeek.toDate());
                                    formikProps.setFieldValue('endDate', endOfPrevWeek.toDate());
                                    break;
                                case 2:
                                case 3:
                                case 6:
                                case 7:
                                    formikProps.setFieldValue('startDate', startOfPrevMonth.toDate());
                                    formikProps.setFieldValue('endDate', endOfPrevMonth.toDate());
                                    break;
                            }
                        } else if (formikProps.values.startDate !== null && formikProps.values.endDate !== null && formikProps.values.reportType === 0) {
                            formikProps.resetForm();
                        }
                    }, [formikProps.values.reportType]);

                    useEffect(() => {
                        const isMonthBased = !!reportTypes.find(reportType => reportType.id === formikProps.values.reportType)?.fieldConfig.yearMonth;
                        if (isMonthBased) {
                            const startDateDayjs = dayjs(formikProps.values.startDate);
                            if (startDateDayjs.isValid())
                                formikProps.setFieldValue('endDate', startDateDayjs.endOf('month').toDate());
                        }
                    }, [formikProps.values.startDate]);

                    return <Form>
                        <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: {xs: '100%', sm: '400px'}}}>
                            <SelectWithId name="reportType" label="Aruanne" options={reportTypes} />
                            <FormContent reportTypeId={formikProps.values.reportType} />
                            <Box pt={1}>
                                <Button
                                    text="Lae alla"
                                    type="submit"
                                    startIcon={<Download/>}
                                    disabled={formikProps.isSubmitting || formikProps.values.reportType === 0}
                                />
                            </Box>
                        </Box>
                    </Form>
                }}
            </Formik>
        </Box>
    );
};

export default Reports;
