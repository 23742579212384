import axios from "axios";
import {AvlAssignment} from "./types";
import {Dayjs} from "dayjs";
import {handleError} from "../utils";

export const getAvlAssignments = (date: Dayjs) =>
    axios.get<AvlAssignment[]>(`/api/avl-assignments/${date.format('YYYY-MM-DD')}`)
        .then(result => result.data)
        .catch(handleError);

export const reportAvlAssignments = () =>
    axios.post(`/api/avl-assignments/report-today`)
        .then(result => result.data)
        .catch(handleError);
