import React, {useEffect, useMemo, useState} from "react";
import {useAppDispatch, useSearchRegions} from "../../../hooks";
import {getTripOccurrences} from "../../../API";
import {TripOccurrenceRow} from "./types";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import DataGrid, {DEFAULT_COL_DEF_PROPS} from "../../../components/DataGrid/DataGrid";
import ListView from "../../../layouts/ListViewWrapper";
import AdvancedFilterToolbar from "./components/AdvancedFilterToolbar";
import dayjs, {Dayjs} from "dayjs";
import {formatTimeAtDate, getDisplayTimeStr, timeSegmentOverlapsWithCriteriaTimeSegment} from "../../../utils/dateUtils";
import {filterByValue, getStartAndEndPointLabel} from "../../../utils/utils";
import routes from "../../../routes";
import {Permission} from "../../../types";
import {Box} from "@mui/material";
import {TripOccurrenceSegment} from "../../../API/trips/types";
import {EllipsisTextDisplayField} from "./components/EllipsisTextDisplayField";
import {FormattedNavLink} from "./components/FormattedNavLink";
import {setToast} from "../../../store/toastSlice";
import {stringIncludes, occurrenceToRow} from "./utils";
import { SearchCriteria } from "./types";
import {FilterChipProps} from "../../../components/FilterToolbar/components/FilterChip";
import {useDebounce, useLocalStorage} from "usehooks-ts";
import {useDayNavigation} from "../../../store/viewSlice";
import {useOutletContext} from "react-router-dom";
import {SearchContextType} from "../../../layouts/SearchLayoutWrapper";
import {DEFAULT_DEBOUNCE_DELAY} from "../../../constants";
import {Region} from "../../../API/region/types";

const timeColWidth = 90;
const busColWidth = 65;
const driverNameColWidth = 150;

const initialCriteria: SearchCriteria = {
    lineNumber: '',
    tripCode: '',
    licensePlateNumber: '',
    driverName: '',
    fromTime: null,
    toTime: null,
};

const renderRoute = (row: TripOccurrenceRow) => {
    if (row.route !== null) {
        return <EllipsisTextDisplayField value={getStartAndEndPointLabel(row.route)} titleValue={row.routeString}/>;
    } else {
        return '';
    }
};

const renderSegment = (segment: TripOccurrenceSegment, index: number = 1) => {
    return <Box key={index} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
        <EllipsisTextDisplayField sx={{width: timeColWidth}} value={`${getDisplayTimeStr(segment.startDateTime)} - ${getDisplayTimeStr(segment.endDateTime)}`} />
        <Box sx={{width: busColWidth}}>
            { segment.busWorkSheet &&
                <FormattedNavLink
                    id={segment.busWorkSheet.workSheetId}
                    value={segment.busWorkSheet.licensePlateNumber}
                    route={routes.AUTHENTICATED.WORK_SHEETS.DETAILS}
                    permission={Permission.ReadWorkSchedules}
                />
            }
        </Box>
        { segment.driverWorkSheet &&
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
                <Box sx={{width: driverNameColWidth, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                    <FormattedNavLink
                        id={segment.driverWorkSheet.workSheetId}
                        value={segment.driverWorkSheet.driver?.fullName}
                        route={routes.AUTHENTICATED.WORK_SHEETS.DETAILS}
                        permission={Permission.ReadWorkSchedules}
                    />
                </Box>
                <EllipsisTextDisplayField value={segment.driverWorkSheet.driver?.phoneNumber ?? ''} />
            </Box>
        }
    </Box>;
};

const renderSegmentsHeader = () => {
    return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
        <Box sx={{width: timeColWidth}}><b>Aeg</b></Box>
        <Box sx={{width: busColWidth}}><b>Buss</b></Box>
        <Box sx={{width: driverNameColWidth}}><b>Juht</b></Box>
    </Box>
};

const renderSegments = (row: TripOccurrenceRow) => {
    const sortedSegments = row.segments
        .toSorted((a, b) => a.startDateTime.localeCompare(b.startDateTime));

    return <Box>
        { sortedSegments.map((segment, index) => renderSegment(segment, index)) }
    </Box>;
};

const renderDateTimeCell = (params: GridRenderCellParams<TripOccurrenceRow, any, any>) => <EllipsisTextDisplayField value={formatTimeAtDate(params.value, params.row.date)}/>;

const getRegionIds = (searchRegions: Region[]) => {
    if (searchRegions.length < 1) {
        return null;
    }
    return searchRegions.map(region => region.id);
}

export default function TripLookup() {
    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(initialCriteria);
    const [rows, setRows] = useState<TripOccurrenceRow[] | undefined>();
    const [showFirstDepartureOnly, setShowFirstDepartureOnly] = useLocalStorage('showFirstDepartureOnly', false);
    const [date, handleChangeDate] = useDayNavigation();
    const {searchInput, setSearchInput} = useOutletContext<SearchContextType>();
    const [searchRegions] = useSearchRegions();
    const [refreshTime, setRefreshTime] = useState<Dayjs | null>(null);
    const debouncedDate = useDebounce(date, DEFAULT_DEBOUNCE_DELAY);
    const debouncedSearchRegions = useDebounce(searchRegions, DEFAULT_DEBOUNCE_DELAY);
    const debouncedSearchInput = useDebounce(searchInput, 100);

    const dispatch = useAppDispatch();

    const memoizedSearchRegions = useMemo(
        () => debouncedSearchRegions,
        [debouncedSearchRegions.join(",")]
    );

    useEffect(() => {
        fetchOccurrences();
    }, [memoizedSearchRegions, debouncedDate]);

    const setSearchCriteriaValue = (value: Partial<SearchCriteria>) => {
        setSearchCriteria((prevState: SearchCriteria): SearchCriteria => ({...prevState, ...value}));
    };

    const handleRefreshed = () => {
        fetchOccurrences()
    }

    const fetchOccurrences = () => {
        setRows(undefined);

        getTripOccurrences(date, getRegionIds(memoizedSearchRegions))
            .then(result => {
                setRows(result.map(occurrenceToRow));
                setRefreshTime(dayjs());
            })
            .catch((apiError) => {
                setRows([]);
                dispatch(setToast({type: 'error', text: apiError.message ?? 'Reiside pärimisel ilmnes viga'}));
            });
    }

    const filterRows = (): TripOccurrenceRow[] => {
        if (rows === undefined) {
            return [];
        } else {
            const result = rows.filter((row) => {
                return stringIncludes(row.lineNumber, searchCriteria.lineNumber)
                    && stringIncludes(row.code, searchCriteria.tripCode)
                    && row.segments.some(segment => stringIncludes(segment.busWorkSheet?.licensePlateNumber, searchCriteria.licensePlateNumber))
                    && row.segments.some(segment => stringIncludes(segment.driverWorkSheet?.driver?.fullName, searchCriteria.driverName))
                    && timeSegmentOverlapsWithCriteriaTimeSegment(row, searchCriteria.fromTime, searchCriteria.toTime)
                    && (!showFirstDepartureOnly || row.segments.some(segment => segment.isFirstDeparture))
            });
            return filterByValue(result, debouncedSearchInput);
        }
    }

    const handleShowFirstDepartureOnlyFilterChipChange = () => {
        setShowFirstDepartureOnly(prevState => !prevState);
    };

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'regionName',
            headerName: 'Piirkond',
            width: 90,
            renderCell: (params) => <EllipsisTextDisplayField value={params.value} />,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'lineNumber',
            headerName: 'Liin',
            width: 75,
            renderCell: (params) => <EllipsisTextDisplayField value={params.value} />,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'code',
            headerName: 'Kood',
            renderCell: (params) =>
                <FormattedNavLink
                    id={params.row.tripId}
                    value={params.value}
                    route={routes.AUTHENTICATED.TRIPS.DETAILS}
                    permission={Permission.ReadTrips}
                />,
            width: 90
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'startDateTime',
            headerName: 'Väljumine',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: renderDateTimeCell,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'endDateTime',
            headerName: 'Saabumine',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: renderDateTimeCell,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'route',
            headerName: 'Marsruut',
            sortable: false,
            disableColumnMenu: true,
            width: 250,
            renderCell: params => renderRoute(params.row),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'segments',
            renderHeader: renderSegmentsHeader,
            sortable: false,
            disableColumnMenu: true,
            width: 500,
            renderCell: params => renderSegments(params.row),
        },
    ];

    const filterChips: FilterChipProps<boolean>[] = [{
        selected: showFirstDepartureOnly,
        handleSelectClick: handleShowFirstDepartureOnlyFilterChipChange,
        text: 'Näita esimest väljumist',
        value: showFirstDepartureOnly
    }];

    return <ListView headerProps={{title: 'Reisi otsing'}}>
        <DataGrid
            rows={filterRows()}
            columns={columns}
            filterToolbar={
                <AdvancedFilterToolbar
                    date={date}
                    handleSelectedDateChange={handleChangeDate}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    searchCriteria={searchCriteria}
                    handleChangeSearchCriteria={setSearchCriteriaValue}
                    refreshTime={refreshTime}
                    handleRefreshed={handleRefreshed}
                    filterChips={filterChips}
                />
            }
            enableMultiRowCells
            isLoading={!rows}
            sx={{
                // move to DataGrid?
                '& .MuiDataGrid-cell': {pt: .9, pb: .9, alignItems: 'center'}
            }}
        />
    </ListView>;
}