import {Role} from "../types";
import {
	CalendarEntryType,
	CharterTripStatus,
	Contract,
	DefectPriority,
	DefectStatus,
	Fuel,
	ResourceType,
	ScheduleSetting,
	WorkGroupItemType,
	WorkScheduleItemType
} from "../API/types";
import {getEnumFromStr} from "./formUtils";
import {PayType} from "../API/payslip/types";
import {CharterTripZone} from "../API/charterTrip/types";
import {WorkGroupItemTypeCharterTrip, WorkGroupItemTypeWithCharterTrip} from "../API/workSheets/types";
import {AvlSyncStatus} from "../API/avlAssignments/types";


export const getRoleTranslation = (role: Role): string => {
	switch (role) {
		case Role.ADMIN:
			return 'Admin';
		case Role.DISPATCHER:
			return 'Operatiivjuht';
		case Role.DRIVER:
			return 'Bussijuht';
		case Role.TECHNICAL_LEAD:
			return 'Tehniline juht';
		case Role.EXTERNAL_ADMIN:
			return 'Väline admin';
		case Role.INFORMATION_CLERK:
			return 'Infotöötaja';
	}
};

export const getFuelTranslation = (fuel: Fuel): string => {
	switch (fuel) {
		case Fuel.D:
			return 'Diislikütus';
		case Fuel.B:
			return 'Bensiin';
		case Fuel.XTL:
			return 'HVO';
		case Fuel.CNG:
			return 'Maagaas';
		case Fuel.CBG:
			return 'Biogaas';
	}
};

export const getFuelTranslationsDisplayStr = (fuels: Fuel[]): string => {
	return fuels.map((fuel) => getFuelTranslation(fuel)).sort((a: string, b: string) => a < b ? -1 : 1).join(', ');
};

export const getFuelUnit = (fuel: Fuel): string => {
	switch (fuel) {
		case Fuel.D:
		case Fuel.B:
		case Fuel.XTL:
			return 'L';
		case Fuel.CNG:
		case Fuel.CBG:
			return 'kg';
		default:
			return '';
	}
};

export const getFuelTranslationFromStr = (fuelString: string): string => {
	const fuel: Fuel | undefined = getEnumFromStr(fuelString, Fuel);
	return fuel ? getFuelTranslation(fuel) : '';
};

export const getContractTranslation = (contract: Contract, isDriverContractForm: boolean = false): string => {
	switch (contract) {
		case Contract.EMPLOYMENT_CONTRACT:
			return 'Tööleping';
		case Contract.SERVICE_CONTRACT:
			return 'Käsundusleping';
		case Contract.LABOR_LEASING_AGREEMENT:
			return isDriverContractForm ? 'Tööjõurent (palk Directost)' : 'Tööjõu rendileping';
	}
};

export const getContractTranslationFromStr = (contractString: string): string => {
	const contract: Contract | undefined = getEnumFromStr(contractString, Contract);
	return contract ? getContractTranslation(contract, true) : '';
};

export const getAvlSyncStatusTranslation = (status: AvlSyncStatus): string => {
	switch (status) {
		case AvlSyncStatus.OK:
			return 'Saadetud';
		case AvlSyncStatus.ERROR:
			return 'Viga';
		case AvlSyncStatus.PENDING:
			return 'Ootel';
		case AvlSyncStatus.CANCELLED:
			return 'Tühistatud';
	}
}

export const getCalendarEntryTypeTranslation = (calendarEntryType: CalendarEntryType): string => {
	switch (calendarEntryType) {
		case CalendarEntryType.PUBLIC_HOLIDAY:
			return 'Riigipüha';
		case CalendarEntryType.SCHOOL_HOLIDAY:
			return 'Sügisene, talvine ja kevadine koolivaheaeg';
		case CalendarEntryType.SUMMER_HOLIDAY:
			return 'Suvine koolivaheaeg';
	}
};

export const getCalendarEntryTypeTranslationFromStr = (calendarEntryType: string): string => {
	const calendarEntry: CalendarEntryType | undefined = getEnumFromStr(calendarEntryType, CalendarEntryType);
	return calendarEntry ? getCalendarEntryTypeTranslation(calendarEntry) : '';
};

export const getScheduleSettingTranslation = (scheduleSetting: ScheduleSetting): string => {
	switch (scheduleSetting) {
		case ScheduleSetting.NoEffect:
			return 'Toimub vastavalt tavagraafikule';
		case ScheduleSetting.Exclude:
			return 'Ei toimu';
		case ScheduleSetting.Only:
			return 'Toimub ainult siis';
		case ScheduleSetting.InAddition:
			return 'Toimub lisaks tavagraafikule';
	}
};

export const getScheduleSettingTranslationFromStr = (scheduleSettingString: string): string => {
	const scheduleSetting: ScheduleSetting | undefined = getEnumFromStr(scheduleSettingString, ScheduleSetting);
	return scheduleSetting ? getScheduleSettingTranslation(scheduleSetting) : '';
};

export const getResourceTypeTranslation = (
	resourceType: ResourceType,
	caseType: 'nominative' | 'genitive' | 'partitive' = 'nominative'
): string => {
	switch (caseType) {
		case 'genitive':
			switch (resourceType) {
				case ResourceType.DRIVER:
					return 'Bussijuhi';
				case ResourceType.VEHICLE:
					return 'Bussi';
			}
			break;
		case 'partitive':
			switch (resourceType) {
				case ResourceType.DRIVER:
					return 'Bussijuhti';
				case ResourceType.VEHICLE:
					return 'Bussi';
			}
			break;
		default:
			switch (resourceType) {
				case ResourceType.DRIVER:
					return 'Bussijuht';
				case ResourceType.VEHICLE:
					return 'Buss';
			}
	}
};

export const getResourceTypeTranslationFromStr = (resourceTypeString: string): string => {
	const resourceType: ResourceType | undefined = getEnumFromStr(resourceTypeString, ResourceType);
	return resourceType ? getResourceTypeTranslation(resourceType) : '';
};

export const getWorkGroupItemTypeTranslation = (workGroupItemType: WorkGroupItemTypeWithCharterTrip): string => {
	switch (workGroupItemType) {
		case WorkGroupItemType.TRIP_DEFINITION:
			return 'Liinisõit';
		case WorkGroupItemTypeCharterTrip.CHARTER_TRIP:
			return 'Tellimusvedu';
		case WorkGroupItemType.EXTRA_TIME:
			return 'Lisaaeg';
		case WorkGroupItemType.PREPARATION_TIME:
			return 'Ettevalmistusaeg';
		case WorkGroupItemType.FINISHING_TIME:
			return 'Lõpetusaeg';
		case WorkGroupItemType.DISRUPTION:
			return 'Katkestus';
		case WorkGroupItemType.LUNCH_BREAK:
			return 'Lõuna';
		case WorkGroupItemType.DEADHEADING:
			return 'Tühisõit';
		case WorkGroupItemType.RESERVE:
			return 'Reserv';
		case WorkGroupItemType.TIME_BETWEEN_SHIFTS:
			return 'Vahetustevaheline aeg';
		case WorkGroupItemType.MAINTENANCE:
			return 'Remondiaeg';
	}
};

export const getPayTypeTranslation = (payType: PayType): string => {
	switch (payType) {
		case PayType.LINE_TIME:
			return 'Liiniaja tasu';
		case PayType.EXTRA_TIME:
			return 'Lisaaja tasu';
		case PayType.NIGHT_TIME:
			return 'Öötöö lisatasu';
		case PayType.DISRUPTION:
			return 'Katkestuse tasu';
		case PayType.RESERVE:
			return 'Reservitasu';
		case PayType.OVERTIME:
			return 'Ületunnitöö lisatasu';
		case PayType.UNDERTIME:
			return 'Alatunni tasu';
		case PayType.MAINTENANCE:
			return 'Remondiaja tasu';
		case PayType.HOLIDAY:
			return 'Riikliku püha lisatasu';
		case PayType.PARCEL_DELIVERY_FEE:
			return 'Pakiveo tasu';
		case PayType.EXTRA_FEE:
			return 'Preemia';
	}
};

export const getWorkGroupItemTypeTranslationFromStr = (workGroupItemTypeString: string): string => {
	const workGroupItemType: WorkGroupItemTypeWithCharterTrip | undefined = getEnumFromStr(workGroupItemTypeString, WorkGroupItemType) ?? getEnumFromStr(workGroupItemTypeString, WorkGroupItemTypeCharterTrip);
	return workGroupItemType ? getWorkGroupItemTypeTranslation(workGroupItemType) : '';
};

export const getWorkScheduleItemTypeTranslation = (workScheduleItemType: WorkScheduleItemType): string => {
	switch (workScheduleItemType) {
		case WorkScheduleItemType.WORK_GROUP:
			return 'Töögrupp';
		case WorkScheduleItemType.VACATION:
			return 'Puhkus';
		case WorkScheduleItemType.UNPAID_LEAVE:
			return 'Palgata puhkus';
		case WorkScheduleItemType.SICK_LEAVE:
			return 'Haiguspäev';
		case WorkScheduleItemType.TRAINING:
			return 'Koolitus';
		case WorkScheduleItemType.FREE_DAY_REQUEST:
			return 'Vaba päeva soov';
		case WorkScheduleItemType.WORK_REQUEST:
			return 'Tööle tulemise soov';
		case WorkScheduleItemType.SPECIAL_REQUEST:
			return 'Erisoov';
		case WorkScheduleItemType.MAINTENANCE:
			return 'Hooldus';
		case WorkScheduleItemType.REPAIR:
			return 'Remont';
		case WorkScheduleItemType.AWAY:
			return 'Eemal';
	}
}

export const getDefectPriorityTranslationFromStr = (defectPriorityString: string): string => {
	const defectPriorityType: DefectPriority | undefined = getEnumFromStr(defectPriorityString, DefectPriority);
	return defectPriorityType ? getDefectPriorityTranslation(defectPriorityType) : '';
};

export const getDefectPriorityTranslation = (defectPriorityType: DefectPriority): string => {
	switch (defectPriorityType) {
		case DefectPriority.CRITICAL:
			return 'Ei tohi kasutada';
		case DefectPriority.IMPORTANT:
			return 'Piirangutega kasutatav';
		case DefectPriority.LOW:
			return 'Ei takista teenindamist';
	}
}

export const getDefectStatusTranslationFromStr = (defectStatusString: string): string => {
	const defectStatusType: DefectStatus | undefined = getEnumFromStr(defectStatusString, DefectStatus);
	return defectStatusType ? getDefectStatusTranslation(defectStatusType) : '';
};

export const getDefectStatusTranslation = (defectPriorityType: DefectStatus): string => {
	switch (defectPriorityType) {
		case DefectStatus.NEW:
			return 'Uus';
		case DefectStatus.IN_PROGRESS:
			return 'Töös';
		case DefectStatus.SOLVED:
			return 'Lahendatud';
	}
}

export const getCharterTripStatusTranslation = (charterTripType: CharterTripStatus): string => {
	switch (charterTripType) {
		case CharterTripStatus.CONFIRMED:
			return 'Kinnitatud';
		case CharterTripStatus.CANCELLED:
			return 'Tühistatud';
		case CharterTripStatus.PLANNED:
			return 'Planeeritud';
		case CharterTripStatus.DONE:
			return 'Teostatud';
	}
}

export const getCharterTripZoneTranslationFromStr = (charterTripZoneString: string): string => {
	const charterTripZone: CharterTripZone | undefined = getEnumFromStr(charterTripZoneString, CharterTripZone);
	return charterTripZone ? getCharterTripZoneTranslation(charterTripZone) : '';
};

export const getCharterTripZoneTranslation = (charterTripZone: CharterTripZone): string => {
	switch (charterTripZone) {
		case CharterTripZone.DOMESTIC:
			return 'Riigisisene';
		case CharterTripZone.EU:
			return 'Ühendusesisene';
		case CharterTripZone.NON_EU:
			return 'Ühenduseväline';
	}
}

export const getWorkScheduleItemTypeTranslationFromStr = (workScheduleItemTypeString: string): string => {
	const workScheduleItemType: WorkScheduleItemType | undefined = getEnumFromStr(workScheduleItemTypeString, WorkScheduleItemType);
	return workScheduleItemType ? getWorkScheduleItemTypeTranslation(workScheduleItemType) : '';
};
