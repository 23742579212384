import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {ToastContext} from "../../../../contexts/ToastContext";
import {useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {BusForm} from "./types";
import {
    ApiError,
    BusDetails as ApiBusDetails,
    Fuel,
    OdometerReadingWithBusId,
    UpdateBusRequest
} from "../../../../API/types";
import * as Yup from "yup";
import {fuelCardNameAndPinRequired, validationSchema} from "../../../../utils/formValidation";
import routes from "../../../../routes";
import {deleteOdometerReading, loadBus, saveOdometerReading, updateOdometerReading} from "../../../../API";
import {Box} from "@mui/material";
import {Formik, FormikHelpers} from "formik";
import Loader from "../../../../components/Loader/Loader";
import BusDetailsEditForm from "./components/BusDetailsEditForm";
import {getFuelsFromStr, strToNr} from "../../../../utils/formUtils";
import {createBus, updateBus} from "../../../../API";
import {mapErrors} from "../../../../utils/errorMapping";
import EditReadingDialog, {EditReadingDialogData} from "./components/EditReadingDialog";
import AddReadingDialog, {AddReadingDialogData} from "./components/AddReadingDialog";
import {getOdometerReadingRequest, ReadingFormData} from "../../../../features/Form/ReadingForm/ReadingForm";
import {AddReadingFormData} from "./components/AddReadingForm";


const busValidationSchema = Yup.object().shape({
    licencePlateNumber: validationSchema('Registreerimisnumber').fields.shortTextFieldRequired,
    make: validationSchema('Mark').fields.shortTextFieldRequired,
    model: validationSchema('Mudel').fields.shortTextFieldRequired,
    type: validationSchema('Masinmark').fields.shortTextFieldRequired,
    seatingCapacity: validationSchema('Istekohtade arv').fields.integerTextFieldRequired,
    standingCapacity: validationSchema('Seisukohtade arv').fields.integerTextFieldRequired,
    registrationDate: validationSchema('Esmaregistreerimise kuupäev').fields.dateRequired,
    regions: validationSchema('Piirkond').fields.arrayRequired,
    accountingRegionId: validationSchema('Arvelduspiirkond').fields.numberRequired,
    fuelTypes: validationSchema('Kütusetüüp').fields.arrayRequired,
    fuelCards: fuelCardNameAndPinRequired('Kaardi nimi', 'PIN'),
});

const defaultBusFormValues: BusForm = {
    licencePlateNumber: '',
    make: '',
    model: '',
    type: '',
    seatingCapacity: '',
    standingCapacity: '',
    registrationDate: null,
    regions: [],
    accountingRegionId: null,
    fuelTypes: [],
    active: true,
    fuelCards: [],
};

const BusDetails = () => {
    const navigate = useNavigate();
    const { addToast } = useContext(ToastContext);
    const regions = useAppSelector(selectAllRegions);
    const { id } = useParams<{ id: string }>();
    const [bus, setBus] = useState<ApiBusDetails | undefined>(undefined);
    const [initialValues, setInitialValues] = useState<BusForm | undefined>(undefined);
    const [editReadingDialogData, setEditReadingDialogData] = useState<EditReadingDialogData | undefined>(undefined);
    const [addReadingDialogData, setAddReadingDialogData] = useState<AddReadingDialogData | undefined>(undefined);

    useEffect(() => {
        if (id === undefined) {
            setInitialValues(defaultBusFormValues);
        } else {
            loadBus(id)
                .then(result => {
                    setBus(result);
                })
                .catch(() => {
                    addToast({type: 'error', text: 'Bussi andmete pärimisel ilmnes viga'});
                    navigate(routes.AUTHENTICATED.BUSES.ROOT);
                });
        }
    }, [id]);

    const getInitialValues = (bus: ApiBusDetails | UpdateBusRequest): BusForm  => ({
        licencePlateNumber: bus.licencePlateNumber,
        make: bus.make,
        model: bus.model,
        type: bus.type,
        seatingCapacity: bus.seatingCapacity.toString(),
        standingCapacity: bus.standingCapacity.toString(),
        registrationDate: new Date(bus.registrationDate),
        regions: regions?.filter(region => bus.regionIds.includes(region.id)) ?? [],
        accountingRegionId: bus.accountingRegionId,
        fuelTypes: bus.fuelTypes,
        active: bus.active,
        fuelCards: bus.fuelCards,
    });

    useEffect(() => {
        if (bus) {
            setInitialValues(getInitialValues(bus));
        }
    }, [bus]);

    const handleFormSubmit = (form: BusForm) => {
        if (form.registrationDate && form.accountingRegionId) {
            const request: UpdateBusRequest = {
                licencePlateNumber: form.licencePlateNumber,
                make: form.make,
                model: form.model,
                type: form.type,
                seatingCapacity: strToNr(form.seatingCapacity),
                standingCapacity: strToNr(form.standingCapacity),
                registrationDate: form.registrationDate.toISOString(),
                fuelTypes: getFuelsFromStr(form.fuelTypes),
                active: form.active,
                regionIds: form.regions.map(region => region.id),
                accountingRegionId: form.accountingRegionId,
                fuelCards: form.fuelCards,
            };
            if (id) {
                updateBus(id, request)
                    .then(() => {
                        addToast({type: 'success', text: 'Bussi andmed edukalt uuendatud'});
                        setInitialValues(getInitialValues(request))
                    })
                    .catch(onSaveBusRejected);
            } else {
                createBus(request)
                    .then(() => {
                        addToast({type: 'success', text: 'Uus buss edukalt loodud'});
                        navigate(routes.AUTHENTICATED.BUSES.ROOT);
                    })
                    .catch(onSaveBusRejected);
            }
        }
    };

    const onSaveBusRejected = (apiError: ApiError) => {
        const errorMessage = mapErrors(apiError) ?? 'Bussi salvestamisel esines tõrge';
        addToast({type: 'error', text: errorMessage});
    };

    const openAddReadingDialog = () => {
        if (bus) setAddReadingDialogData({bus: bus});
    };
    const closeAddReadingDialog = () => setAddReadingDialogData(undefined);
    const handleSaveNewReading = (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>) => {
        if (bus) {
            helpers.setSubmitting(true);

            saveOdometerReading(bus.id, {driverId: formData.driverId, ...getOdometerReadingRequest(formData)})
                .then((result) => {
                    setBus({...bus, odometerReadings: [...bus.odometerReadings, result]});
                    setAddReadingDialogData(undefined);
                })
                .catch((apiError) => onOdometerRequestRejected(apiError, 'lisamisel', formData.fuelType))
                .finally(() => helpers.setSubmitting(false));
        }
    };

    const openEditReadingDialog = (reading: OdometerReadingWithBusId) => {
        if (bus) setEditReadingDialogData({reading: reading, bus: bus});
    };
    const closeEditReadingDialog = () => setEditReadingDialogData(undefined);
    const handleSaveEditedReading = (originalReading: OdometerReadingWithBusId, formData: ReadingFormData, helpers: FormikHelpers<ReadingFormData>) => {
        helpers.setSubmitting(true);
        const odometerReadingRequest = getOdometerReadingRequest(formData);

        updateOdometerReading(originalReading.id, getOdometerReadingRequest(formData))
            .then(() => {
                if (bus) {
                    setBus({
                        ...bus,
                        odometerReadings: [
                            ...bus.odometerReadings.filter(reading => reading.id !== originalReading.id),
                            {...originalReading, ...odometerReadingRequest}
                        ]
                    });
                }
                setEditReadingDialogData(undefined);
            })
            .catch((apiError) => onOdometerRequestRejected(apiError, 'muutmisel', formData.fuelType))
            .finally(() => helpers.setSubmitting(false));
    };

    const handleDeleteReading = (deletedReading: OdometerReadingWithBusId) => {
        if (bus && confirm(`Kas oled kindel, et soovid ${deletedReading.fuelAmount ? 'tankimise' : 'näidu'} kustutada?`)) {
            deleteOdometerReading(deletedReading.id)
                .then(() => setBus({
                    ...bus, odometerReadings: bus.odometerReadings.filter(reading => reading.id !== deletedReading.id)
                }))
                .catch((apiError) => onOdometerRequestRejected(apiError, 'kustutamisel', deletedReading.fuelType));
        }
    };

    const onOdometerRequestRejected = (apiError: ApiError, activityType: 'lisamisel' | 'muutmisel' | 'kustutamisel', fuelType?: Fuel | string | null) => {
        const errorMessage = mapErrors(apiError) ?? ` ${fuelType ? 'Tankimise' : 'Näidu'} ${activityType} esines tõrge`;
        addToast({type: 'error', text: errorMessage});
    };

    return (initialValues ?
            <Box p={{xs: 1, sm: 0}}>
                <Formik enableReinitialize initialValues={initialValues} validationSchema={busValidationSchema} onSubmit={handleFormSubmit}>
                    <BusDetailsEditForm
                        bus={bus}
                        id={id}
                        defaultFormValues={defaultBusFormValues}
                        handleAddOdometerReading={openAddReadingDialog}
                        handleEditOdometerReading={openEditReadingDialog}
                        handleDeleteOdometerReading={handleDeleteReading}
                    />
                </Formik>
                {editReadingDialogData &&
					<EditReadingDialog
						dialogData={editReadingDialogData}
						handleCloseDialog={closeEditReadingDialog}
						handleSave={handleSaveEditedReading}
					/>
                }
                {addReadingDialogData &&
					<AddReadingDialog
						dialogData={addReadingDialogData}
						handleCloseDialog={closeAddReadingDialog}
						handleSave={handleSaveNewReading}
					/>
                }
            </Box>
            :
            <Loader />
    );
};

export default BusDetails;
