import React from "react";
import Dialog from "@mui/material/Dialog";
import {Box} from "@mui/material";
import {FormikHelpers} from "formik";
import {validateReadingWithPrevious} from "../../../../../features/Form/ReadingForm/utils";
import AddReadingForm, {AddReadingFormData} from "./AddReadingForm";
import {BusDetails} from "../../../../../API/bus/types";


export interface AddReadingDialogData {
    bus: BusDetails;
}

interface AddReadingDialogProps {
    dialogData: AddReadingDialogData;
    handleCloseDialog: () => void;
    handleSave: (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>) => void;
}

const AddReadingDialog = ({dialogData, handleCloseDialog, handleSave}: AddReadingDialogProps) => {
    const handleSubmit = (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>) => {
        if (!validateReadingWithPrevious(dialogData.bus.odometerReadings, formData)) {
            helpers.setSubmitting(false);
            return;
        }

        handleSave(formData, helpers);
    };

    return (
        <Dialog open={true} onClose={handleCloseDialog} maxWidth="md">
            <Box p={{xs: 2, sm: 3}}>
                {dialogData &&
                    <AddReadingForm
                        bus={dialogData.bus}
                        handleCloseDialog={handleCloseDialog}
                        handleSubmit={handleSubmit}
                    />
                }
            </Box>
        </Dialog>
    );
};

export default AddReadingDialog;
