import {Box, Tooltip, useMediaQuery} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import {getTimeString} from "../../../utils/dateUtils";
import React from "react";
import {Dayjs} from "dayjs";
import {Theme} from "@mui/material/styles";
import Button from "../../Button/Button";

export interface DataRefreshProps {
    refreshTime: Dayjs | null,
    handleRefreshed: () => void,
    height?: number;
}

const DataRefresh = ({refreshTime, handleRefreshed, height = 30}: DataRefreshProps) => {
    const showLabel = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Tooltip title={refreshTime && `Värskendatud ${getTimeString(refreshTime)}`} placement="top" arrow>
            <Box>
                <Button
                    onClick={handleRefreshed}
                    size="small"
                    color="secondary"
                    styles={{
                        height: `${height}px`,
                        lineHeight: 'normal',
                        color: 'secondary.main',
                        borderColor: 'secondary.main'
                    }}
                    startIcon={<Refresh fontSize="small" />}
                    showLabel={showLabel}
                    text="värskenda"
                />
            </Box>
        </Tooltip>
    );
};

export default DataRefresh;