import React  from 'react';
import {InputAdornment, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps} from '@mui/material';
import {useField} from 'formik';
import StaticIcon from "../../Icon/StaticIcon";


interface NumberTextFieldProps extends Omit<MuiTextFieldProps, 'name' | 'label'> {
    name: string;
    label: string;
    length?: number;
    decimals?: number;
    helperText?: string;
    description?: string;
}

const NumberTextField = ({name, label, length, decimals, helperText, description, ...otherProps}: NumberTextFieldProps) => {
    const [field, meta, helpers] = useField(name);
    const numberRegex = /^(0|[1-9]\d*|)$/;
    const decimalRegex = /^([0-9]\d*|)$/;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputStr = event.target.value.replace('.', ',');
        if ((!length || (length && inputStr.length <= length))) {
            if (decimals && inputStr.includes(',') && !inputStr.includes(',,')) {
                const parts = inputStr.split(',');
                if (
                    numberRegex.test(parts[0]) && decimalRegex.test(parts[1])
                    && (parts[1] === '' || parts[1].length <= decimals) && parts.length <= 2
                ) {
                    void helpers.setValue(inputStr);
                }
            } else {
                if (numberRegex.test(inputStr)) {
                    void helpers.setValue(inputStr);
                }
            }
        }
    };

    const getHelperText = (): string | undefined => {
        if (meta.touched && meta.error) {
            return meta.error;
        } else {
            return helperText;
        }
    };

    return (
        <MuiTextField
            value={field.value.replace('.', ',')}
            label={label}
            size={otherProps.size ?? 'small'}
            error={meta.touched && !!meta.error}
            helperText={getHelperText()}
            onBlur={() => helpers.setTouched(true)}
            onChange={(event) => handleChange(event)}
            InputProps={
                description ? {
                    endAdornment:
                        <InputAdornment position="end" sx={{mr: -1.5, cursor: 'default'}}>
                            <StaticIcon type="INFO" tooltipTitle={description} size="medium" />
                        </InputAdornment>
                } : undefined
            }
            sx={{width: '100%', my: 1}}
            disabled={otherProps.disabled}
            name={name}
        />
    );
};

export default NumberTextField;