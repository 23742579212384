import React from "react";
import Dialog from "@mui/material/Dialog";
import {Box} from "@mui/material";
import {FormikHelpers} from "formik";
import {WorkSheetDetails} from "../../../../../../API/workSheets/types";
import {getDisplayDateTimeRange} from "../../../../../../utils/dateUtils";
import {BusUsageWithDistance} from "../../../../dashboard/DriverDashboard/types";
import {isReadingWithinBusUsage, validateReadingWithPrevious} from "../../../../../../features/Form/ReadingForm/utils";
import AddReadingForm, {AddReadingFormData} from "../odometerReadings/AddReadingForm";
import {OdometerReadingRow} from "../odometerReadings/OdometerReadings";


export interface AddReadingDialogData {
    workSheet: WorkSheetDetails;
    busUsages: BusUsageWithDistance[];
    odometerReadings: OdometerReadingRow[];
    driverId: number;
}

interface AddReadingDialogProps {
    dialogData: AddReadingDialogData;
    handleCloseDialog: () => void;
    handleSave: (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>, bus: number) => void;
}

const AddReadingDialog = ({dialogData, handleCloseDialog, handleSave}: AddReadingDialogProps) => {
    const firstBusUsage: BusUsageWithDistance | undefined = dialogData.busUsages.length > 0 ? dialogData.busUsages[0] : undefined;

    const handleSubmit = (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>, busUsage: BusUsageWithDistance) => {
        const readingType = formData.fuelType ? 'Tankimine' : 'Odomeetri näit';
        const busId = busUsage.busId;

        if (!isReadingWithinBusUsage(formData.dateTime, busUsage)) {
            if (!confirm(`${readingType} on väljaspool bussiga seotud tööde toimumisaega ${getDisplayDateTimeRange(busUsage)}. Kas soovid selle siiski salvestada?`)) {
                helpers.setSubmitting(false);
                return;
            }
        }

        const readingsOfTheSelectedBus = dialogData.workSheet.odometerReadings.filter(reading => reading.busId === busId);
        if (!validateReadingWithPrevious(readingsOfTheSelectedBus, {...formData, dateTime: formData.dateTime.toDate()})) {
            helpers.setSubmitting(false);
            return;
        }

        handleSave(formData, helpers, busId);
    };

    return (
        <Dialog open={true} onClose={handleCloseDialog} maxWidth="md">
            <Box p={{xs: 2, sm: 3}}>
                {dialogData && firstBusUsage &&
                    <AddReadingForm
						dialogData={dialogData}
                        firstBusUsage={firstBusUsage}
                        handleCloseDialog={handleCloseDialog}
                        handleSubmit={handleSubmit}
                    />
                }
            </Box>
        </Dialog>
    );
};

export default AddReadingDialog;
