import React from "react";
import Dialog from "@mui/material/Dialog";
import {Box, DialogActions, DialogContent, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {getResourceTypeTranslation} from "../../../../../utils/enumTranslations";
import {Form, Formik, FormikProps} from "formik";
import Button from "../../../../../components/Button/Button";
import {Check, Clear} from "@mui/icons-material";
import TextField from "../../../../../components/Form/TextField/TextField";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {clearDialogData, copyOppositeWorkGroup, selectDialogData} from "../../../../../store/plannerSlice";
import * as Yup from "yup";
import {validationSchema} from "../../../../../utils/formValidation";
import {getOppositeResourceType} from "../../../../../utils/utils";
import {isEqual} from "lodash";
import Checkbox from "../../../../../components/Form/Checkbox/Checkbox";

const copyOppositeWorkGroupValidationSchema = Yup.object().shape({
    code: validationSchema('Töögrupi kood').fields.shortTextFieldRequired,
    comment: validationSchema('Kommentaar').fields.longTextField,
});

interface CopyOppositeWorkGroupData {
    code: string;
    comment: string;
    linkWithOriginal: boolean;
}

const CopyOppositeWorkGroup = () => {
    const dispatch = useAppDispatch();
    const originalWorkGroup = useAppSelector(selectDialogData).copyOppositeWorkGroup?.workGroup;

    if (!originalWorkGroup) {
        return null;
    }

    const oppositeResourceType = getOppositeResourceType(originalWorkGroup.type);

    const defaultValues: CopyOppositeWorkGroupData = {
        code: originalWorkGroup.code,
        comment: originalWorkGroup.comment ?? '',
        linkWithOriginal: false,
    };

    const handleClose = () => {
        dispatch(clearDialogData());
    };
    const handleFormSubmitClick = (form: CopyOppositeWorkGroupData) => {
        dispatch(copyOppositeWorkGroup({
            originalWorkGroupId: originalWorkGroup.id,
            data: {
                ...form
            },
        }));
        handleClose();
    };

    const areCodesEqual = (formikProps: FormikProps<CopyOppositeWorkGroupData>) =>
        isEqual(formikProps.initialValues.code, formikProps.values.code);

    return (
        <Dialog open={true} onClose={handleClose}>
            <Box sx={{width: '400px', maxWidth: '100%', mt: 1, p: {xs: 0, sm: 3}}}>
                <DialogTitle>
                    <Typography variant="h5" component="div">
                        {getResourceTypeTranslation(oppositeResourceType, 'genitive')} töögrupi lisamine
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary">
                        {getResourceTypeTranslation(oppositeResourceType, 'genitive')} töögrupp luuakse {getResourceTypeTranslation(originalWorkGroup.type, 'genitive').toLowerCase()} töögrupi {originalWorkGroup.code} reeglite ja tegevustega
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={defaultValues}
                    validationSchema={copyOppositeWorkGroupValidationSchema}
                    onSubmit={handleFormSubmitClick}
                >
                    {formikProps =>
                        <Form>
                            <DialogContent sx={{pt: 0}}>
                                <TextField
                                    name="code"
                                    label="Kood*"
                                    fullWidth
                                    helperText={areCodesEqual(formikProps) ? 'Sisesta uus kood' : undefined}
                                />
                                <TextField name="comment" label="Kommentaar" multiline minRows={2} maxRows={5} fullWidth />
                                <Checkbox name="linkWithOriginal" label="Seo valitud töögrupiga" />
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%'}}>
                                <Box maxWidth="50%">
                                    <Button
                                        text="Loobu"
                                        variant="outlined"
                                        onClick={handleClose}
                                        startIcon={<Clear />}
                                    />
                                </Box>
                                <Box maxWidth="50%">
                                    <Button
                                        text="Salvesta"
                                        type="submit"
                                        startIcon={<Check />}
                                        disabled={areCodesEqual(formikProps)}
                                    />
                                </Box>
                            </DialogActions>
                        </Form>
                    }
                </Formik>
            </Box>
        </Dialog>
    );
};

export default CopyOppositeWorkGroup;
