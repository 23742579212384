import {ActiveFilterChipType, AuthenticatedUser, DateLimitFilterChipType, Permission} from "../types";
import dayjs, {Dayjs} from "dayjs";
import {GeoPoint, Region, ResourceType, RoutePoint} from "../API/types";
import {RoutePointForm} from "../scenes/authenticated/trips/detailsView/types";

export function filterByValue<T extends { [key: string]: any }>(arr: T[], searchString: string): T[] {
    return arr.filter((obj) => Object.values(obj).some(
        (value) => typeof value === 'string' && value.toLowerCase().includes(searchString.toLowerCase())
    ));
}

export const areBothOrNeitherActiveChipsSelected = (activeChips: (ActiveFilterChipType | string)[]) => {
    const includeActive = activeChips.includes('Aktiivsed');
    const includeInactive = activeChips.includes('Mitteaktiivsed');

    return (includeActive && includeInactive) || (!includeActive && !includeInactive);
};

export function filterByActive<T extends { [key: string]: any }>(array: T[], activeChips: (ActiveFilterChipType | string)[]): T[] {
    return areBothOrNeitherActiveChipsSelected(activeChips) ? array : array.filter((obj) => obj.active === activeChips.includes('Aktiivsed'));
}

export function filterByEndDate<T extends { validTo?: string | null }>(array: T[], activeChips: (DateLimitFilterChipType | string)[]): T[] {
    const includeOngoingItems = activeChips.includes('Käigus');
    const includePastItems = activeChips.includes('Möödunud');

    if ((includeOngoingItems && includePastItems) || (!includeOngoingItems && !includePastItems)) return array;

    return includeOngoingItems
        ? array.filter(row => !row.validTo || !dayjs(row.validTo).isBefore(dayjs()))
        : array.filter(row => row.validTo && dayjs(row.validTo).isBefore(dayjs()));
}

export function sortByStartDate<T extends { startDate: string | null }>(a: T, b: T) {
    if (a.startDate === null || b.startDate === null) {
        return 0;
    }

    return (new Date(a.startDate)).getTime() - (new Date(b.startDate)).getTime();
}

export function sortByStartTime<T extends { startTime: string, startTimeIsOnNextDay: boolean }>(a: T, b: T) {
    if (a.startTimeIsOnNextDay && !b.startTimeIsOnNextDay) {
        return 1;
    }
    if (!a.startTimeIsOnNextDay && b.startTimeIsOnNextDay) {
        return -1;
    }
    if (a.startTime === b.startTime) {
        return 0;
    }

    return a.startTime > b.startTime ? 1 : -1;
}

export function isOnWeekend(date: Dayjs|Date|string): boolean {
    const dayjsDate = dayjs(date);

    return dayjsDate.day() === 0 || dayjsDate.day() === 6; // 0 is Sunday, 6 is Saturday
}

export const isToday = (date: Dayjs | Date | string) => {
    const dayjsDate = dayjs(date);
    const today = dayjs();

    return dayjsDate.isSame(today, 'day');
};

export const roundToDecimals = (number: number, decimals: number): number => {
    return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const strToDecimal = (value: string) => Number(value.replace(',', '.'));

export const decimalToStr = (value: number) => value.toString().replace('.', ',');

export const decimalToDisplayStr = (value?: number | null) => {
    return value !== null && value !== undefined && value >= 0 ? value.toLocaleString('et-EE') : '';
};

export const decimalToFixedDisplayStr = (value?: number | null, decimals: number = 2) => {
    return value !== null && value !== undefined ? value.toLocaleString('et-EE', {maximumFractionDigits: decimals}) : '';
};

export const stringToEnumPermissionMap: { [key: string]: Permission } = {
    'read:users': Permission.ReadUsers,
    'update:users': Permission.UpdateUsers,
    'list:buses': Permission.ListBuses,
    'read:buses': Permission.ReadBuses,
    'update:buses': Permission.UpdateBuses,
    'update:odometer-readings': Permission.UpdateOdometerReadings,
    'read:regions': Permission.ReadRegions,
    'update:regions': Permission.UpdateRegions,
    'list:drivers': Permission.ListDrivers,
    'read:drivers': Permission.ReadDrivers,
    'update:drivers': Permission.UpdateDrivers,
    'read:calendar-entries' : Permission.ReadCalendarEntries,
    'update:calendar-entries': Permission.UpdateCalendarEntries,
    'list:transport-contracts': Permission.ListTransportContracts,
    'read:transport-contracts': Permission.ReadTransportContracts,
    'update:transport-contracts': Permission.UpdateTransportContracts,
    'list:trips': Permission.ListTrips,
    'read:trips': Permission.ReadTrips,
    'update:trips': Permission.UpdateTrips,
    'delete:trips': Permission.DeleteTrips,
    'read:charter-trips': Permission.ReadCharterTrips,
    'update:charter-trips': Permission.UpdateCharterTrips,
    'read:work-groups': Permission.ReadWorkGroups,
    'update:work-groups': Permission.UpdateWorkGroups,
    'list:work-schedules': Permission.ListWorkSchedules,
    'read:work-schedules': Permission.ReadWorkSchedules,
    'update:work-schedules': Permission.UpdateWorkSchedules,
    'delete:work-schedules': Permission.DeleteWorkSchedules,
    'read:payroll': Permission.ReadPayroll,
    'update:payroll': Permission.UpdatePayroll,
    'read:defects': Permission.ReadDefects,
    'list:defects': Permission.ListDefects,
    'update:defects': Permission.UpdateDefects,
    'read:avl-assignments': Permission.ReadAvlAssignments,
    'update:avl-assignments': Permission.UpdateAvlMessages,
    'read:trip-occurrences': Permission.ReadTripOccurrences,
    'read:reports': Permission.ReadReports,
};

export const getStartPoint = (route: RoutePoint[] | string | null): {name: string} | undefined => {
    if (typeof route === 'string') {
        const name = route.split('-')?.at(0);
        return name ? {name} : undefined;
    }

    return route?.at(0);
};

export const getEndPoint = (route: RoutePoint[] | string | null) => {
    if (typeof route === 'string') {
        const name = route.split('-')?.pop();
        return name ? {name} : undefined;
    }

    return route && route.length > 1 ? route.at(route.length - 1) : undefined;
};

export const getStartAndEndPoint = (route: RoutePoint[] | string | null) => ({
    startPoint: getStartPoint(route),
    endPoint: getEndPoint(route)
});

export const getStartAndEndPointLabel = (route: RoutePoint[] | string | null): string => {
    const {startPoint, endPoint} = getStartAndEndPoint(route);

    if (startPoint && endPoint) return `${startPoint.name} - ${endPoint.name}`;
    if (startPoint) return startPoint.name;
    if (endPoint) return endPoint.name;

    return '';
};

export const doesItemCodeOrRouteMatchFilterInput = (filterInput: string, code?: string, route?: RoutePoint[] | string | null) => {
    const startAndEndPoint = route ? getStartAndEndPoint(route) : undefined;

    return code?.toLowerCase().includes(filterInput.toLowerCase())
        || startAndEndPoint?.startPoint?.name.toLowerCase().includes(filterInput)
        || startAndEndPoint?.endPoint?.name.toLowerCase().includes(filterInput)
};

export const getOppositeResourceType = (resourceType: ResourceType): ResourceType => {
    return resourceType === ResourceType.DRIVER ? ResourceType.VEHICLE : ResourceType.DRIVER;
};

export const dateRangeToArrayOfDays = (start: string|Date|Dayjs, end: string|Date|Dayjs): Dayjs[] => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    return [...Array(1 + endDate.diff(startDate, 'days'))]
        .map((_, index) => startDate.add(index, 'day'));
};

export const getRegionsDisplayStr = (regionIds: number[], regions: Region[]) => {
    const selectedRegions = regions?.filter(region => regionIds.includes(region.id)) ?? [];
    return selectedRegions.map(region => region.name).join(', ');
};

export const getOtherRegionId = (regionId: number | null, selectedRegion: Region | undefined) => {
    return (selectedRegion && regionId !== null && regionId !== selectedRegion.id) ? regionId : null;
};
export const getNumberWithSign = (number: number): string => `${number > 0 ? '+' : ''}${number}`;

export const correspondsWith = (routePointForm: RoutePointForm, geoPoint: GeoPoint) => {
    if (routePointForm.geoPoint?.id) {
        return geoPoint.id === routePointForm.geoPoint?.id;
    } else {
        return !geoPoint.stopCode && geoPoint.name === routePointForm.geoPoint?.name;
    }
};

export function listsHaveAnyOverlap<T>(list1: T[], list2: T[]): boolean {
    return list1.some(list1Element => list2.includes(list1Element));
}

export function filterByRegionsForUser<T extends {regionIds: number[]}>(list: T[], user: AuthenticatedUser): T[] {
    if (user.regionalFilteringApplies) {
        const userRegionIds = user.regions.map(region => region.id);
        return list.filter(item => listsHaveAnyOverlap(item.regionIds, userRegionIds));
    }
    return list;
}

export function filterByRegionNameForUser<T extends {regionName: string}>(list: T[], user: AuthenticatedUser): T[] {
    if (user.regionalFilteringApplies) {
        const userRegionNames = user.regions.map(region => region.name);
        return list.filter(item => userRegionNames.includes(item.regionName));
    }
    return list;
}

export const userCanEditResource = (user?: AuthenticatedUser | null, resource?: {regionIds: number[]} | null) => {
    return (user?.regionalFilteringApplies && resource)
        ? listsHaveAnyOverlap(resource?.regionIds, user.regions.map(region => region.id))
        : true;
};

export const getRegionChoicesForUser = (
    regions: Region[],
    user?: AuthenticatedUser | null,
    resource?: { regionIds: number[], accountingRegionId: number },
): [regionChoices: Region[], accountingRegionChoices: Region[]] => {
    if (user?.regionalFilteringApplies !== true) {
        return [regions, regions]
    }

    const resourceRegions = regions.filter(region => resource?.regionIds && resource.regionIds.includes(region.id));
    const accountingRegion = regions.find(region => region.id === resource?.accountingRegionId);
    const filteredRegions = user?.regionalFilteringApplies ? user.regions : regions;
    const regionChoices = [...filteredRegions, ...resourceRegions.filter(region => !filteredRegions.map(region => region.id).includes(region.id))];
    const accountingRegionChoices = accountingRegion ? [...filteredRegions.filter(region => region.id !== accountingRegion.id), accountingRegion] : filteredRegions;

    return [regionChoices, accountingRegionChoices];
};

export const getEnvironmentFromUrl = (): string => {
    switch (window.location.hostname) {
        case 'viiger.gobus.ee':
            return 'production';
        case 'test.viiger.gobus.ee':
            return 'test';
        default:
            return 'development';
    }
};