import {Box} from "@mui/material";
import {Dayjs} from "dayjs";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import theme from "../../../../theme";
import {TimeField, TimeFieldProps} from "@mui/x-date-pickers";

interface FilterTimeFieldProps extends Omit<TimeFieldProps<Date>, 'name' | 'value' | 'onChange' | 'views'> {
    value: Dayjs | null;
    handleValueChanged: (value: Dayjs | null) => void;
    label: string;
}

export const FilterTimeField = ({value, handleValueChanged, label}: FilterTimeFieldProps) => {
    return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="et">
        <Box sx={{width: {xs: '50%', md: 150}}}>
            <TimeField
                label={label}
                value={value}
                onChange={handleValueChanged}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    width: '100%',
                    '.MuiInputBase-root': {
                        height: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 8px',
                    },
                    '.MuiInputBase-input': {
                        px: 0.5,
                    },
                }}
                slotProps={{
                    textField: { size: 'small' },
                }}
                InputLabelProps={{shrink: true}}
            />
        </Box>
    </LocalizationProvider>
};