import {RoutePoint} from "../../../API/types";
import {TripOccurrence} from "../../../API/trips/types";

const routeToString = (route: RoutePoint[] | null) => {
    if (route === null) {
        return '';
    }
    const sortedRoutePoints = route.toSorted((a, b) => a.order - b.order);

    return sortedRoutePoints.map(item => item.name).join(" - ");
}

const getResourceSearchString = (row: TripOccurrence) => {
    const driverNames: Set<string> = new Set();
    const driverPhones: Set<string> = new Set();
    const licensePlateNumbers: Set<string> = new Set();
    row.segments.map(segment => {
        const driver = segment.driverWorkSheet?.driver;
        if (driver) {
            driverNames.add(driver.fullName);
            driverPhones.add(driver.phoneNumber);
        }
        const licensePlateNumber = segment.busWorkSheet?.licensePlateNumber;
        if (licensePlateNumber) {
            licensePlateNumbers.add(licensePlateNumber);
        }
    });

    return [...driverNames, ...driverPhones, ...licensePlateNumbers].join(' ');
}

export const occurrenceToRow = (occurrence: TripOccurrence) => {
    return {
        id: occurrence.tripId,
        routeString: routeToString(occurrence.route),
        resourcesSearchString: getResourceSearchString(occurrence),
        ...occurrence
    };
};

const strToSearchStr = (str: string | undefined | null): string => (str ?? '').toLowerCase();
export const stringIncludes = (str: string | undefined | null, searchStr: string): boolean => strToSearchStr(str).includes(searchStr.toLowerCase());