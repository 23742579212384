import React, {ReactNode, useEffect} from 'react';
import {Box, styled, SxProps, useMediaQuery} from '@mui/material';
import {
    DataGrid as MuiDataGrid,
    DataGridProps as MuiDataGridProps,
    GridColDef,
    GridSortingInitialState
} from '@mui/x-data-grid';
import theme from "../../theme";
import {blue} from "@mui/material/colors";
import {useLocation} from "react-router";
import {useFindAndGoToPageOfItem} from "../../hooks";
import {useNavigate} from "react-router-dom";
import {DEFAULT_DISPLAY_TIMOUT} from "../../constants";
import LoaderWithChildren from "../Loader/LoaderWithChildren";


export const DEFAULT_COL_DEF_PROPS = {
    sortable: true,
    filterable: false,
    disableColumnMenu: true,
};

export const DEFAULT_MENU_COL_DEF_PROPS: GridColDef = {
    field: 'Menüü',
    headerName: '',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    align: 'right',
    flex: 1,
    minWidth: 50,
};

export const StyledDataGrid = styled(MuiDataGrid)(({theme}) => ({
    overflowX: 'scroll',
    backgroundColor: theme.palette.background.paper,
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '.MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
    },

    // editable data grid props
    '& .MuiSelect-select, & .MuiInputBase-input': {
        fontSize: '14px',
        height: '20px',
        padding: '2px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    '& .MuiInputBase-root': {
        marginRight: '10px'
    },
}));

interface DataGridProps extends MuiDataGridProps {
    enableMultiRowCells?: boolean;
    sorting?: GridSortingInitialState;
    sx?: SxProps;
    filterToolbar?: ReactNode;
    isLoading?: boolean;
}

export default function DataGrid({rows, columns, enableMultiRowCells, sorting, editMode, sx, filterToolbar, isLoading, ...props}: DataGridProps) {
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const navigate = useNavigate();
    const findAndGoToPageOfItem = useFindAndGoToPageOfItem();

    const highlightedId: number | undefined = location.state?.highlightId;

    useEffect(() => {
        if (findAndGoToPageOfItem && highlightedId && rows.length > 0) {
            findAndGoToPageOfItem(rows, highlightedId);
            setTimeout(() => navigate(location.pathname, {replace: true}), DEFAULT_DISPLAY_TIMOUT);
        }
    }, [highlightedId]);

    return (
        <Box sx={{flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
            {filterToolbar}
            <LoaderWithChildren loading={isLoading ?? false}>
                <Box sx={{overflow: 'hidden'}}>
                    <StyledDataGrid
                        rows={rows}
                        editMode={editMode}
                        columns={columns}
                        columnHeaderHeight={40}
                        pageSizeOptions={[10, 25, 50, 100]}
                        disableRowSelectionOnClick
                        disableColumnSelector
                        getRowHeight={() => enableMultiRowCells ? 'auto' : isScreenSmall ? 32 : 35}
                        initialState={{
                            pagination: {paginationModel: { page: 0, pageSize: isScreenSmall ? 10 : 25 }},
                            sorting: sorting,
                        }}
                        slotProps={{noRowsOverlay: { style: { borderTop: '10px solid #ffffff' } }}}
                        getRowClassName={(params) =>
                            params.id.toString() === highlightedId?.toString() ? 'MuiDataGrid-row--highlight' : ''
                        }
                        {...props}
                        sx={{
                            '& .MuiDataGrid-row--highlight': {backgroundColor: blue[50]},
                            '& .MuiDataGrid-cell': enableMultiRowCells ? {pt: 1, alignItems: 'flex-start'} : {},
                            ...sx,
                        }}
                    />
                </Box>
            </LoaderWithChildren>
        </Box>
    );
}
