import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box} from "@mui/material";
import routes from "../../../../routes";
import {ToastContext} from "../../../../contexts/ToastContext";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {WorkGroup, WorkGroupVersion} from "../../../../API/types";
import Loader from "../../../../components/Loader/Loader";
import WorkGroupItems from "./components/WorkGroupItems";
import WorkGroupEditFormWrapper from "./components/WorkGroupEditFromWrapper";
import {findClosestItemByValidRange} from "../../../../utils/dateUtils";
import {sortByStartTime} from "../../../../utils/utils";
import {deleteWorkGroup, loadWorkGroupDetails as apiLoadWorkGroupDetails} from "../../../../API";
import TextButton from "../../../../components/Button/TextButton";
import {Delete} from "@mui/icons-material";
import {setToast} from "../../../../store/toastSlice";
import {useAppDispatch} from "../../../../hooks";


export default function WorkGroupDetails() {
    const { id } = useParams<{ id: string }>();
    const { addToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const [workGroup, setWorkGroup] = useState<WorkGroup | null | undefined>(undefined);
    const [selectedVersion, setSelectedVersion] = useState<WorkGroupVersion | undefined>(undefined);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (id) {
            loadWorkGroupDetails(id);
        } else {
            setWorkGroup(null);
        }
    }, [id]);

    const loadWorkGroupDetails = (id: string) => {
        apiLoadWorkGroupDetails(id)
            .then(result => {
                setWorkGroup(result);
                setSelectedVersion(findClosestItemByValidRange(result?.versions ?? []));
            })
            .catch(() => {
                addToast({type: 'error', text: 'Töögrupi pärimisel ilmnes viga'});
                navigate(routes.AUTHENTICATED.WORK_GROUPS.ROOT);
            });
    };

    const handleSuccessfulEdit = (workGroup: WorkGroup) =>
        setWorkGroup(prevState => ({...prevState, ...workGroup}));

    const handleSuccessfulCreate = () => navigate(routes.AUTHENTICATED.WORK_GROUPS.ROOT);

    const handleDeleteClick = () => {
        if (id && confirm("Kas oled kindel, et soovid töögrupi kustutada?")) {
            deleteWorkGroup(id)
                .then(() => {
                    dispatch(setToast({type: 'success', text: 'Töögrupp edukalt kustutatud'}));
                    navigate(routes.AUTHENTICATED.WORK_GROUPS.ROOT);
                })
                .catch((apiError) => {
                    dispatch(setToast({type: 'error', text: apiError.message ?? 'Töögrupi kustutamisel ilmnes viga'}));
                });
        }
    };

    const canDelete = useMemo(() => {
        return workGroup
            && !workGroup.linkedWorkGroup
            && !workGroup.hasWorkSheet
            && workGroup.tripDefinitions.length === 0
            && workGroup.activities.length === 0;
    }, [workGroup]);

    return (workGroup !== undefined ?
        <Box p={{xs: 1, sm: 0}}>
            <WorkGroupEditFormWrapper
                workGroup={workGroup}
                handleSuccessfulEdit={handleSuccessfulEdit}
                handleSuccessfulCreate={handleSuccessfulCreate}
                selectedVersion={selectedVersion}
                setSelectedVersion={setSelectedVersion}
            />
            {canDelete &&
                <TextButton text="Kustuta töögrupp" startIcon={<Delete />} onClick={handleDeleteClick} styles={{mt: 1}} />
            }
            <WorkGroupItems
                items={selectedVersion ? [...selectedVersion.tripDefinitions, ...selectedVersion.activities].sort(sortByStartTime) : []}
            />
        </Box>
        :
        <Loader />
    );
}