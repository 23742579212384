import {Box, SxProps} from "@mui/material";
import React from "react";

interface EllipsisTextDisplayFieldProps {
    value: string;
    titleValue?: string;
    sx?: SxProps;
}

export const EllipsisTextDisplayField = ({value, titleValue = value, sx}: EllipsisTextDisplayFieldProps) => {
    return(
        <Box
            sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                ...sx
            }}
            title={titleValue}
        >
            {value}
        </Box>
    );
}