import {Alert} from "@mui/material";
import React, {useMemo} from "react";
import {ResourceType} from "../../../../../../API/workGroup/types";
import {OdometerReadingRow} from "../odometerReadings/OdometerReadings";
import {WorkSheetDetails} from "../../../../../../API/types";


interface DistanceDifferenceWarningProps {
    workSheet: WorkSheetDetails;
    odometerReadingRows: OdometerReadingRow[];
}

const DistanceDifferenceWarning = ({workSheet, odometerReadingRows}: DistanceDifferenceWarningProps) => {
    const distanceDifferenceWarning = useMemo(() => {
        if (workSheet.resourceType === ResourceType.VEHICLE) return false;

        for (const row of odometerReadingRows) {
            if (row.distanceDiff && Math.abs(row.distanceDiff ?? 0) > 1) return true;
        }

        return false;
    }, [workSheet, odometerReadingRows]);

    return (distanceDifferenceWarning &&
        <Alert
            severity="warning"
            variant="outlined"
            sx={{mb: 1, minWidth: '200px', '&:not(:last-of-type)': {mr: {sm: 1}}}}
        >
            Juhi sisestatud lõppnäit erineb planeeritud läbisõidust
        </Alert>
    );
};

export default DistanceDifferenceWarning;