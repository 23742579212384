import InputSearch from "../../../../components/FilterToolbar/components/InputSearch";
import {Dayjs} from "dayjs";
import ToolbarContainer from "../../../../components/FilterToolbar/components/ToolbarContainer";
import {Box} from "@mui/material";
import DateNavigator from "../../../../components/DateNavigator/DateNavigator";
import DataRefresh from "../../../../components/FilterToolbar/components/DataRefresh";
import React from "react";
import RegionsSearch from "../../../../components/FilterToolbar/components/RegionsSearch";
import {FilterTimeField} from "./FilterTimeField";
import {FilterTextField} from "./FilterTextField";
import {SearchCriteria} from "../types";
import FilterChip, {FilterChipProps} from "../../../../components/FilterToolbar/components/FilterChip";


interface FilterToolbarProps {
    date: Dayjs;
    handleSelectedDateChange: (date: Dayjs) => void;
    searchInput: string;
    setSearchInput: (term: string) => void;
    searchCriteria: SearchCriteria;
    handleChangeSearchCriteria: (value: Partial<SearchCriteria>) => void;
    refreshTime: Dayjs | null,
    handleRefreshed: () => void,
    filterChips?: FilterChipProps[];
}

const height = 30;

const AdvancedFilterToolbar = ({date, handleSelectedDateChange, searchInput, setSearchInput, searchCriteria, handleChangeSearchCriteria, refreshTime, handleRefreshed, filterChips}: FilterToolbarProps) => {

    return (
        <ToolbarContainer sx={{flexDirection: 'column', gap: 1}}>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, flexWrap: {xs: 'wrap-reverse', lg: 'nowrap'}}}>
                <Box sx={{ order: 1, display: 'flex', alignItems: 'center' }}>
                    <DateNavigator
                        date={date}
                        handleSelectedDateChange={handleSelectedDateChange}
                        unit="day"
                        height={height}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 1,
                    rowGap: 1.5,
                    flexWrap: {xs: 'wrap-reverse', md: 'nowrap'},
                    width: '100%',
                    order: {xs: 3, lg: 2},
                }}>
                    <Box sx={{width: {xs: '100%', md: 'fit-content'}}}>
                        <InputSearch {...({value: searchInput, updateValue: setSearchInput})} />
                    </Box>
                    <Box sx={{width: {xs: '100%', sm: 'fit-content'} }}>
                        <RegionsSearch />
                    </Box>
                    { filterChips &&
                        <Box sx={{display: 'flex', gap: 0.5, flexWrap: {xs: 'wrap', sm: 'nowrap'},}}>
                            {filterChips.map((filterChip, index) => <FilterChip key={index} {...filterChip} />)}
                        </Box>
                    }
                </Box>
                <Box sx={{order: {xs: 2, lg: 3}, display: 'flex', alignItems: 'center' }}>
                    <DataRefresh refreshTime={refreshTime} handleRefreshed={handleRefreshed} height={height} />
                </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, mt: 1, flexWrap: 'wrap' }} >
                <Box sx={{ display: 'flex', gap: 1, pb: 0.5, height: height, width: {xs: '100%', md: 'auto'} }} >
                    <FilterTimeField
                        value={searchCriteria.fromTime}
                        label={'Alates'}
                        handleValueChanged={(newValue) => handleChangeSearchCriteria({fromTime: newValue})}
                    />
                    <FilterTimeField
                        value={searchCriteria.toTime}
                        label={'Kuni'}
                        handleValueChanged={(newValue) => handleChangeSearchCriteria({toTime: newValue})}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 1, pb: 0.5, height: height, width: {xs: '100%', md: 'auto'} }} >
                    <FilterTextField
                        value={searchCriteria.lineNumber}
                        label="Liin"
                        onChange={(value) => handleChangeSearchCriteria({lineNumber: value})}
                    />
                    <FilterTextField
                        value={searchCriteria.tripCode}
                        label="Reisi kood"
                        onChange={(value) => handleChangeSearchCriteria({tripCode: value})}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 1, height: height, width: {xs: '100%', md: 'auto'} }} >
                    <FilterTextField
                        value={searchCriteria.licensePlateNumber}
                        label="Bussi reg. number"
                        onChange={(value) => handleChangeSearchCriteria({licensePlateNumber: value})}
                    />
                    <FilterTextField
                        value={searchCriteria.driverName}
                        label="Juhi nimi"
                        onChange={(value) => handleChangeSearchCriteria({driverName: value})}
                    />
                </Box>
            </Box>
        </ToolbarContainer>
    );
};

export default AdvancedFilterToolbar;
