import React from 'react';
import {Add, Check} from "@mui/icons-material";
import {Chip, styled} from "@mui/material";

export interface FilterChipProps<T = any> {
    selected: boolean;
    handleSelectClick: (value: T) => void;
    text?: string;
    value: T;
}

const ActiveChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    '& .MuiChip-icon': {
        color: theme.palette.primary.main,
    },
}));

const FilterChip = <T,>({ selected, handleSelectClick, text, value }: FilterChipProps<T>) => {
    const handleClick = () => handleSelectClick(value);

    return (
        selected
            ? <ActiveChip icon={<Check fontSize="small" />} label={text ?? value as string} variant="outlined" onClick={handleClick} sx={{ height: 30 }} />
            : <Chip icon={<Add fontSize="small" />} label={text ?? value as string} variant="outlined" onClick={handleClick} sx={{ height: 30 }}/>
    );
};

export default FilterChip;
