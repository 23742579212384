import React from "react";
import DataGrid from "../../../../../components/DataGrid/DataGrid";
import {Payslip} from "../../../../../API/payslip/types";
import {GridColDef} from "@mui/x-data-grid";
import dayjs, {Dayjs} from "dayjs";
import {Box, Link, Typography} from "@mui/material";
import {Add, Download} from "@mui/icons-material";
import {useAppDispatch, useAppSelector, useAppStore} from "../../../../../hooks";
import {selectAllDrivers, selectDriverById} from "../../../../../store/driverSlice";
import {numberFormat, PayslipViewType, payslipViewTypeValues, payTypeIsCountedForTotal} from "../../types";
import DateNavigator from "../../../../../components/DateNavigator/DateNavigator";
import {AppStore} from "../../../../../store";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import {createDirectoExportString} from "../../utils";
import FileSaver from "file-saver";
import {setToast} from "../../../../../store/toastSlice";
import writeXlsxFile, {Cell, SheetData} from "write-excel-file";
import TextButton from "../../../../../components/Button/TextButton";
import {Contract, Driver} from "../../../../../API/driver/types";
import {formatName} from "../../../workSchedule/utils";
import DriversWithoutPayslipsInfoAlert from "./components/DriversWithoutPayslipsInfoAlert";
import {getContractTranslation} from "../../../../../utils/enumTranslations";
import {getDriverYearMonthCoefficients, getProfitCenterCoefficients} from "../../../../../API/payslip/api";
import ActionIcon from "../../../../../components/Icon/ActionIcon";
import ErrorWrapper from "../../../../../components/ErrorWrapper/ErrorWrapper";
import {getDisplayNullableDate, startOfPreviousMonth} from "../../../../../utils/dateUtils";
import ListView from "../../../../../layouts/ListViewWrapper";
import {PageHeaderButtonProps} from "../../../../../components/PageHeader/PageHeader";
import {Permission} from "../../../../../types";

const downloadMultiplePayslipsFile = (store: AppStore, payslips: Payslip[], month: Dayjs) => {
    const state = store.getState();
    const drivers = selectAllDrivers(state);
    const selectedRegion = selectSelectedRegion(state);
    const filename = `${month.format('YYYY-MM')}_${selectedRegion?.name}.txt`;
    const directoFileContents = payslips.filter(payslip => payslip.driverContractType !== Contract.LABOR_LEASING_AGREEMENT ).map(payslip => {
        const driver = drivers.find(driver => driver.id === payslip.driverId);
        return driver ? createDirectoExportString(payslip.body, driver) : '';
    }).join('\n');
    const file = new File([directoFileContents], filename, {type: 'text/plain;charset=utf-8'});
    FileSaver.saveAs(file);
};

const downloadSinglePayslipFile = (store: AppStore, payslip: Payslip) => {
    const state = store.getState();
    const driver = selectDriverById(state, payslip.driverId);
    const filename = `lopparve_${driver?.lastName}_${driver?.firstName}_${dayjs(payslip.startDate).format('YYYY-MM-DD')}_${dayjs(payslip.endDate).format('YYYY-MM-DD')}.txt`;
    const directoFileContents = driver ? createDirectoExportString(payslip.body, driver) : '';
    const file = new File([directoFileContents], filename, {type: 'text/plain;charset=utf-8'});
    FileSaver.saveAs(file);
};

const downloadDriverCoefficientsFile = async (store: AppStore, month: Dayjs) => {
    const state = store.getState();
    const selectedRegion = selectSelectedRegion(state);
    const yearMonthStr = month.format('YYYY-MM');
    const filename = `juhi_koefitsendid_${yearMonthStr}_${selectedRegion?.name}`;

    if (selectedRegion) {
        const rows: SheetData = (await getDriverYearMonthCoefficients(selectedRegion.id, yearMonthStr)).map(reportLine => {
            const groupItemCells: Cell[] = reportLine.group.map(item => ({ value: item }));
            const coefficientCell: Cell = { value: reportLine.coefficient };
            return groupItemCells.concat([coefficientCell])
        });

        await writeXlsxFile(
            rows,
            {
                fileName: filename,
                sheet: `Koefitsendid`,
                columns: [
                    {width: 8}, {width: 12}, {width: 20}, {width: 12}, {width: 8},
                ],
            }
        )
    }
};

const downloadProfitCenterCoefficientsFile = async (store: AppStore, month: Dayjs) => {
    const state = store.getState();
    const selectedRegion = selectSelectedRegion(state);
    const yearMonthStr = month.format('YYYY-MM');
    const filename = `tulemusyksuste_koefitsendid_${yearMonthStr}_${selectedRegion?.name}`;

    if (selectedRegion) {
        const rows: SheetData = (await getProfitCenterCoefficients(selectedRegion.id, yearMonthStr)).map(reportLine => {
            const groupItemCells: Cell[] = reportLine.group.map(item => ({ value: item }));
            const coefficientCell: Cell = { value: reportLine.coefficient };
            return groupItemCells.concat([coefficientCell])
        });

        await writeXlsxFile(
            rows,
            {
                fileName: filename,
                sheet: `Koefitsendid`,
                columns: [
                    {width: 8}, {width: 12}, {width: 8},
                ],
            }
        )
    }
};

interface PayslipRow {
    id: number;
    driverName: string;
    personalId: string;
    startDate: string;
    endDate: string;
    contractType?: string;
    numberOfWorkDays: number;
    hours: number;
    amount: number;
}

interface PayslipListProps {
    payslips: Payslip[];
    handleOpenPayslip: (id: number) => void;
    handleDelete: (id: number) => void;
    handleChangePayslipViewType: (value: string) => void;
    month: Dayjs;
    handleChangeMonth: (month: Dayjs) => void;
    handleGeneratePayslips: () => void;
    payslipViewType: PayslipViewType,
    driversWithoutGeneratedPayslip: Driver[];
    handleOpenFinalPayslipDialog: () => void;
    error?: string;
}

const PayslipList = ({
    payslips,
    handleOpenPayslip,
    handleDelete,
    handleChangePayslipViewType,
    month,
    handleChangeMonth,
    payslipViewType,
    driversWithoutGeneratedPayslip,
    handleGeneratePayslips,
    handleOpenFinalPayslipDialog,
    error,
}: PayslipListProps) => {
    const store = useAppStore();
    const drivers = useAppSelector(selectAllDrivers);
    const dispatch = useAppDispatch();

    const rows: PayslipRow[] = payslips.map(payslip => {
        const driver = drivers.find(driver => driver.id === payslip.driverId);

        return {
            id: payslip.id,
            driverName: driver ? formatName(driver, 'lastNameFirst') : '',
            personalId: driver?.personalId ?? '',
            startDate: payslip.startDate,
            endDate: payslip.endDate,
            contractType: payslip.driverContractType ? getContractTranslation(payslip.driverContractType) : '',
            numberOfWorkDays: payslip.body.periods.reduce((total, item) => (total + item.numberOfWorkDays), 0),
            hours: payslip.body.periods.reduce((periodTotal, period) => {
                return periodTotal + period.lines.reduce((total, item) => (payTypeIsCountedForTotal(item.type) ? total + item.hours : total), 0) ;
            }, 0),
            amount: payslip.body.periods.reduce((periodTotal,period) => {
                return periodTotal + period.lines.reduce((total, item) => (total + item.amount), 0)
            }, 0),
            payslip: payslip,
        };
    });

    const handleDownload = (id: number) => {
        const payslip = payslips.find(payslip => payslip.id === id);
        if (payslip) {
            downloadSinglePayslipFile(store, payslip);
        } else {
            dispatch(setToast({type: 'error', text: 'Palgafaili allalaadimisel tekkis tõrge'}));
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'personalId',
            headerName: 'Isikukood',
            sortable: true,
            filterable: true,
            width: 120,
            renderCell: (params) =>
                <Link onClick={() => handleOpenPayslip(params.row.id)} sx={{cursor: 'pointer'}}>{params.row.personalId}</Link>
        },
        {
            field: 'driverName',
            headerName: 'Nimi',
            sortable: true,
            filterable: true,
            width: 200,
        },
        {
            field: 'startDate',
            headerName: 'Algus',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params) => getDisplayNullableDate(params.value),
        },
        {
            field: 'endDate',
            headerName: 'Lõpp',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params) => getDisplayNullableDate(params.value),
        },
        {
            field: 'contractType',
            headerName: 'Lepingu tüüp',
            sortable: true,
            filterable: true,
            disableColumnMenu: false,
            width: 150,
        },
        {
            field: 'numberOfWorkDays',
            headerName: 'Tööpäevi',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 100,
        },
        {
            field: 'hours',
            headerName: 'Töötunde',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params) =>
                Number(params.value).toLocaleString('et-EE', numberFormat),
        },
        {
            field: 'amount',
            headerName: 'Summa',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 120,
            valueFormatter: (params) =>
                Number(params.value).toLocaleString('et-EE', numberFormat),
        },
        {
            field: 'actions',
            type: 'actions',
            align: 'right',
            flex: 1,
            minWidth: 120,
            renderCell: (params) => (
                <>
                    <ActionIcon type="VIEW" id={params.row.id} handleClick={handleOpenPayslip} />
                    {payslipViewType === 'LÕPPARVED' && <ActionIcon type="DOWNLOAD" id={params.row.id} handleClick={handleDownload} />}
                    <ActionIcon type="DELETE" id={params.row.id} handleClick={handleDelete} />
                </>
            ),
        }
    ];

    const headerButton: PageHeaderButtonProps = payslipViewType === 'KUUPALGAD'
        ? {title: 'Genereeri palgalehed', onClick: handleGeneratePayslips, permission: Permission.UpdatePayroll}
        : {title: 'Lõpparve', onClick: handleOpenFinalPayslipDialog, startIcon: <Add />, permission: Permission.UpdatePayroll};

    return (
        <ListView
            headerProps={{
                title: 'Palgalehed',
                toggleProps: {
                    options: payslipViewTypeValues.map(value => value.toString()),
                    value: payslipViewType,
                    handleChange: handleChangePayslipViewType,
                },
                buttonProps: headerButton,
                showRegionSelect: true,
            }}
        >
            <ErrorWrapper error={error}>
                <Box sx={{display: 'flex', flexDirection: {xs: 'column-reverse', md: 'row'}, justifyContent: 'space-between', mb: 1}}>
                    <DateNavigator
                        date={month}
                        handleSelectedDateChange={handleChangeMonth}
                        unit={'month'}
                        defaultDate={startOfPreviousMonth()}
                    />
                    <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, justifyContent: 'flex-end', minWidth: 'fit-content', ml: 1, mb: {xs: 1, sm: 0}}}>
                        {payslipViewType === 'KUUPALGAD' &&
                            <TextButton
                                text="Directo fail"
                                size="small"
                                startIcon={<Download />}
                                onClick={() => downloadMultiplePayslipsFile(store, payslips, month)}
                            />
                        }
                        <TextButton
                            text="Juhi koefitsendid"
                            size="small"
                            startIcon={<Download />}
                            onClick={() => downloadDriverCoefficientsFile(store, month)}
                            styles={{ml: 1}}
                        />
                        <TextButton
                            text="Tulemusüksuse koefitsendid"
                            size="small"
                            startIcon={<Download />}
                            onClick={() => downloadProfitCenterCoefficientsFile(store, month)}
                            styles={{ml: 1}}
                        />
                    </Box>
                </Box>
                {payslipViewType === 'KUUPALGAD' && payslips.length > 0 && driversWithoutGeneratedPayslip.length > 0 &&
                    <DriversWithoutPayslipsInfoAlert drivers={driversWithoutGeneratedPayslip} />
                }
                {payslips.length > 0 ?
                    <DataGrid
                        sorting={{
                            sortModel: [{ field: 'driverName', sort: 'asc' }],
                        }}
                        rows={rows}
                        columns={columns}
                    />
                    :
                    <Typography color="text.secondary" mt={2}>Palgalehti pole veel genereeritud.</Typography>
                }
            </ErrorWrapper>
        </ListView>
    );
};

export default PayslipList;
