import React, {useEffect, useState} from 'react';
import {Box, useTheme} from "@mui/material";
import TimelineHeader from "./components/Timeline/TimelineHeader";
import DateNavigator from "../../components/DateNavigator/DateNavigator";
import UnassignedTripContainer from "./components/containers/UnassignedItemsContainer/UnassignedItemsContainer";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import AddOrEditActivityDialog from "./components/dialogs/AddOrEditActivity";
import AddOtherRegionTripDialog from "./components/dialogs/AddOtherRegionTrip";
import AssignedItemsContainer from "./components/containers/AssignedItemsContainer/AssignedItemsContainer";
import {ContentScroll, PlannerType, ScrollOrigin} from './types';
import WorkGroupDetailsDialog from "./components/dialogs/AddOrEditWorkGroupDetails";
import {useAppDispatch, useAppSelector, useCustomRef} from "../../hooks";
import {
    fetchWorkGroupPlannerData,
    fetchWorkSheetPlannerData,
    selectDialogData,
    selectIsPlannerLoading,
    selectUnassignedTripsSectionHeight,
    setIsLoading,
    setSelectedOppositeGroupId,
} from "../../store/plannerSlice";
import {selectToggledResourceType, useDayNavigation} from "../../store/viewSlice";
import {selectSelectedRegion} from "../../store/regionSlice";
import {fetchDrivers} from "../../store/driverSlice";
import {fetchBuses} from "../../store/busSlice";
import AddOppositeWorkGroupToActivityDialog
    from "./components/dialogs/AddOppositeWorkGroup/AddOppositeWorkGroupDialogToActivity";
import AddOppositeWorkGroupToTripDialog from "./components/dialogs/AddOppositeWorkGroup/AddOppositeWorkGroupToTrip";
import AddOppositeWorkSheetDialog from "./components/dialogs/AddOppositeWorkSheet";
import AddWorkSheetDialog from "./components/dialogs/AddWorkSheetDialog";
import CopyOppositeWorkGroup from "./components/dialogs/CopyOppositeWorkGroup";
import {PLANNER_ROW_HEIGHT} from "./constants";
import {useDebounce} from "usehooks-ts";
import {DEFAULT_DEBOUNCE_DELAY} from "../../constants";
import SplitTrip from "./components/dialogs/SplitTrip";
import LoaderWithChildren from "../../components/Loader/LoaderWithChildren";
import ErrorWrapper from "../../components/ErrorWrapper/ErrorWrapper";
import ConfirmMergeTrip from "./components/dialogs/MergeTrip";
import SplitWorkGroupDialog from "./components/dialogs/SplitWorkGroupDialog";
import NewWorkGroupVersionWrapper from "./components/dialogs/NewWorkGroupVersion/NewWorkGroupVersionWrapper";
import LinkOppositeWorkGroup from "./components/dialogs/LinkOppostiteWorkGroup";


interface PlannerProps {
    type: PlannerType;
}

export default function Planner({type}: PlannerProps) {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(selectIsPlannerLoading);
    const error = useAppSelector(state => state.planner.error);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const toggledResourceType = useAppSelector(selectToggledResourceType);
    const dialogData = useAppSelector(selectDialogData);
    const unassignedTripsSectionHeight = useAppSelector(selectUnassignedTripsSectionHeight);

    const [date, handleChangeDate] = useDayNavigation();
    const debouncedDate = useDebounce(date, DEFAULT_DEBOUNCE_DELAY);
    const [contentScrollX, setContentScrollX] = useState<ContentScroll | undefined>();
    const [contentHeightRef] = useCustomRef();

    useEffect(() => {
        if (type == PlannerType.WORK_SHEET) {
            dispatch(fetchDrivers());
            dispatch(fetchBuses());
            dispatch(setIsLoading(true));
        }
    }, []);

    useEffect(() => {
        if (selectedRegion) {
            if (type === PlannerType.WORK_GROUP) {
                dispatch(fetchWorkGroupPlannerData());
            }
            if (type === PlannerType.WORK_SHEET) {
                dispatch(fetchWorkSheetPlannerData());
            }
        }
        setContentScrollX(undefined);
    }, [selectedRegion, toggledResourceType, debouncedDate]);

    useEffect(() => {
        dispatch(setIsLoading(true));
        dispatch(setSelectedOppositeGroupId(undefined));
    }, [date, selectedRegion, toggledResourceType]);

    const handleContentScroll = (ref: React.RefObject<HTMLDivElement>, origin: ScrollOrigin) => {
        if (ref.current) {
            const { scrollLeft } = ref.current;
            if (contentScrollX?.value !== scrollLeft) setContentScrollX({value: scrollLeft, origin: origin});
        }
    };

    return (
        <ErrorWrapper error={error}>
            <Box ref={contentHeightRef} sx={{height: '100%', overflow: 'hidden'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', position: 'relative', borderRadius: '5px 5px 5px 5px', border: `1px dashed ${theme.palette.divider}`, height: '100%', boxSizing: 'border-box'}}>
                    <Box sx={{p: 1}}>
                        <DateNavigator date={date} handleSelectedDateChange={handleChangeDate} unit={'day'} />
                    </Box>
                    <LoaderWithChildren loading={isLoading}>
                        <DndProvider backend={HTML5Backend}>
                            <Box sx={{display: 'flex', overflow: 'hidden', flexDirection: 'column', flex: '0 1 auto', maxHeight: unassignedTripsSectionHeight === '20%' ? '80%' : '60%'}}>
                                <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', overflow: 'hidden'}}>
                                    <TimelineHeader contentScrollX={contentScrollX} />
                                    <AssignedItemsContainer contentScrollX={contentScrollX} handleContentScroll={handleContentScroll} />
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', height: unassignedTripsSectionHeight, minHeight: `${PLANNER_ROW_HEIGHT * 2}px`, overflow: 'hidden', flex: '1 1 auto'}}>
                                <UnassignedTripContainer contentScrollX={contentScrollX} handleContentScroll={handleContentScroll} />
                            </Box>
                        </DndProvider>
                    </LoaderWithChildren>
                    {(!!dialogData.addActivity || !!dialogData.editActivity) && <AddOrEditActivityDialog />}
                    {!!dialogData.addOtherRegionTrip && <AddOtherRegionTripDialog />}
                    {!!dialogData.addOrEditWorkGroupDetails && type === PlannerType.WORK_GROUP && <WorkGroupDetailsDialog />}
                    {!!dialogData.addOppositeWorkGroupToActivity && type === PlannerType.WORK_GROUP && <AddOppositeWorkGroupToActivityDialog />}
                    {!!dialogData.addOppositeWorkGroupToTrip && type === PlannerType.WORK_GROUP && <AddOppositeWorkGroupToTripDialog />}
                    {!!dialogData.addOppositeWorkSheetToWorkItem && type === PlannerType.WORK_SHEET && <AddOppositeWorkSheetDialog />}
                    {!!dialogData.addWorkSheet && <AddWorkSheetDialog />}
                    {!!dialogData.copyOppositeWorkGroup && <CopyOppositeWorkGroup />}
                    {!!dialogData.splitTrip && <SplitTrip />}
                    {!!dialogData.mergeTrip && <ConfirmMergeTrip />}
                    {!!dialogData.splitWorkGroup && <SplitWorkGroupDialog />}
                    {!!dialogData.divideWorkGroup && <NewWorkGroupVersionWrapper />}
                    {!!dialogData.linkWorkGroup && <LinkOppositeWorkGroup />}
                </Box>
            </Box>
        </ErrorWrapper>
    );
}
