import React, {useEffect, useMemo, useState} from 'react';
import {Box, Tooltip, useMediaQuery} from '@mui/material';
import {Contract, Driver, DriverContract} from "../../../../API/types";
import {GridColDef} from '@mui/x-data-grid';
import {
    areBothOrNeitherActiveChipsSelected,
    filterByActive,
    filterByRegionsForUser,
    filterByValue,
    getRegionsDisplayStr,
    sortByStartDate
} from "../../../../utils/utils";
import theme from "../../../../theme";
import {ActiveFilterChipType, activeFilterChipValues, Permission} from "../../../../types";
import {useNavigate, useOutletContext} from 'react-router-dom';
import {getContractTranslation} from "../../../../utils/enumTranslations";
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../../components/DataGrid/DataGrid";
import routes from "../../../../routes";
import {DriverContractTypeContractions} from "../../workSchedule/types";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {loadDrivers} from "../../../../API";
import {setToast} from "../../../../store/toastSlice";
import {getDisplayNullableDate} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink/NavLink";
import NavIcon from "../../../../components/Icon/NavIcon";
import StaticIcon from "../../../../components/Icon/StaticIcon";
import {useAuth} from "../../../../contexts/AuthContext";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import ListView from "../../../../layouts/ListViewWrapper";
import {useLocalStorage} from "usehooks-ts";
import FilterToolbar from "../../../../components/FilterToolbar/FilterToolbar";
import {FilterChipProps} from "../../../../components/FilterToolbar/components/FilterChip";


interface DisplayDriver extends Driver {
    regions: string;
    latestContract?: DriverContract;
}

const getLatestContract = (contracts: DriverContract[]): DriverContract | undefined => {
    if (contracts.length === 0) return undefined;

    return [...contracts].sort(sortByStartDate)[contracts.length - 1];
};

export default function Drivers() {
    const dispatch = useAppDispatch();
    const regions = useAppSelector(selectAllRegions);
    const { authenticatedUser } = useAuth();
    const navigate = useNavigate();
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [drivers, setDrivers] = useState<Driver[] | undefined>(undefined);
    const [selectedFilterChips, setSelectedFilterChips] = useLocalStorage<ActiveFilterChipType[]>('driverFilterChips', ['Aktiivsed']);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('xl'));

    useEffect(() => {
        if (authenticatedUser) {
            loadDrivers()
                .then(result => {
                    setDrivers(filterByRegionsForUser(result, authenticatedUser))
                })
                .catch(() => dispatch(setToast({type: 'error', text: 'Bussijuhtide andmete pärimisel ilmnes viga'})));
        }
    }, [authenticatedUser]);

    const rows: DisplayDriver[] | undefined = useMemo(() => drivers?.map(driver => ({
        ...driver,
        regions: getRegionsDisplayStr(driver.regionIds, regions),
        latestContract: getLatestContract(driver.contracts),
    })), [drivers, regions]);

    const handleAddDriver = () => navigate(routes.AUTHENTICATED.DRIVERS.ADD);

    const handleSelectedFilterChipsChange = (value: ActiveFilterChipType) => {
        if (selectedFilterChips.includes(value)) {
            setSelectedFilterChips(prevState => prevState.filter(prevValue => prevValue !== value));
        } else {
            setSelectedFilterChips(prevState => [...prevState, value]);
        }
    };

    const getFilteredRows = (rows: Driver[]) => filterByActive(filterByValue(rows, searchInput), selectedFilterChips);

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'personalId',
            headerName: 'Isikukood',
            width: isScreenSmall ? 110 : 130,
            renderCell: params =>
                <NavLink id={params.row.id} value={params.row.personalId} route={routes.AUTHENTICATED.DRIVERS.EDIT} navPermission={Permission.UpdateDrivers} />
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'firstName',
            headerName: 'Eesnimi',
            width: isScreenSmall ? 80 : 120,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'lastName',
            headerName: 'Perekonnanimi',
            width: isScreenSmall ? 100 : 140,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'phoneWithPrefix',
            headerName: 'Telefon',
            width: isScreenSmall ? 120 : 140,
            minWidth: 100,
            valueGetter: params => `${params.row.phonePrefix} ${params.row.phone}`
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'email',
            headerName: 'E-post',
            width: isScreenSmall ? 150 : 200,
            minWidth: 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'contractType',
            headerName: 'Lepingutüüp',
            width: isScreenSmall ? 70 : 120,
            valueGetter: params => params.row.latestContract?.type,
            renderCell: params => params.value
                ? (
                    <Tooltip title={getContractTranslation(params.value as Contract)} placement="top" arrow>
                        <Box>{DriverContractTypeContractions.get(params.value as Contract)}</Box>
                    </Tooltip>
                ) : <></>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'contractId',
            headerName: 'Lepingu nr',
            width: isScreenSmall ? 100 : 100,
            valueGetter: params => params.row.latestContract?.number,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'contractStartDate',
            headerName: 'Lepingu algus',
            sortable: false,
            width: isScreenSmall ? 120 : 130,
            valueGetter: params => getDisplayNullableDate(params.row.latestContract?.startDate),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'contractEndDate',
            headerName: 'Lepingu lõpp',
            sortable: false,
            width: isScreenSmall ? 110 : 120,
            valueGetter: params => getDisplayNullableDate(params.row.latestContract?.endDate),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'nominalWeeklyWorkingHours',
            headerName: 'Tunnid nädalas',
            width: isScreenSmall ? 80 : 130,
            valueGetter: params => params.row.latestContract?.nominalWeeklyWorkingHours,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'regions',
            headerName: 'Piirkonnad',
            width: isScreenSmall ? 150 : 200,
            minWidth: 100
        },
        ...(areBothOrNeitherActiveChipsSelected(selectedFilterChips) ? [{
            ...DEFAULT_COL_DEF_PROPS,
            field: 'active',
            headerName: 'Aktiivne',
            width: isScreenSmall ? 70 : 80,
            renderCell: params => params.value ? <StaticIcon type="CHECK" /> : ''
        } as GridColDef] : []),
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            renderCell: params =>
                <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.DRIVERS.EDIT} navPermission={Permission.UpdateDrivers} />
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Bussijuhid',
                buttonProps: {title: 'Lisa juht', onClick: handleAddDriver, permission: Permission.UpdateDrivers}
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                filterToolbar={
                    <FilterToolbar
                        inputSearch={{value: searchInput, updateValue: setSearchInput}}
                        filterChips={
                            activeFilterChipValues.map(value => ({
                                selected: selectedFilterChips.includes(value),
                                handleSelectClick: handleSelectedFilterChipsChange,
                                value: value
                            })) as FilterChipProps<ActiveFilterChipType>[]
                        }
                    />
                }
            />
        </ListView>
    );
}
