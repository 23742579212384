import React, {useEffect, useMemo, useState} from "react";
import CardBase from "../../../components/CardBase";
import {orange} from "@mui/material/colors";
import {Box, Collapse, IconButton, Typography} from "@mui/material";
import {AddCircleOutline, ChevronLeft, Edit} from "@mui/icons-material";
import {OdometerAndRefuellingData} from "../../../../../../../features/Form/ReadingForm/ReadingForm";
import {OdometerReading, OdometerReadingType} from "../../../../../../../API/bus/types";
import {useAppSelector} from "../../../../../../../hooks";
import {selectCurrentDriverBusById} from "../../../../../../../store/currentDriverWorkSheetsSlice";
import dayjs from "dayjs";
import {useAuth} from "../../../../../../../contexts/AuthContext";
import {BusUsageWithDistance} from "../../../types";
import {
    findNearestReading,
    findPreviousReading,
    isReadingWithinAllowedTimeRange
} from "../../../../../../../features/Form/ReadingForm/utils";
import ReadingFormWrapper from "../../../components/ReadingFormWrapper";


const renderReadingDiffersFromExpectedText = (
    reading: OdometerReading,
    previousReading: OdometerReading,
    busUsage: BusUsageWithDistance, driverId?: number | null
) => {
    // only compare to a reading that was added by the current driver and that was added at the bus usage start time
    if (previousReading.driverId !== driverId || !isReadingWithinAllowedTimeRange(dayjs(previousReading.dateTime), dayjs(busUsage.startDateTime))) {
        return <></>;
    }

    const differenceBetweenReadings = Math.round(reading.reading - previousReading.reading - busUsage.totalDistance);
    return (
        <Typography variant="body2">
            {differenceBetweenReadings >= 2 && `Näit on plaanitust ${differenceBetweenReadings}km suurem`}
            {differenceBetweenReadings <= -2 && `Näit on plaanitust ${differenceBetweenReadings * -1}km väiksem`}
        </Typography>
    )
};

interface OdometerReadingCardProps {
    busUsage: BusUsageWithDistance;
    type: OdometerReadingType;
}

const OdometerReadingCard = ({busUsage, type}: OdometerReadingCardProps) => {
    const {authenticatedUser} = useAuth();
    const bus = useAppSelector(state => selectCurrentDriverBusById(state, busUsage.busId));
    const [readingFormData, setReadingFormData] = useState<OdometerAndRefuellingData | undefined>(undefined);
    const [reading, setReading] = useState<OdometerReading | undefined>(undefined);
    const [previousReading, setPreviousReading] = useState<OdometerReading | undefined>(undefined);

    const expectedReadingDateTime = useMemo(() => type === OdometerReadingType.START ? busUsage.startDateTime : busUsage.endDateTime, [busUsage])

    useEffect(() => {
        if (bus) {
            const nearestReading = findNearestReading(bus.odometerReadings, expectedReadingDateTime, authenticatedUser?.driverId, type);
            if (nearestReading) {
                setReading(nearestReading);
            }
            if (!nearestReading || type === OdometerReadingType.END) {
                setPreviousReading(findPreviousReading(bus.odometerReadings, expectedReadingDateTime));
            }
        }
    }, [bus]);

    useEffect(() => {
        if (type === OdometerReadingType.START && readingFormData?.suggestedReading !== previousReading?.reading) {
            setReadingFormData(undefined);
        }
    }, [previousReading]);

    if (!bus) return;

    const handleClick = () => {
        if (readingFormData) {
            setReadingFormData(undefined);
        } else {
            setReadingFormData({
                includeRefuelling: false,
                copiedData: reading,
                suggestedDateTime: expectedReadingDateTime,
                suggestedReading: type === OdometerReadingType.START ? previousReading?.reading : undefined
            });
        }
    };

    return (
        <CardBase
            key={`${type}Reading-${expectedReadingDateTime}`}
            onClick={handleClick}
            sx={readingFormData || reading ? {} : {backgroundColor: orange[50]}}
        >
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 0.5}}>
                <Box sx={{display: 'flex'}}>
                    <Typography component="div" variant="body1" fontWeight="bold" sx={{display: 'flex', alignItems: 'end', pr: 0.5}}>
                        {type === OdometerReadingType.START && (reading ? 'Sisestatud algnäit' : 'Kinnita odomeetri algnäit')}
                        {type === OdometerReadingType.END && (reading ? 'Sisestatud lõppnäit' : 'Lisa odomeetri lõppnäit')}
                    </Typography>
                    <Typography>({reading ? reading.reading + 'km' : bus.licencePlateNumber})</Typography>
                </Box>
                <IconButton size="small" sx={{p: 0}}>
                    {readingFormData
                        ? <ChevronLeft sx={{transform: 'rotate(90deg)'}} />
                        : (reading ? <Edit fontSize="small" /> : <AddCircleOutline fontSize="medium" />)}
                </IconButton>
            </Box>
            {type === OdometerReadingType.END && reading && previousReading &&
                renderReadingDiffersFromExpectedText(reading, previousReading, busUsage, authenticatedUser?.driverId)
            }
            <Collapse in={!!readingFormData} onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
            }}>
                <Box onClick={() => {}} sx={{cursor: 'default'}}>
                    {previousReading &&
                        <Typography variant="body2" color="text.secondary">
                            {type === OdometerReadingType.START && !reading && `Odomeetrinäit on eeltäidetud viimase sisestatud näiduga. Veendu näidu õigsuses!`}
                            {type === OdometerReadingType.END && !reading && `Viimane eelnevalt sisestatud näit on ${previousReading.reading}km`}
                        </Typography>
                    }
                    {readingFormData &&
                        <ReadingFormWrapper
                            bus={bus}
                            handleClose={handleClick}
                            includeRefuelling={readingFormData.includeRefuelling}
                            copiedData={readingFormData.copiedData}
                            suggestedDateTime={readingFormData.suggestedDateTime}
                            suggestedReading={readingFormData.suggestedReading}
                            previousReading={previousReading}
                            showDateTimeField={!readingFormData.suggestedDateTime}
                            suggestedType={type}
                        />
                    }
                </Box>
            </Collapse>
        </CardBase>
    );
};

export default OdometerReadingCard;
