import React, {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import {Box, DialogActions, DialogContent, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {getResourceTypeTranslation} from "../../../../../utils/enumTranslations";
import {Form, Formik} from "formik";
import Button from "../../../../../components/Button/Button";
import {Check, Clear} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {clearDialogData, linkWorkGroup, selectDialogData} from "../../../../../store/plannerSlice";
import {getOppositeResourceType} from "../../../../../utils/utils";
import {WorkGroup} from "../../../../../API/workGroup/types";
import {selectSelectedDay} from "../../../../../scenes/authenticated/workSheets/store/selectors";
import {loadWorkGroupsOnDate} from "../../../../../API";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import dayjs from "dayjs";
import {setToast} from "../../../../../store/toastSlice";
import LoaderWithChildren from "../../../../../components/Loader/LoaderWithChildren";
import Autocomplete from "../../../../../components/Form/Select/AutoComplete";


interface LinkWorkGroupFormData {
    linkedWorkGroup: WorkGroup | null;
}

const LinkOppositeWorkGroup = () => {
    const dispatch = useAppDispatch();
    const originalWorkGroup = useAppSelector(selectDialogData).linkWorkGroup?.originalWorkGroup;
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const date = useAppSelector(selectSelectedDay);
    const [workGroupOptions, setWorkGroupOptions] = useState<WorkGroup[]>();

    useEffect(() => {
        if (originalWorkGroup && selectedRegion) {
            loadWorkGroupsOnDate(selectedRegion.id, getOppositeResourceType(originalWorkGroup.type), dayjs(date))
                .then(result => setWorkGroupOptions(result.filter(wg => !wg.linkedWorkGroup)))
                .catch(() => dispatch(setToast({
                    type: 'error',
                    text: 'Töögruppide andmete pärimisel ilmnes viga',
                })));
        }
    }, [selectedRegion, date]);

    if (!originalWorkGroup) {
        return null;
    }

    const oppositeResourceType = getOppositeResourceType(originalWorkGroup.type);

    const defaultValues: LinkWorkGroupFormData = { linkedWorkGroup: null };

    const handleClose = () => {
        dispatch(clearDialogData());
    };
    const handleFormSubmitClick = (form: LinkWorkGroupFormData) => {
        if (form.linkedWorkGroup) {
            dispatch(linkWorkGroup({
                workGroupId: originalWorkGroup.id,
                linkedWorkGroup: {
                    id: form.linkedWorkGroup.id,
                    code: form.linkedWorkGroup.code,
                }
            }));
        }
    };

    return (workGroupOptions &&
        <Dialog open={true} onClose={handleClose}>
            <Box sx={{width: '400px', maxWidth: '100%', mt: 1, p: {xs: 0, sm: 3}}}>
                <LoaderWithChildren loading={!workGroupOptions}>
                <DialogTitle>
                    <Typography variant="h5" component="div">
                        {getResourceTypeTranslation(oppositeResourceType, 'genitive')} töögrupi sidumine
                    </Typography>
                    <Typography variant="body2" component="div" color="text.secondary">
                        {getResourceTypeTranslation(oppositeResourceType, 'genitive')} töögrupp seotakse {getResourceTypeTranslation(originalWorkGroup.type, 'genitive').toLowerCase()} töögrupiga {originalWorkGroup.code}. Kõik muudatused reiside ja tegevustega kajastuvad mõlemas seotud töögrupis.
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={defaultValues}
                    onSubmit={handleFormSubmitClick}
                >
                    {() =>
                        <Form>
                            <DialogContent sx={{pt: 0}}>
                                <Autocomplete
                                    options={workGroupOptions}
                                    name="linkedWorkGroup"
                                    label="Töögrupp"
                                    getOptionLabel={option => option.code}
                                    noOptionsText={
                                        getResourceTypeTranslation(originalWorkGroup.type, 'genitive')
                                        + ' töögrupi kordumisreeglitega sobivaid ' +
                                        getResourceTypeTranslation(oppositeResourceType, 'genitive').toLowerCase() +
                                        ' töögruppe ei leitud'
                                    }
                                />
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%'}}>
                                <Box maxWidth="50%">
                                    <Button
                                        text="Loobu"
                                        variant="outlined"
                                        onClick={handleClose}
                                        startIcon={<Clear />}
                                    />
                                </Box>
                                <Box maxWidth="50%">
                                    <Button
                                        text="Salvesta"
                                        type="submit"
                                        startIcon={<Check />}
                                    />
                                </Box>
                            </DialogActions>
                        </Form>
                    }
                </Formik>
                </LoaderWithChildren>
            </Box>
        </Dialog>
    );
};

export default LinkOppositeWorkGroup;
