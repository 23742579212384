import React from "react";
import {FormikHelpers} from "formik";
import {OdometerReadingRequest, BusDetails} from "../../../../../API/types";
import {useAppDispatch} from "../../../../../hooks";
import {addOdometerReading, updateOdometerReading} from "../../../../../store/currentDriverWorkSheetsSlice";
import ReadingForm, {
    getOdometerReadingRequest,
    ReadingFormData,
    ReadingFormProps
} from "../../../../../features/Form/ReadingForm/ReadingForm";
import {validateReadingWithPrevious} from "../../../../../features/Form/ReadingForm/utils";


interface ReadingFormWrapperProps extends Omit<ReadingFormProps, 'handleSubmit'> {
    bus: BusDetails;
    handleClose: () => void;
}

const ReadingFormWrapper = ({
    bus,
    includeRefuelling,
    handleClose,
    copiedData,
    suggestedDateTime,
    suggestedReading,
    previousReading,
    suggestedType,
    showDateTimeField,
}: ReadingFormWrapperProps) => {
    const dispatch = useAppDispatch();

    const handleSubmit = (formData: ReadingFormData, helpers: FormikHelpers<ReadingFormData>) => {
        if (!validateReadingWithPrevious(bus.odometerReadings, formData)) {
            helpers.setSubmitting(false);
            return;
        }

        const data: OdometerReadingRequest = getOdometerReadingRequest(formData);

        if (copiedData) {
            dispatch(updateOdometerReading({
                busId: bus.id,
                readingId: copiedData.id,
                driverId: copiedData.driverId,
                data: data,
                handleClose
            })).then(() => helpers.setSubmitting(false));
        } else {
            dispatch(addOdometerReading({busId: bus.id, data, handleClose})).then(() => helpers.setSubmitting(false));
        }
    };

    return (
        <ReadingForm
            bus={bus}
            includeRefuelling={includeRefuelling}
            handleSubmit={handleSubmit}
            suggestedReading={suggestedReading}
            suggestedDateTime={suggestedDateTime}
            copiedData={copiedData}
            previousReading={previousReading}
            suggestedType={suggestedType}
            showDateTimeField={showDateTimeField}
        />
    );
};

export default ReadingFormWrapper;
