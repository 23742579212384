import React, {useCallback, useEffect, useMemo, useState} from "react";
import Dialog from "../../../../../../components/Dialog/Dialog";
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import {WorkItemDialogData} from "./WorkItemDialog";
import {WorkSheetDetails as ApiWorkSheetDetails, WorkSheetWorkItem} from "../../../../../../API/workSheets/types";
import {Clear} from "@mui/icons-material";
import {ResourceType} from "../../../../../../API/workGroup/types";
import {BusUsageWithDistance, BusUsageWithOdometerReadings} from "../../../../dashboard/DriverDashboard/types";
import {getBusUsages, getUpdatedOdometerReadings, getWorkItemLabel, isItTheSameBusUsage} from "../../utils";
import {isItemWithinTimeFrame} from "../../../../../../utils/dateUtils";
import {OdometerReadingWithBusId} from "../../../../../../API/bus/types";
import {OdometerReadingRow} from "../odometerReadings/OdometerReadings";
import OdometerReadingChangeForm from "../odometerReadings/OdometerReadingChangeForm";


interface DeleteWorkItemDialogProps {
    dialogData: WorkItemDialogData;
    handleCloseDialog: () => void;
    handleSave: (workItem: WorkSheetWorkItem, removeBoth: boolean, updatedReadings: OdometerReadingWithBusId[]) => void;
    odometerReadingRows: OdometerReadingRow[];
    busUsages: BusUsageWithDistance[];
    sortedWorkItems: WorkSheetWorkItem[];
    secondaryWorkSheets: ApiWorkSheetDetails[];
}

const DeleteWorkItemDialog = ({
    dialogData,
    handleCloseDialog,
    handleSave,
    odometerReadingRows,
    busUsages,
    sortedWorkItems,
    secondaryWorkSheets,
}: DeleteWorkItemDialogProps) => {
    const [removeType, setRemoveType] = useState<string>('ONE');
    const [selectedReadingIdsForUpdate, setSelectedReadingIdsForUpdate] = useState<number[]>([]);
    const workItem = dialogData.workItem;

    const originalBusUsage: BusUsageWithOdometerReadings | undefined = useMemo(() => {
        if (dialogData.workSheet.resourceType !== ResourceType.DRIVER || !dialogData?.workItem) return undefined;

        const originalBusUsage = busUsages.find(usage =>
            usage.busId === dialogData.workItem?.busWorkSheet?.bus?.id && isItemWithinTimeFrame(
                dialogData.workItem.startDateTime,
                dialogData.workItem.endDateTime,
                usage.startDateTime,
                usage.endDateTime
            )
        );

        return originalBusUsage ? {
            ...originalBusUsage,
            odometerReadings: odometerReadingRows.filter(reading => reading.busUsage && isItTheSameBusUsage(reading.busUsage, originalBusUsage))
        } : undefined
    }, []);

    const updatedOdometerReadingRows: OdometerReadingWithBusId[] = useMemo(() => {
        if (dialogData.workSheet.resourceType !== ResourceType.DRIVER || !originalBusUsage) return [];

        const updatedBusUsages = dialogData.workSheet.resourceType === ResourceType.DRIVER ? getBusUsages(
            sortedWorkItems.filter(wi => workItem?.id !== wi.id),
            secondaryWorkSheets,
            dialogData.workSheet.id
        ) : undefined;

        const updatedOriginalUsage = updatedBusUsages?.find(updated =>
            updated.busId === originalBusUsage.busId && (updated.startDateTime === originalBusUsage.startDateTime || updated.endDateTime === originalBusUsage.endDateTime)
        );

        return getUpdatedOdometerReadings(originalBusUsage, updatedOriginalUsage);
    }, [originalBusUsage]);

    useEffect(() => {
        if (selectedReadingIdsForUpdate.length) {
            setSelectedReadingIdsForUpdate([]);
        }
    }, [updatedOdometerReadingRows]);

    const handleSubmit = useCallback(() => {
        if (workItem) {
            const odometerReadingsForUpdate: OdometerReadingWithBusId[] = selectedReadingIdsForUpdate
                .map(readingId => updatedOdometerReadingRows.find(updatedReading => updatedReading.id === readingId))
                .filter(reading => reading !== undefined) as OdometerReadingWithBusId[];

            handleSave(workItem, removeType === "BOTH", odometerReadingsForUpdate);
        }
    }, [workItem, removeType, selectedReadingIdsForUpdate, updatedOdometerReadingRows]);

    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRemoveType(event.target.value);
    };

    return (
        <Dialog
            open={true}
            onClose={handleCloseDialog}
            title="Tegevuse kustutamine"
            subtitle={workItem ? getWorkItemLabel(workItem) : undefined}
            buttons={[
                {text: 'Loobu', color: 'secondary', onClick: handleCloseDialog, startIcon: <Clear />},
                {text: 'Kustuta', onClick: handleSubmit}
            ]}
        >
            {workItem?.driverWorkSheetId && workItem.busWorkSheetId ?
                <>
                    <Typography>Palun vali, kuidas tegevust kustutada:</Typography>
                    <FormControl variant="outlined" fullWidth={true}>
                        <RadioGroup onChange={handleChangeType} value={removeType}>
                            <FormControlLabel
                                value="ONE"
                                control={<Radio sx={{py: 0.5}}  />}
                                label={`Ainult ${dialogData.workSheet.resourceType === ResourceType.DRIVER ? 'juhi' : 'bussi'} sõidulehelt`}
                            />
                            <FormControlLabel
                                value="BOTH"
                                control={<Radio sx={{py: 0.5}} />}
                                label="Nii juhi kui bussi sõidulehelt"
                            />
                        </RadioGroup>
                    </FormControl>
                    {updatedOdometerReadingRows.length > 0 &&
                        <OdometerReadingChangeForm
                            selectedReadingIdsForUpdate={selectedReadingIdsForUpdate}
                            setSelectedReadingIdsForUpdate={setSelectedReadingIdsForUpdate}
                            odometerReadingRows={odometerReadingRows}
                            updatedOdometerReadingRows={updatedOdometerReadingRows}
                        />
                    }
                </>
                :
                <Typography>Kas soovid tegevuse sellelt sõidulehelt kustutada?</Typography>
            }
        </Dialog>
    );
};

export default DeleteWorkItemDialog;
