import {Alert} from "@mui/material";
import React from "react";


const OdometerChangeNeededWarning = () => {
    return (
		<Alert severity="warning" variant="outlined">
            Muudatus jääb välja olemasoleva alg- või lõppnäidu tuvastamisvahemikust (+/- 15 min).
            Seose säilitamiseks kinnita uus näidu aeg.
        </Alert>
    );
};

export default OdometerChangeNeededWarning;
