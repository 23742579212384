import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Box, Typography} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import DateNavigator from "../../../components/DateNavigator/DateNavigator";
import PageHeader from "../../../components/PageHeader/PageHeader";
import ToggleButton from "../../../components/Button/ToggleButton";
import WorkScheduleView from "./planningView";
import {useAppDispatch, useAppSelector, useAppStore} from "../../../hooks";
import {workScheduleTypes, WorkScheduleTypeToggleButtonType} from "./types";
import {ToastContext} from "../../../contexts/ToastContext";
import {confirmWorkSchedule} from "./store/actions";
import {selectConfirmedAt, selectMonth, selectRowsForExcel, selectWorkScheduleType} from "./store/selectors";
import {fetchDrivers} from "../../../store/driverSlice";
import {fetchBuses} from "../../../store/busSlice";
import {selectSelectedRegion} from "../../../store/regionSlice";
import {
    fetchWorkSchedule,
    resetExtraDrivers,
    setMonth,
    setScheduleType,
    setWorkScheduleSearchInput
} from "../../../store/workScheduleSlice";
import {Permission, ResourceGroupToggleButtonType, resourceGroupToggleButtonTypeValues} from "../../../types";
import {selectToggledResourceType, useResourceTypeToggle} from "../../../store/viewSlice";
import {fetchCalendarEntries} from "../../../store/calendarEntriesSlice";
import writeXlsxFile from "write-excel-file";
import {Check, Download} from "@mui/icons-material";
import {AppStore} from "../../../store";
import TextButton from "../../../components/Button/TextButton";
import {useDebounce} from "usehooks-ts";
import {DEFAULT_DEBOUNCE_DELAY} from "../../../constants";
import ErrorWrapper from "../../../components/ErrorWrapper/ErrorWrapper";
import InputSearch from "../../../components/FilterToolbar/components/InputSearch";
import ToolbarContainer from "../../../components/FilterToolbar/components/ToolbarContainer";

const downloadExcel = (store: AppStore) => async () => {
    const state = store.getState();
    const month = selectMonth(state);
    const selectedRegion = selectSelectedRegion(state);
    const data = selectRowsForExcel(state);

    const maxWorkGroupCodeCharacters = data.flatMap(row => row.slice(2)).reduce(
        (max, cell) => {
            const valueLength = cell?.value?.toString().length ?? 0;
            return max > valueLength ? max : valueLength
        }, 0
    );

    await writeXlsxFile(
        data,
        {
            fileName: `tooajakava_${selectedRegion?.name}_${month}`,
            stickyRowsCount: 2,
            sheet: 'Tööajakava',
            columns: [
                {width: 25},
                {width: 5},
                ...data[0].slice(2).map(() => ({width: maxWorkGroupCodeCharacters * 1.5})),
            ],
        },
    );
};


export default function WorkSchedule() {
    const {addToast} = useContext(ToastContext);
    const store = useAppStore();
    const error = useAppSelector(state => state.workSchedule.error);
    const selectedRegion = useAppSelector(selectSelectedRegion);

    const resourceType = useAppSelector(selectToggledResourceType);
    const scheduleType = useAppSelector(selectWorkScheduleType);
    const month = useAppSelector(selectMonth);
    const debouncedMonth = useDebounce(month, DEFAULT_DEBOUNCE_DELAY);
    const confirmedAt = useAppSelector(selectConfirmedAt);
    const [searchInput, setSearchInput] = useState('');
    const debouncedSearchInput = useDebounce(searchInput, DEFAULT_DEBOUNCE_DELAY);
    const dispatch = useAppDispatch();

    const [resourceTypeToggleButtonValue, handleResourceTypeChange] = useResourceTypeToggle<ResourceGroupToggleButtonType>();

    const date = useMemo(() => dayjs(month), [month]);
    const resetDate = useMemo(() => dayjs().startOf('month').add(1, 'month'), []);
    const confirmedAtTime = useMemo(() => confirmedAt ? dayjs(confirmedAt) : null, [confirmedAt]);

    const handleChangeMonth = useCallback((month: Dayjs) => {
        dispatch(setMonth(month.format('YYYY-MM')));
    }, [dispatch]);

    const handleConfirmedTypeChange = useCallback((value: string) => {
        if (workScheduleTypes.includes(value as WorkScheduleTypeToggleButtonType)) {
            dispatch(setScheduleType(value));
        }
    }, [dispatch]);

    const handleConfirm = useCallback(async () => {
        if (selectedRegion) {
            if (confirmedAt) {
                if (!window.confirm('Tööajakava on juba kinnitatud. Uuesti kinnitamine kirjutab selle üle. Kas soovid kinnitatud tööajakava üle kirjutada?')) {
                    return;
                }
            }
            const resultAction = await dispatch(confirmWorkSchedule(selectedRegion));
            if (confirmWorkSchedule.fulfilled.match(resultAction)) {
                addToast({ type: 'success', text: 'Tööajakava kinnitatud' });
                dispatch(setScheduleType('KINNITATUD'));
            } else {
                addToast({ type: 'error', text: 'Tööajakava kinnitamine ebaõnnestus' });
            }
        }
    }, [selectedRegion, confirmedAt]);

    useEffect(() => {
        dispatch(fetchDrivers());
        dispatch(fetchBuses());
        dispatch(fetchCalendarEntries());
    }, []);

    useEffect(() => {
        dispatch(fetchWorkSchedule());
        dispatch(resetExtraDrivers());
    }, [debouncedMonth, selectedRegion, resourceType]);

    useEffect(() => {
        dispatch(setWorkScheduleSearchInput(debouncedSearchInput));
    }, [debouncedSearchInput]);

    const showConfirmButton = useMemo(() => {
        if (date.isBefore(dayjs().add(1, 'month'), 'month')) {
            return false;
        }

        return scheduleType === 'JOOKSEV';
    }, [month, scheduleType]);

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <PageHeader
                title="Tööajakava"
                toggleProps={{
                    options: [...resourceGroupToggleButtonTypeValues],
                    value: resourceTypeToggleButtonValue,
                    handleChange: handleResourceTypeChange,
                }}
                showRegionSelect
                buttonProps={showConfirmButton ?
                    {title: 'Kinnita', onClick: handleConfirm, permission: Permission.UpdateWorkSchedules, startIcon: <Check />}
                    :
                    undefined
                }
                secondaryButton={
                    <Box sx={{
                        display: 'flex',
                        flexDirection: {xs: 'row-reverse', sm: 'row'},
                        alignItems: 'center',
                        width: {xs: '100%', sm: 'fit-content'},
                    }}>
                        {confirmedAt &&
                            <ToggleButton
                                color="secondary"
                                options={[...workScheduleTypes]}
                                value={scheduleType}
                                handleChange={handleConfirmedTypeChange}
                            />
                        }
                        {(confirmedAtTime && scheduleType === 'KINNITATUD') &&
							<Typography variant="body2" color="text.secondary" component="div" sx={{textAlign: {xs: 'end', sm: 'start'}, mx: 1}}>
                                {confirmedAtTime.format('DD.MM.YYYY HH:mm')}
							</Typography>
                        }
                    </Box>
                }
            />
            <ErrorWrapper error={error}>
                <ToolbarContainer sx={{flexDirection: {xs: 'column', md: 'row'}, gap: 1}}>
                    <Box sx={{order: {xs: 2, md: 1}}}>
                        <DateNavigator date={date} handleSelectedDateChange={handleChangeMonth} unit={'month'} defaultDate={resetDate} />
                    </Box>
                    <Box sx={{order: {xs: 1, md: 2}, display: 'flex', flexDirection: {xs: 'column-reverse', sm: 'row'}, width: '100%', justifyContent: 'space-between', gap: 1}}>
                        <Box sx={{width: {xs: '100%', md: 'fit-content'}}}>
                            <InputSearch value={searchInput} updateValue={setSearchInput} />
                        </Box>
                        <TextButton text="Lae alla Exceli failina" size="small" onClick={downloadExcel(store)} startIcon={<Download />} />
                    </Box>
                </ToolbarContainer>
                <WorkScheduleView />
            </ErrorWrapper>
        </Box>
    );
}
