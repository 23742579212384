import {Box} from "@mui/material";
import React from "react";
import FilterChip, {FilterChipProps} from "./components/FilterChip";
import InputSearch, {InputSearchProps} from "./components/InputSearch";
import RegionsSearch from "./components/RegionsSearch";
import ToolbarContainer from "./components/ToolbarContainer";


interface FilterToolbarProps {
    inputSearch?: InputSearchProps,
    showRegionsSearch?: boolean,
    filterChips?: FilterChipProps[],
}

const FilterToolbar = ({inputSearch, showRegionsSearch = false, filterChips}: FilterToolbarProps) => {
    return (
        <ToolbarContainer sx={{
            alignItems: {xs: 'flex-start', md: 'center'},
            flexDirection: {xs: 'column-reverse', md: 'row'},
            gap: {xs: 2, md: 1}
        }}>
            <Box sx={{
                boxSizing: 'border-box',
                width: {xs: '100%', md: 'fit-content'},
                display: 'flex',
                flexDirection: {xs: 'column', sm: 'row-reverse'},
                gap: {xs: 2, sm: 1},
            }}>
                {showRegionsSearch && <RegionsSearch />}
                {inputSearch &&
                    <Box sx={{width: {xs: '100%', sm: showRegionsSearch ? 'fit-content' : '100%', md: 'fit-content'}}}>
                        <InputSearch {...inputSearch} />
                    </Box>
                }
            </Box>
            {filterChips &&
                <Box sx={{display: 'flex', maxWidth: '100%', flexWrap: 'wrap', gap: 0.5}}>
                    {filterChips.map((filterChip, index) => <FilterChip key={index} {...filterChip} />)}
                </Box>
            }
        </ToolbarContainer>
    );
};

export default FilterToolbar;
