import React from "react";
import {Form, FormikProps} from "formik";
import {WorkGroupForm} from "../types";
import {WorkGroup, ResourceType, WorkGroupVersion} from "../../../../../API/types";
import {Box, Link, Tooltip, Typography, tooltipClasses} from "@mui/material";
import RegionSelect from "../../../../../components/PageHeader/components/RegionSelect";
import DetailViewButtons from "../../../../../components/Button/DetailViewButtons";
import theme from "../../../../../theme";
import TextField from "../../../../../components/Form/TextField/TextField";
import DatePicker from "../../../../../components/Form/DateTimePicker/DatePicker";
import Select from "../../../../../components/Form/Select/Select";
import {getResourceTypeTranslationFromStr} from "../../../../../utils/enumTranslations";
import RepetitionRulesInput from "../../../../../components/Form/RepetiotionRulesInput/RepetitionRulesInput";
import routes from "../../../../../routes";
import VersionSelect from "./VersionSelect";
import {useAuth} from "../../../../../contexts/AuthContext";
import {Link as RouterLink} from "react-router-dom";
import {LinkRounded} from "@mui/icons-material";


interface WorkGroupEditFormProps {
    formikProps: FormikProps<WorkGroupForm>;
    workGroup: WorkGroup | null | undefined;
    defaultFormValues: WorkGroupForm,
    workGroupRegion: string | undefined,
    useDialogVariant?: boolean;
    handleCancelButtonPress?: () => void;
    selectedVersion?: WorkGroupVersion;
    setSelectedVersion: (newVersion: WorkGroupVersion | undefined) => void;
}

export default function WorkGroupEditForm({
    formikProps, workGroup, defaultFormValues, workGroupRegion, useDialogVariant, handleCancelButtonPress, selectedVersion, setSelectedVersion
}: WorkGroupEditFormProps) {
    const { authenticatedUser } = useAuth();
    const canEdit = (authenticatedUser?.regionalFilteringApplies && workGroup)
        ? authenticatedUser.regions.some(region => region.id === workGroup.regionId)
        : true;

    const showVersionSelect = (
        !useDialogVariant && !!workGroup && !!workGroup.versions && workGroup.versions.length > 1
    );

    const hasMultipleVersions = workGroup?.versions && workGroup.versions.length > 1;

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={workGroup ? workGroup.id : undefined}
                defaultFormValues={defaultFormValues}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.WORK_GROUPS.LIST}
                useDialogVariant={useDialogVariant}
                handleCancelButtonPress={handleCancelButtonPress}
            />
        );
    };

    return (
        <Form>
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, justifyContent: 'space-between', mb: 2}}>
                <Box>
                    <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                        <Typography variant="h5" pt={{xs: 1, sm: 0}}>
                            {workGroup ? workGroup.code : 'Uus töögrupp'}
                        </Typography>
                        {workGroup?.linkedWorkGroup &&
                            <Tooltip
                                title="Seotud töögrupp"
                                 arrow
                                 slotProps={{
                                     popper: {
                                         sx: {
                                             [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: { marginTop: '5px' },
                                             [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: { marginBottom: '5px' },
                                         }
                                     }
                                 }}
                            >
                                <Typography component="div">
                                    <LinkRounded sx={{mx: 1, mb: '-6px'}} />
                                    <Link
                                        sx={{textDecoration: 'none'}}
                                        component={RouterLink}
                                        to={routes.AUTHENTICATED.WORK_GROUPS.EDIT.replace(':id', workGroup.linkedWorkGroup.id.toString())}
                                        target={useDialogVariant ? '_blank' : undefined}
                                    >{workGroup.linkedWorkGroup.code}</Link>
                                </Typography>
                            </Tooltip>
                        }
                    </Box>
                    {workGroupRegion &&
                        <Typography variant="body2" color="text.secondary">
                            {workGroupRegion}
                        </Typography>
                    }
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row', mt: {xs: 1, md: 0}}}>
                    {!workGroup && <RegionSelect styles={{pr: {xs: 0, sm: useDialogVariant ? 0 : 1}}} />}
                    {showVersionSelect && <VersionSelect versions={workGroup?.versions ?? []}
                                                         selectedVersion={selectedVersion}
                                                         handleChange={setSelectedVersion}
                                                         sx={{pr: {xs: 0, sm: useDialogVariant ? 0 : 1}}} />}
                    {!useDialogVariant &&
                        <Box sx={{my: 0.5, [theme.breakpoints.down('sm')]: {display: 'none'}}}>
                            {renderDetailViewButtons()}
                        </Box>
                    }
                </Box>
            </Box>
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '450px', maxWidth: '100%',
                [theme.breakpoints.down('sm')]: {width: '100%'}
            }}>
                <TextField
                    name="code"
                    label="Kood*"
                    disabled={!canEdit}
                    description="Tööajakavas jääb nähtavale üksnes alakriipsule eelnev osa või kuni neli tähemärki."
                />
                <Select
                    name="type"
                    label="Tüüp*"
                    options={Object.values(ResourceType)}
                    translationFunction={getResourceTypeTranslationFromStr}
                    translationEnumType={ResourceType}
                    disabled={!canEdit || !!workGroup}
                />
                <DatePicker
                    name="validFrom"
                    label={`${hasMultipleVersions ? 'Esimese versiooni algus' : 'Algus'}*`}
                    disabled={!canEdit}
                />
                <DatePicker
                    name="validTo"
                    label={`${hasMultipleVersions ? 'Viimase versiooni lõpp' : 'Lõpp'}`}
                    disabled={!canEdit}
                />
                <DatePicker name="startDate" label="Sesonaalse kehtivuse alguskuupäev" hideYear disabled={!canEdit} />
                <DatePicker name="endDate" label="Sesonaalse kehtivuse lõpukuupäev" hideYear disabled={!canEdit} />
                <RepetitionRulesInput name="repetition" disabled={!canEdit} />
                <TextField name="comment" label="Kommentaar" multiline minRows={2} maxRows={5} disabled={!canEdit} />
                <Box sx={useDialogVariant ? {} : {[theme.breakpoints.up('sm')]: {display: 'none'}}}>
                    {renderDetailViewButtons()}
                </Box>
            </Box>
        </Form>
    );
}
